<template>
    <DialogTitle as="h3" class="text-lg font-semibold leading-6 text-gray-900 mb-3">
        Hubo un problema
    </DialogTitle>
    <div class="w-full h-full flex flex-col items-center justify-center p-4">
        <svg width="256px" height="256px" class="max-h-24 max-w-24" viewBox="0 0 512.00 512.00" version="1.1" xmlns="http://www.w3.org/2000/svg"
            xmlns:xlink="http://www.w3.org/1999/xlink" fill="#ff0000">
            <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
            <g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g>
            <g id="SVGRepo_iconCarrier">
                <title>error-filled</title>
                <g id="Page-1" stroke-width="0.00512" fill="none" fill-rule="evenodd">
                    <g id="add" fill="#ff0000" transform="translate(42.666667, 42.666667)">
                        <path
                            d="M213.333333,3.55271368e-14 C331.136,3.55271368e-14 426.666667,95.5306667 426.666667,213.333333 C426.666667,331.136 331.136,426.666667 213.333333,426.666667 C95.5306667,426.666667 3.55271368e-14,331.136 3.55271368e-14,213.333333 C3.55271368e-14,95.5306667 95.5306667,3.55271368e-14 213.333333,3.55271368e-14 Z M262.250667,134.250667 L213.333333,183.168 L164.416,134.250667 L134.250667,164.416 L183.168,213.333333 L134.250667,262.250667 L164.416,292.416 L213.333333,243.498667 L262.250667,292.416 L292.416,262.250667 L243.498667,213.333333 L292.416,164.416 L262.250667,134.250667 Z"
                            id="Combined-Shape"> </path>
                    </g>
                </g>
            </g>
        </svg>
        <div class="flex justify-end self-end gap-2 p-2">
            <button type="button" @click="hideModal"
                class="inline-flex justify-center rounded-md border border-transparent bg-blue-100 px-4 py-2 text-sm font-medium text-blue-900 hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2 my-4">
                Volver
            </button>
        </div>
    </div>
</template>

<script>
import {
  DialogTitle
} from '@headlessui/vue'
export default {
  name: 'RejectText',
  components: {
    DialogTitle
  },
  props: {
    hideModal: {
      type: Function
    }
  }
}
</script>
