<template>
  <div
  class="m-4 sm:mt-10 sm:mb-20 grid grid-cols-2 md:grid-cols-3 gap-4 sm:gap-y-6 content-center object-center justify-items-center">
    <button v-for="item in items" :key="item.id"
      class="xs:w-44 xs:h-46 transition-all bg-white flex flex-col shadow-xl p-4 sm:p-6 border-gray-700 rounded-lg border-4 cursor-pointer hover:bg-gray-200 focus:border-blue-700 focus:border-6 hover:shadow-2xl"
      @click="$router.push({ path: `entities/${item.href}` })">
      <img v-bind:src="item.img" class="rounded-md sm:w-48 sm:h-48" />
      <h3 class=" mt-4 select-none w-full">
        <p class="text-gray-700 text-center font-bold break-words hyphens-manual text-xl">{{ item.name }} </p>
      </h3>
    </button>
  </div>
</template>

<script>
const items = [
  {
    id: 1,
    name: 'Administraciones',
    href: 'companies',
    img: require('@/assets/configuration.svg')
  },
  {
    id: 2,
    name: 'Instalaciones',
    href: 'installations',
    img: require('@/assets/instalations.svg')
  },
  {
    id: 3,
    name: 'Racks',
    href: 'racks',
    img: require('@/assets/racks.svg')
  },
  {
    id: 4,
    name: 'Dispositivos',
    href: 'devices',
    img: require('@/assets/devices.svg')
  },
  {
    id: 5,
    name: 'Usuarios',
    href: 'users',
    img: require('@/assets/users.svg')
  }
  ,
  {
    id: 6,
    name: 'Qrs',
    href: 'qrs',
    img: require('@/assets/qr.jpg')
  }
]
export default {
  name: 'MobileLayout',
  props: {},
  data () {
    return {
      items
    }
  }
}
</script>
