<script setup>
import { defineEmits, defineProps } from 'vue';


const emit = defineEmits(['input-function']);

const props = defineProps({
  text: {
    type: [String],
    required: true,
  },
  bgColor: {
    type: [String],
    required: true,
  },
  textColor: {
    type: [String],
    required: false,
  },
  labelText:{ 
    type: [String],
    required: false
  }
});

const buttonAction = () =>{
  emit('input-function', "code");
}

</script>

<template>
    <div>
        <button 
          @click="buttonAction"
          :class="`relative flex flex-col h-16 w-full justify-center items-center 
                  rounded-md border border-transparent hover:bg-blue-300 bg-${bgColor} 
                  py-2 px-4 text-sm text-${textColor} font-bold group`">
          <!-- <LoadingComponent class="object-none" v-if="isLoading" />
          <span v-else>Iniciar sesión</span> -->
          <span class="text-lg self-center">{{ props.text }}</span>
          <span class="text-xs self-end">{{ props.labelText }}</span>
        </button>
    </div>
</template>