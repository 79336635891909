<template>
  <div class="h-full flex flex-col justify-center gap-1	basis-1/2">
    <div class="flex flex-row mx-5 justify-center">
      <div class="flex flex-col gap-1 basis-1/2 mt-5 bg-white border-2 shadow rounded-2xl py-4 px-8 text-center">
        <h1 class="text-3xl font-bold text-gray-900">
          {{
            this.$route.params.simcard
            ? "Editar Tarjeta Sim"
            : "Crear Tarjeta Sim"
          }}
        </h1>
      </div>
    </div>

    <div class="flex flex-row mx-5 justify-center">
      <form class="flex flex-col gap-1 basis-1/2 mt-5 mb-20 bg-white border-2 shadow rounded-2xl py-4 px-8"
        v-on:submit="submitSimcard">
        <span class="text-red-600"> {{ msg }} </span>
        <br /><br />

        <span> Proveedor: </span><br />
        <input v-model="netProvider"
          class="w-full border mb-5 px-4 py-2 rounded-full focus:border-blue-500 focus:shadow-outline outline-none"
          type="text" placeholder="Ingrese compañia" />

        <span> ICC: </span><br />
        <input v-model="icc"
          class="w-full border mb-5 px-4 py-2 rounded-full focus:border-blue-500 focus:shadow-outline outline-none"
          type="text" placeholder="Ingrese ICC" />

        <span> Plan de datos: </span><br />
        <input v-model="dataPlan"
          class="w-full border mb-5 px-4 py-2 rounded-full focus:border-blue-500 focus:shadow-outline outline-none"
          type="text" placeholder="Ingrese tipo de plan" />

        <span> Numero de celular: </span><br />
        <input v-model="phone"
          class="w-full border mb-5 px-4 py-2 rounded-full focus:border-blue-500 focus:shadow-outline outline-none"
          type="text" placeholder="Ej 56912345678" />

        <span> Dispositivo vinculado: </span><br />
        <Listbox v-model="selectedDevice">
          <ListboxButton
            class="relative w-full cursor-default rounded-full bg-white py-2 pl-3 pr-10 text-left shadow-md focus:outline-none focus:border-blue-500 focus-visible:border-indigo-500 focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 focus-visible:ring-offset-blue-300 sm:text-sm mt-2">
            {{ selectedDevice ? selectedDevice.nombre : "Seleccione un dispositivo" }}
          </ListboxButton>
          <ListboxOptions
            class="mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
            <ListboxOption v-slot="{ active, selected }" v-for="device in this.devices" :key="device.id" :value="device"
              as="template">
              <li :class="[
                active ? 'bg-blue-100 text-blue-900' : 'text-gray-900',
                'relative cursor-default select-none py-2 pl-10 pr-4',
              ]">
                <span :class="[
                  selected ? 'font-medium' : 'font-normal',
                  'block truncate',
                ]">{{ device.nombre }}</span>
                <span v-if="selected" class="absolute inset-y-0 left-0 flex items-center pl-3 text-blue-600">
                  <CheckIcon class="h-5 w-5" aria-hidden="true" />
                </span>
              </li>
            </ListboxOption>
          </ListboxOptions>
        </Listbox>
        <br />

        <button
          class="bg-blue-500 p-4 mt-5 mx-2 self-end font-bold text-white grow rounded-md hover:bg-blue-900 border-b-4"
          type="submit">
          {{
            this.$route.params.simcard
            ? "Editar tarjeta Sim"
            : "Crear tarjeta Sim"
          }}
        </button>
      </form>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import {
  Listbox,
  ListboxButton,
  ListboxOptions,
  ListboxOption
} from '@headlessui/vue'
import { CheckIcon } from '@heroicons/vue/20/solid'
export default {
  name: 'CreateSimcardForm',
  props: {},
  components: {
    Listbox,
    ListboxButton,
    ListboxOptions,
    ListboxOption,
    CheckIcon
  },
  data () {
    return {
      netProvider: '',
      icc: '',
      dataPlan: '',
      msg: '',
      phone: '',
      devices: [],
      selectedDevice: null
    }
  },
  created () {
    this.fetchDevices()
    if (this.$route.params.simcard) { // If we are editing a simcard
      this.fetchSimcardInfo()
    }
  },
  methods: {
    async createSimcard () { // Request to create a simcard
      const simcard = {
        empresa_internet: this.netProvider,
        icc: this.icc,
        nro_celular: this.phone,
        plan_datos: this.dataPlan
      }
      const headers = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + localStorage.getItem('token')
        }
      }
      const response = await axios
        .post(
          process.env.VUE_APP_URL_BACKEND + '/simcards/crear',
          simcard,
          headers
        )
        .then(async (response) => {
          if (response.status == 200 && this.selectedDevice) {
            const asociation = await axios
              .put(
                process.env.VUE_APP_URL_BACKEND + '/dispositivos/simcard',
                null,
                {
                  headers: {
                    Authorization: 'Bearer ' + localStorage.getItem('token')
                  },
                  params: {
                    id_dispositivo: this.selectedDevice.id,
                    id_simcard: response.data.id
                  }
                }
              )
              .then((response) => {
                console.log('response', response)
                if (response.status === 200) {
                  this.$router.push({ name: 'ShowSimcardsView' })
                }
              }).catch((error) => {
                this.msg =
                  'Error al crear la tarjeta SIM: ' +
                  (error.message
                    ? error.message
                    : 'No se logró indentificar el error')
              })
            return asociation
          } else if (response.status == 200) {
            this.$router.push({ name: 'ShowSimcardsView' })
          }
          return response
        })
        .catch((error) => {
          this.msg =
            'Error al crear la tarjeta SIM: ' +
            (error.message
              ? error.message
              : 'No se logró indentificar el error')
        })
      return response
    },
    async fetchSimcardInfo () { // Fetch the simcard info to edit
      const params = {
        id: this.$route.params.simcard
      }
      const headers = {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + localStorage.getItem('token')
      }
      const response = await axios
        .get(process.env.VUE_APP_URL_BACKEND + '/simcards/getSimcard', {
          params: params,
          headers: headers
        })
        .then((response) => {
          if (response.status === 200) {
            this.netProvider = response.data.empresa_internet
            this.icc = response.data.icc
            this.dataPlan = response.data.plan_datos
            this.phone = response.data.nro_celular
            this.selectedDevice = response.data.dispositivo[0] ? response.data.dispositivo[0] : { nombre: 'Seleccione un dispositivo' }
          }
        })
        .catch((error) => {
          this.msg = 'Error al obtener la información de la tarjeta SIM'
          console.log(error)
        })
      return response
    },
    async submitSimcard (e) { // Submit the simcard info to edit or create
      e.preventDefault()
      if (this.validateForm()) return
      if (this.$route.params.simcard) {
        await this.editSimcard()
      } else {
        await this.createSimcard()
      }
    },
    async editSimcard () { // Edit the simcard info
      const simcard = {
        empresa_internet: this.netProvider,
        icc: this.icc,
        plan_datos: this.dataPlan,
        id: this.$route.params.simcard
      }
      const headers = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + localStorage.getItem('token')
        }
      }
      const response = await axios
        .put(
          process.env.VUE_APP_URL_BACKEND + '/simcards/actualizar',
          simcard,
          headers
        )
        .then((response) => {
          if (response.status === 200 && !this.selectedDevice.id) {
            this.$router.push({ name: 'ShowSimcardsView' })
          } else {
            return response
          }
        })
        .catch((error) => {
          this.msg = 'Error al editar la tarjeta sim'
          console.log(error)
        })

      if (this.selectedDevice.id) {
        var asociation = await axios
          .put(
            process.env.VUE_APP_URL_BACKEND + '/dispositivos/simcard',
            null,
            {
              headers: {
                Authorization: 'Bearer ' + localStorage.getItem('token')
              },
              params: {
                id_dispositivo: this.selectedDevice.id,
                id_simcard: this.$route.params.simcard
              }
            }
          )
          .then((response) => {
            if (response.status === 200) {
              this.$router.push({ name: 'ShowSimcardsView' })
            }
          })
      }
      return [response, asociation]
    },
    validateForm () { // Validate the form using regex
      const regexNumber = /^[0-9]+$/
      const regexPhone = /^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s\./0-9]*$/; //eslint-disable-line
      if (
        this.netProvider === '' ||
        this.dataPlan === '' ||
        this.icc === '' ||
        this.phone === ''
      ) {
        this.msg = 'Por favor llene todos los campos'
        return true
      }
      if (!regexNumber.test(this.icc)) {
        this.msg = 'El ICC debe ser un número'
        return true
      }
      if (!regexPhone.test(this.phone)) {
        this.msg = 'El número de celular debe ser un número'
        return true
      }
      return false
    },
    async fetchDevices () { // Fetch the devices to show in the listbox
      const headers = {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + localStorage.getItem('token')
      }
      const response = await axios
        .get(
          process.env.VUE_APP_URL_BACKEND + '/dispositivos/getDispositivos',
          {
            headers: headers
          }
        )
        .then((response) => {
          if (response.status === 200) {
            this.devices = response.data
          }
        })
        .catch((error) => {
          this.msg = 'Error al cargar los dispositivos'
          console.log(error)
        })
      return response
    }
  }
}
</script>
