<template>
    <div class="min-h-screen flex flex-col justify-between">
        <MenuNavbar />
        <SuccessMessage class="h-full"/>
        <FooterComponent />
    </div>
</template>

<script>
import MenuNavbar from '@/components/ui/MenuNavbar.vue'
import FooterComponent from '@/components/ui/FooterComponent.vue'
import SuccessMessage from '@/components/SuccessMessage.vue'
export default {
  name: 'SuccessOpenView',
  components: {
    MenuNavbar,
    FooterComponent,
    SuccessMessage
  }
}
</script>
