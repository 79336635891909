<script setup>
import { ref, onMounted } from 'vue';
import axios from 'axios';
import UniversalModal from '@/components/ui/UniversalModal.vue';
import OpenLockerText from '@/components/modalboxs/OpenLockerText.vue';
import CopyCodeText from '@/components/modalboxs/CopyCodeText.vue';
import DeleteReservationText from '@/components/modalboxs/DeleteReservationText.vue';
import LoadingText from '@/components/modalboxs/LoadingText.vue';
import AcceptText from '@/components/modalboxs/AcceptText.vue';
import RejectedText from '@/components/modalboxs/RejectedText.vue';
import EditablePagination from '../ui/EditablePagination.vue';
import OutTimeText from '@/components/modalboxs/OutTimeText.vue';
import { useRoute } from 'vue-router';

const route = useRoute();

//cabecera de cablas
const headers = ref([
    { name: 'ID Reserva', field: 'id', sortable: false },
    { name: 'Ubicación', field: 'nombre_instalacion', sortable: false },
    { name: 'Creación', field: 'fecha', sortable: false },
    // { name: 'Fecha Arriendo', field: 'fecha_arriendo', sortable: false },
    // { name: 'Hizo Reserva', field: 'usuario', sortable: false },
    { name: 'Recibio Reserva', field: 'correo', sortable: false },
    { name: 'Celular', field: 'telefono', sortable: false },
    { name: 'N° locker', field: 'nro_locker', sortable: false },
    // { name: 'Estado', field: 'arrendado', sortable: false },
    // { name: 'Clave', field: 'clave_actual', sortable: false },

]);

//variables para botones de acciones
const socket = ref(null);
const timeoutTimer = ref(null);

//variables de paginado
const itemsPerPage = ref(40); // numero de elemntos maximos por pagina
const currentPage = ref(0); // pagina acual mostrada
const paginatedData = ref([]); // objeto con elementos de pagina actual
const totalPages = ref(0); // numero total de paginas en la bbdd

//variables para botones
const showModal = ref(false);
const modalMessage = ref('');
const modalType = ref(0);
const selectedRow = ref(null);
const link = ref('');

//variables para dropdown
const currentColumns = ref("");
const currentHeaderWidth = ref("");
const showDropDown = ref(false);

//varables parseo nombre de usuarios excel
const instalationIds = ref(null); // arreglo con ids extraidos de registros de reserva
const instalationUsersExcel = ref({});

const jumpPage = async (n) => {
    currentPage.value = n - 1; //se le resta para estar alineado con el request del servidor
    await loadData();
}

//variables paa busqueda
const columnSelected = ref("");
const typingText = ref("");
const textSelected = ref("");
const loading = ref(true)

/** FUNCIONES PARA BOTONES DE ACCION */

// ocultar modal de accion
const hideModal = () => {
    showModal.value = false;
    modalMessage.value = '';
}

// abiri modal de accion
const openLockerModal = (row) => {
    modalType.value = 1;
    showModal.value = true;
    selectedRow.value = row;
}

// cierra modal
const deleteReservationModal = (row) => {
    modalType.value = 2;
    showModal.value = true;
    selectedRow.value = row;
}

const copyLinkModal = (row) => {
    modalType.value = 3;
    showModal.value = true;
    selectedRow.value = row;
    const params = new URLSearchParams({
        code: selectedRow.value.clave_actual,
        device: selectedRow.value.id_device,
        locker: selectedRow.value.nro_locker,
        instalacion: selectedRow.value.nombre_instalacion
    })
    link.value =
        process.env.VUE_APP_URL_FRONTEND +
        '/open/invitation?' +
        params.toString()
}

const copyLink = () => {
    navigator.clipboard.writeText(link.value)
    modalMessage.value = 'Link copiado!'
}

// muestra modal de operacion fallida
const failedMessage = () => {
    modalType.value = 6;
}

// abre locker
const openLocker = async () => {
    const today = new Date();

    const formatDate = today.getFullYear() + '-' + (today.getMonth() + 1).toString().padStart(2, '0') + '-' + today.getDate().toString().padStart(2, '0') + ' ' + today.getHours().toString().padStart(2, '0') + ':' + today.getMinutes().toString().padStart(2, '0') + ':' + today.getSeconds().toString().padStart(2, '0');

    const request = {
        usuario: localStorage.getItem('user'),
        mesasageRequest: 'websocket',
        type: 'apertura_cliente',
        clave: selectedRow.value.clave_actual,
        token: localStorage.getItem('token'),
        fecha_arriendo: formatDate,
        chapa: {
            id: selectedRow.value.id_device,
            nro_chapa: selectedRow.value.nro_locker
        },
        nro_serie: selectedRow.value.id_device
    }

    socket.value.send(JSON.stringify(request));
    modalType.value = 4;
    timeoutTimer.value = setTimeout(failedMessage, 20000);

}

// elimina reserva
const deleteReserve = async () => {
    const response = await axios
        .delete(
            `${process.env.VUE_APP_URL_CERRADURAS}/device?id=${selectedRow.value.id}`,
            {
                headers: {
                    Authorization: 'Bearer ' + localStorage.getItem('token')
                },
            }
        )
        .then((response) => {
            if (response.status == 200) {
                showModal.value = false;

                location.reload();
            }
        }).catch((error) => {
            console.log(error);
            modalType.value = 6;
        });

    return response;

}


/** FUNCIONES DE DROPDOWN */

//
const captureColumnInfo = (width) => {
    const roundedWidth = Math.ceil(width / 10) * 10; // Redondear al siguiente número múltiplo de 10
    currentHeaderWidth.value = roundedWidth;
}

// cambia estado de componnte, para ser mostrado o oculto
const changeDropDownState = (name) => {
    currentColumns.value = name;
    showDropDown.value = !showDropDown.value;
}

/** FUNCIONES DE UTILIDAD */

// formateo de fechas en html
const formatDate = (date) => {
    const day = new Date(date).toLocaleDateString('es-ES', {
        year: 'numeric',
        month: 'numeric',
        day: 'numeric'
    })
    const hour = new Date(date).toLocaleTimeString('es-ES', {
        hour: 'numeric',
        minute: 'numeric'
    })
    return hour + ', ' + day
}

// carga de datos iniciales
const loadData = async () => {  
    loading.value = true;  
    const url = route.path === '/menu/admin/reservation'
        ? `${process.env.VUE_APP_URL_CERRADURAS}/device`
        : `${process.env.VUE_APP_URL_CERRADURAS}/userBooking`;

    const params = {
        page: currentPage.value,
        size: itemsPerPage.value,
        sort: "id,desc"
    }

    if (columnSelected.value !== ''  && textSelected.value !== ''){
        params["text"] = textSelected.value;
        params["label"] = columnSelected.value;
    }
    if (route.path === '/menu/reservations') params["email"] = localStorage.getItem('user');
    const response = await axios.get(
        url,
        {
            headers: {
                Authorization: 'Bearer ' + localStorage.getItem('token')
            },
            params
        }
    );

    const data = response.data;    
    instalationIds.value = data.content
        ?.map(item => item.id_instalacion)
        .filter((value, index, self) => self.indexOf(value) === index);
    const instalationObj = {};

    for (const instalationId of instalationIds.value) {
        const url = `${process.env.VUE_APP_URL_BACKEND}/departamentos/instalacion/${instalationId}`;

        try {
            const res = await axios.get(url);

            if (res.data) {
                res.data.forEach(item => {
                    const email = String(item?.correo).replace(' ', '');
                    const cel = String(item?.telefono).replace(' ', '');
                    const name = `Dept ${item?.nro_depto} - ${item?.nombre}`;
                    instalationObj[email] = {
                        name,
                        cel
                    };
                });
            }
        } catch (error) {
            // Continuar con la siguiente iteración si ocurre un error
            continue;
        }
    }

    //variables a renderizar en html referentes a reservas
    instalationUsersExcel.value = instalationObj; //para formateo de nombres
    totalPages.value = data.totalPages; // para paginado
    paginatedData.value = data.content; // para paginado
    loading.value = false;
    return response;

};

const clearSearch = async () => { 
    columnSelected.value = '';
    textSelected.value = '';
    typingText.value = '';
    await loadData();
}

const inputSearch = (e) => {
    textSelected.value = e;
}

const inputSelect = () => {
    if (columnSelected.value === 'arrendado') textSelected.value = 'none'; 
}

const runSeach = async () => {
    await loadData();
}

/** FUNCIONES OBTENCIÓN DE NOMBRE USUSARIO (DESDE CARGA EXCEL) */

onMounted(async () => {

    //obtener datos de reservas
    await loadData();

    //funcionalidades para botones de accion
    socket.value = new WebSocket(`${process.env.VUE_APP_URL_SOCKET}`);

    socket.value.onmessage = (e) => {
        try {
            const jsonData = JSON.parse(e.data);
            if (jsonData.messageDevice && jsonData.code == 200) {
                clearTimeout(timeoutTimer.value);
                modalType.value = 5;
            } else if (jsonData.code == 409) {
                clearTimeout(timeoutTimer.value);
                modalType.value = 7;
            } else if (jsonData.code == 400) {
                modalType.value = 6;
            }

        } catch (error) {
            if (error instanceof SyntaxError) {
                console.log(
                    'JSON string is not in the correct format:',
                    error.message
                )
            } else {
                console.log('Error parsing JSON:', error.message)
            }
        }
    }

});

</script>

<template>
    <div>
        <div 
            class="flex mb-8 justify-center space-x-2"
        >
            <div class="flex h-10">
                <select
                    v-model="columnSelected" 
                    @change="() => inputSelect()"
                    name="select" 
                    class="w-46 border rounded-l-md text-center"
                > 
                    <option value="" class="font-semibold" disabled selected>Seleccione una opción</option>
                    <option v-if="route.path === '/menu/admin/reservation'" value="nombre_instalacion">Ubicación</option>
                    <option value="arrendado">Arrendado</option>
                </select>
                <input 
                    v-if="columnSelected !== 'arrendado' && route.path === '/menu/admin/reservation'" 
                    v-model="typingText" type="text" 
                    @change="() => inputSearch(typingText)" 
                    :disabled="!columnSelected"
                    :class="columnSelected ? 'w-58 border rounded-r-md' : 'w-58 border rounded-r-md bg-gray-400'"            
                >
            </div>
            <div class="flex space-x-2">
                <button 
                    @click="runSeach()"
                    :class="textSelected ? 'text-white font-bold bg-blue-500 hover:bg-blue-700 px-1 border rounded-md' : 'text-white font-bold bg-gray-400 px-1 border rounded-md'"
                    :disabled="!textSelected"
                >
                    Buscar
                </button>
                <button 
                    class="text-white font-bold bg-red-500 hover:bg-red-700 px-1 border rounded-md"
                    @click="clearSearch()"
                >
                    Limpiar
                </button>
            </div>
        </div>

        <table class="w-full table-auto border-x border-b">
            <!-- Encabezado de la tabla -->
            <thead>
                <tr>
                    <th v-for="(column, index) in headers" :key="index"
                        class="border-b border-l border-blue-500 bg-blue-500 p-2 text-left font-bold text-white truncate cursor-pointer">
                        <div class="flex flex-row justify-center items-center">
                            <p> {{ column.name }} </p>
                            <a v-if="column.sortable">
                                <button
                                    @click="changeDropDownState(column.name); captureColumnInfo(column.name, $event.target.closest('th').clientWidth)">
                                    <svg xmlns="http://www.w3.org/2000/svg" class="w-3 h-3 ml-1" aria-hidden="true"
                                        fill="currentColor" viewBox="0 0 320 512">
                                        <path
                                            d="M27.66 224h264.7c24.6 0 36.89-29.78 19.54-47.12l-132.3-136.8c-5.406-5.406-12.47-8.107-19.53-8.107c-7.055 0-14.09 2.701-19.45 8.107L8.119 176.9C-9.229 194.2 3.055 224 27.66 224zM292.3 288H27.66c-24.6 0-36.89 29.77-19.54 47.12l132.5 136.8C145.9 477.3 152.1 480 160 480c7.053 0 14.12-2.703 19.53-8.109l132.3-136.8C329.2 317.8 316.9 288 292.3 288z" />
                                    </svg>
                                </button>
                            </a>
                        </div>
                    </th>
                    <th
                        class="border-b border-l border-blue-500 bg-blue-500 p-2 text-left font-bold text-white truncate cursor-pointer">
                        <div class="flex flex-row justify-center items-center">
                            <p> Acciones </p>
                        </div>
                    </th>
                </tr>
            </thead>

            <!-- Datos paginados -->
            <tbody v-if="!loading">
                <tr v-bind:key="index" :value="row.id" v-for="(row, index) in paginatedData"
                    class="odd:bg-gray-100 even:bg-white hover:!bg-stone-200 cursor-pointer">
                    <td class="border-b border-l p-2 text-left truncate ">
                        {{ row.id }}
                    </td>
                    <td class="border-b border-l p-2 text-left truncate ">
                        {{ row.nombre_instalacion }}
                    </td>
                    <td class="border-b border-l p-2 text-left truncate ">
                        {{ formatDate(row.fecha) }}
                    </td>
                    <td class="border-b border-l p-2 text-left truncate ">

                        {{ instalationUsersExcel[row?.usuario]?.name ?
                            instalationUsersExcel[row?.usuario]?.name :
                            instalationUsersExcel[row?.correo]?.name ?
                                instalationUsersExcel[row?.correo]?.name :
                                row.usuario === 'REPARTIDOR-ODIHNX' ? row.correo : row.usuario
                        }}
                    </td>
                    <td class="text-center border-b border-l p-2 text-left truncate ">
                        {{ instalationUsersExcel[row?.usuario]?.name ?
                            instalationUsersExcel[row?.usuario]?.cel :
                            instalationUsersExcel[row?.correo]?.name ?
                                instalationUsersExcel[row?.correo]?.cel :
                                '-'
                        }}
                    </td>
                    <td class="text-center border-b border-l p-2 text-left truncate ">
                        {{ row.nro_locker }}
                    </td>
                    <td class=" border-b border-l p-2">
                        <div v-if="row.arrendado"
                            :class="`${route.path === '/menu/admin/reservation' ? 'grid grid-cols-3 gap-4 place-content-evenly' : 'grid grid-cols-2 gap-1 place-content-evenly'}`">
                            <img class="bg-green-500 hover:bg-green-700 p-2 rounded-2xl w-9 place-self-center cursor-pointer"
                                @click="openLockerModal(row)" src="@/assets/OPEN-2.svg" alt="Odihnx" />
                            <img class="bg-gray-500 hover:bg-gray-700 p-2 rounded-2xl w-9 place-self-center cursor-pointer"
                                @click="copyLinkModal(row)" src="@/assets/SHARE-2.svg" alt="Odihnx" />
                            <img 
                                v-if="route.path === '/menu/admin/reservation' ? true : false"
                                class="bg-red-500 p-2 rounded-2xl w-9 place-self-center cursor-pointer " 
                                :class="`${!row.arrendado ? 'col-start-1 w-9' : ''}`"
                                @click="deleteReservationModal(row)"
                                src="@/assets/TRASH-2.svg" alt="Odihnx" />
                        </div>
                        <div v-else class="flex justify-center items-center">
                            ---
                        </div>
                    </td>
                </tr>

            </tbody>

        </table>
        
        <UniversalModal @cancel="hideModal" :isOpen="showModal">
            <OpenLockerText v-if="modalType == 1" :acceptFunction="openLocker" :hideModal="hideModal" />
            <DeleteReservationText v-if="modalType == 2" :acceptFunction="deleteReserve" :hideModal="hideModal" />
            <CopyCodeText v-if="modalType == 3" :acceptFunction="copyLink" :link="link" :modalMessage="modalMessage" />
            <LoadingText v-if="modalType == 4" :hideModal="hideModal" />
            <AcceptText v-if="modalType == 5" :hideModal="hideModal" />
            <RejectedText v-if="modalType == 6" :hideModal="hideModal" />
            <OutTimeText v-if="modalType == 7" :hideModal="hideModal" />
        </UniversalModal>
        
        <!-- spinner -->
        <div v-if="loading" class="flex justify-center items-center mt-20">
            <div
            class="animate-spin rounded-full h-14 w-14 border-t-2 border-b-2 border-blue-500"
            ></div>
        </div>     

        <!-- Paginación -->
        <EditablePagination @jump-page="jumpPage" :currentPage="currentPage" :totalPages="totalPages" />

    </div>
</template>