<template>
    <button
    :class="{ 
      'bg-yellow-500 hover:bg-yellow-700': bgColor === 'yellow', 
      'bg-blue-500 hover:bg-blue-700': bgColor === 'blue', 
      'text-black': textColor === 'black',
      'font-bold sm:py-2 sm:px-4 rounded': true
    }"
      @click="onClickFunction"
    >
      {{ text }}
    </button>
  </template>
  
  <script>
  export default {
    name: 'EditableButton',
    props: {
      text: String,
      onClickFunction: Function,
      textColor: String,
      bgColor: String
    },
    data () {
      return {
        editable: false
      }
    }
  }
  </script>
  