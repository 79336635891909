<template>
  <div class="overflow-auto px-8 rounded-lg shadow-lg flex flex-col justify-start items-center">
    <form @submit.prevent="submitForm"
      class="flex flex-col bg-white pl-8 pr-32 pb-8 pt-4 my-5 shadow-md border rounded w-1/3">
      <h2 class="text-2xl font-semibold mb-4">Completa tu informacion</h2>
      <div class="mb-4 flex flex-col">
        <label for="phone" class="block text-gray-700 font-medium my-1">Telefono</label>
        <input type="text" v-model="formData.telefono" class="form-input mt-1 block pl-2 p-1 border rounded-md"
          placeholder="+56 X XXXXXXXX" @input="handleInput" required />
      </div>
      <div class="mb-4 flex flex-col">
        <label for="lockerLocation" class="block text-gray-700 font-medium"> Ubicación mas cercana</label>
        <Listbox v-model="selectedInstallation" class="mt-2">
          <div class="relative mt-1">
            <ListboxButton
              class="relative border w-full cursor-default rounded-lg bg-white py-2 pl-3 pr-10 text-left shadow-md focus:outline-none focus-visible:border-indigo-500 focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 focus-visible:ring-offset-blue-300 sm:text-sm">
              <span class="block truncate">{{ selectedInstallation ?
                selectedInstallation.nombre_instalacion :
                "Selecciona tu ubicación" }}</span>
              <span class="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                <ChevronUpDownIcon class="h-5 w-5 text-gray-400" aria-hidden="true" />
              </span>
            </ListboxButton>
            <transition leave-active-class="transition duration-100 ease-in" leave-from-class="opacity-100"
              leave-to-class="opacity-0">
              <ListboxOptions
                class="absolute mt-1 max-h-60 w-full z-20 overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                <ListboxOption v-slot="{ active, selected }" v-for="installation in installations" :key="installation"
                  :value="installation" as="template">
                  <li :class="[
                    active ? 'bg-blue-100 text-blue-900' : 'text-gray-900',
                    'relative cursor-default select-none py-2 pl-10 pr-4',
                  ]">
                    <span :class="[
                      selected ? 'font-medium' : 'font-normal',
                      'block truncate',
                    ]">{{ installation.nombre_instalacion }}</span>
                    <span v-if="selected" class="absolute inset-y-0 left-0 flex items-center pl-3 text-blue-600">
                      <CheckIcon class="h-5 w-5" aria-hidden="true" />
                    </span>
                  </li>
                </ListboxOption>
              </ListboxOptions>
            </transition>
          </div>
        </Listbox>
      </div>
      <div>
        <button type="submit" class="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600">
          Guardar información
        </button>
      </div>
    </form>
  </div>
</template>

<script>
import { Listbox, ListboxButton, ListboxOptions, ListboxOption } from '@headlessui/vue'
import { CheckIcon, ChevronUpDownIcon } from '@heroicons/vue/20/solid'
import axios from 'axios'
export default {
  name: 'finishRegister',
  components: {
    Listbox,
    ListboxButton,
    ListboxOptions,
    ListboxOption,
    CheckIcon,
    ChevronUpDownIcon
  },
  data () {
    return {
      formData: {
        telefono: '',
        instalacion: ''
      },
      selectedInstallation: null
    }
  },
  created () {
    this.fetchInstallations()
  },
  methods: {
    async submitForm () {
      const response = await axios.put(
        process.env.VUE_APP_URL_BACKEND + '/auth/actualizarme',
        this.formData,
        {
          headers: {
            Authorization: 'Bearer ' + localStorage.getItem('token')
          }
        }).then((response) => {
        if (response.status == 200) {
          this.$router.push({ name: 'HomeMenuView' })
        }
      }).catch((error) => {
        this.errorMsg =
            'Error al obtener instalaciones' +
            (error.message
              ? error.message
              : 'No se logró indentificar el error')
        window.scrollTo(0, 0) // Scrolls to the top when error msg pops
      }
      )
      return response
    },
    async fetchInstallations () {
      const response = await axios
        .get(
          process.env.VUE_APP_URL_BACKEND + '/instalaciones/listarInstalaciones'
        )
        .then((response) => {
          if (response.status == 200) {
            this.installations = response.data
          }
        })
        .catch((error) => {
          this.errorMsg =
            'Error al obtener instalaciones' +
            (error.message
              ? error.message
              : 'No se logró indentificar el error')
          window.scrollTo(0, 0) // Scrolls to the top when error msg pops
        })
      return response
    },
    handleInput () {
      // Remove non-numeric characters and ensure a single "+" at the beginning
      this.formData.telefono = this.formData.telefono.replace(/[^0-9+]/g, '')
      if (this.formData.telefono.startsWith('+')) {
        this.formData.telefono = '+' + this.formData.telefono.substring(1, 13).replace(/[^0-9]/g, '')
      } else {
        this.formData.telefono = this.formData.telefono.substring(0, 12).replace(/[^0-9]/g, '')
      }
    }
  },
  watch: {
    selectedInstallation: function (val) {
      console.log(val)
      this.formData.instalacion = val.id
    }
  }
}
</script>
