<script setup>
import { computed, defineProps, defineEmits } from 'vue';

const props = defineProps({
    currentPage: {
        type: [Number],
        required: true,
    },
    totalPages: {
        type: [Number],
        required: true,
    }
});

const emit = defineEmits(['jump-page']);

const pageToShow = computed(() => props.currentPage + 1); // formateo de pagina a mostrar (se le suma 1 a la del repsonse desde el servidor)

const previusArray = computed(() => {
    if (pageToShow.value > 2) {
        return Array.from({ length: 3 }, (_, index) => pageToShow.value - index - 1).reverse().filter(num => num > 0);
    } else {
        return 0;
    }
});

const nextArray = computed(() => {
    if (props.totalPages - pageToShow.value > 2) {
        return Array.from({ length: 3 }, (_, index) => pageToShow.value + index + 1);
    } else {
        return Array.from({ length: props.totalPages - pageToShow.value }, (_, index) => pageToShow.value + index + 1);
    }
});


const jumpPage = async (n) => {
    emit('jump-page', n);
}

</script>


<template>
    <div class="flex my-4 justify-between">
        <div>
            <p class="text-sm font-normal text-gray-500">Mostrando
                <span class="font-semibold text-gray-900">{{ pageToShow }}</span>
                de
                <span class="font-semibold text-gray-900">{{ totalPages }}</span>
            </p>
        </div>
        <ul class="inline-flex items-center -space-x-px">
            <li v-if="(pageToShow !== 1) && (pageToShow - 1 !== 1)" @click="jumpPage(1)">
                <a
                    class="block px-3 py-2 ml-0 leading-tight text-gray-500 bg-white border border-gray-300 rounded-l-lg hover:bg-gray-100 hover:text-gray-700">
                    <span class="sr-only">Previous</span>
                    <svg class="w-5 h-5" aria-hidden="true" fill="currentColor" viewBox="0 0 20 20"
                        xmlns="http://www.w3.org/2000/svg">
                        <path fill-rule="evenodd"
                            d="M12.707 5.293a1 1 0 010 1.414L9.414 10l3.293 3.293a1 1 0 01-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z"
                            clip-rule="evenodd"></path>
                    </svg>
                </a>
            </li>

            <li v-if="pageToShow">

                <div class="flex">
                    <!-- Se renderiza para mostrar la pagina anterior a la pagina actual -->
                    <!-- Se oculta al comienzo, para no mostrar una pagina cero inexistente -->
                    <button v-if="pageToShow !== 1 && !previusArray"
                        class="px-3 py-2 leading-tight text-gray-500 bg-white border border-gray-300"
                        :class="pageToShow === 1 ? '' : 'bg-gray-200'" @click="jumpPage(pageToShow - 1)">
                        {{ pageToShow - 1 }}
                    </button>

                    <!-- Renderiza dinamicamente los 3 botones previos a  la pagina actual -->
                    <!-- Solo se muestra si el numero de pagina es 3 o mayor -->
                    <div v-if="previusArray">
                        <button v-for="n in previusArray" :key="n"
                            class="px-3 py-2 bg-gray-200 leading-tight text-gray-500 bg-white border border-gray-300"
                            @click="jumpPage(n)">
                            {{ n }}
                        </button>
                    </div>

                    <!-- Pagina actual -->
                    <button class="px-3 py-2 bg-blue-300 leading-tight text-gray-500 bg-white border border-gray-300"
                        disabled @click="jumpPage(pageToShow)">
                        {{ pageToShow }}
                    </button>

                    <!-- Renderiza dinamicamente los 3 botones posteriores a  la pagina actual -->
                    <!-- Si el numero entre la ultima pagina y la actual es menos de 3, se ajusta automaticamente -->
                    <div v-if="nextArray && pageToShow > 2">
                        <button v-for="n in nextArray" :key="n"
                            class="px-3 py-2 bg-gray-200 leading-tight text-gray-500 bg-white border border-gray-300"
                            @click="jumpPage(n)">
                            {{ n }}
                        </button>
                    </div>

                    <!-- Se renderiza para mostrar la pagina siguiente a la pagina actual -->
                    <!-- Se oculta para no mostrar una pagina siguente al maximo inexistente -->
                    <button v-if="pageToShow !== props.totalPages && pageToShow < 3"
                        class="px-3 py-2 bg-gray-200 leading-tight text-gray-500 bg-white border border-gray-300"
                        @click="jumpPage(pageToShow + 1)">
                        {{ pageToShow + 1 }}
                    </button>

                    <!-- Se muestra al comienzo, cuando la pagina actual es 1 -->
                    <button v-if="pageToShow === 1 && props.totalPages > 2"
                        class="px-3 py-2 bg-gray-200 leading-tight text-gray-500 bg-white border border-gray-300"
                        @click="jumpPage(pageToShow + 2)">
                        {{ pageToShow + 2 }}
                    </button>

                </div>

            </li>


            <li v-if="(pageToShow >= 1) && (pageToShow !== totalPages) && (pageToShow + 1 !== totalPages)"
                @click="jumpPage(totalPages)">
                <a
                    class="block px-3 py-2 leading-tight text-gray-500 bg-white border border-gray-300 rounded-r-lg hover:bg-gray-100 hover:text-gray-700">
                    <span class="sr-only">Next</span>
                    <svg class="w-5 h-5" aria-hidden="true" fill="currentColor" viewBox="0 0 20 20"
                        xmlns="http://www.w3.org/2000/svg">
                        <path fill-rule="evenodd"
                            d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                            clip-rule="evenodd"></path>
                    </svg>
                </a>
            </li>
        </ul>
    </div>
</template>