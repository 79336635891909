<template>
    <div class="flex flex-row justify-between items-center">
        <div class="grow"> </div>
        <div class="bg-blue-400 grow place-content-center rounded-full flex p-10 flex-col items-center">
            <img src="../assets/close.png" class="rounded-md max-h-20 max-w-20 object-center"/>
            <p class="text-white text-xl text-center sm:text-2xl font-bold">Se agotó el tiempo de conexion</p>
        </div>
        <div class="grow"> </div>
    </div>
</template>

<script>

export default {
  name: 'FailureOpenView'
}
</script>
