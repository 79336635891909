<template>
  <div class="h-full flex flex-col justify-center gap-1	basis-1/2">
    <div class="flex flex-row mx-5 justify-center">
      <div class="flex flex-col gap-1 basis-1/2 mt-5 bg-white border-2 shadow rounded-2xl py-4 px-8 text-center">
        <h1 class="text-3xl font-bold text-gray-900">
          {{
            this.$route.params.installation
            ? "Editar Instalacion"
            : "Crear Instalacion"
          }}
        </h1>
      </div>
    </div>
    <div class="flex flex-row mx-5 justify-center">
      <form class="flex flex-col gap-1 basis-1/2 mt-5 mb-20 bg-white border-2 shadow rounded-2xl py-4 px-8"
        v-on:submit="submitInstallation">
        <span v-if="msg" class="text-red-600"> {{ msg }} </span>
        <br v-if="msg" /><br v-if="msg" />
        <span> Administrador: </span><br />
        <input v-model="adminName"
          class="w-full border px-4 py-2 rounded-full focus:border-blue-500 focus:shadow-outline outline-none" type="text"
          placeholder="Usuario del administrador" />

        <span> Comuna: </span><br />
        <input v-model="comuna"
          class="w-full border px-4 py-2 rounded-full focus:border-blue-500 focus:shadow-outline outline-none" type="text"
          placeholder="Comuna de la instalacion" />

        <span> Direccion: </span><br />
        <input v-model="direction"
          class="w-full border px-4 py-2 rounded-full focus:border-blue-500 focus:shadow-outline outline-none" type="text"
          placeholder="Direccion de la instalacion" />

        <span> Nombre de la instalacion: </span><br />
        <input v-model="name"
          class="w-full border px-4 py-2 rounded-full focus:border-blue-500 focus:shadow-outline outline-none" type="text"
          placeholder="Nombre de la ubicación" />

        <span> Numero de contacto: </span><br />
        <input v-model="phone"
          class="w-full border px-4 py-2 rounded-full focus:border-blue-500 focus:shadow-outlineoutline-none" type="text"
          placeholder="Ingrese el numero de contacto" />

        <span> País: </span><br />
        <input v-model="country"
          class="w-full border px-4 py-2 rounded-full focus:border-blue-500 focus:shadow-outline outline-none" type="text"
          placeholder="Pais de la instalacion" disabled />

        <span> Región: </span><br />
        <input v-model="region"
          class="w-full border px-4 py-2 rounded-full focus:border-blue-500 focus:shadow-outline outline-none" type="text"
          placeholder="Region de la instalacion" disabled />

        <span> Banco: </span><br />
        <input v-model="bank"
          class="w-full border px-4 py-2 rounded-full focus:border-blue-500 focus:shadow-outline outline-none" type="text"
          placeholder="Ej: Banco Estado" />

        <span> Administracion responsable: </span><br />
        <Listbox v-model="selectedAdministration">
          <ListboxButton
            class="relative w-full cursor-default rounded-full bg-white py-2 pl-3 pr-10 text-left shadow-md focus:outline-none focus:border-blue-500 focus-visible:border-indigo-500 focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 focus-visible:ring-offset-blue-300 sm:text-sm mt-2">
            {{
              selectedAdministration
              ? selectedAdministration.nombre_contacto
              : "Seleccione una Administracion"
            }}
          </ListboxButton>
          <ListboxOptions
            class="mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
            <ListboxOption v-slot="{ active, selected }" v-for="administration in this.administrations"
              :key="administration.id" :value="administration" as="template">
              <li :class="[
                active ? 'bg-blue-100 text-blue-900' : 'text-gray-900',
                'relative cursor-default select-none py-2 pl-10 pr-4',
              ]">
                <span :class="[
                  selected ? 'font-medium' : 'font-normal',
                  'block truncate',
                ]">{{ administration.nombre_contacto }}</span>
                <span v-if="selected" class="absolute inset-y-0 left-0 flex items-center pl-3 text-blue-600">
                  <CheckIcon class="h-5 w-5" aria-hidden="true" />
                </span>
              </li>
            </ListboxOption>
          </ListboxOptions>
        </Listbox>

        <br /><br />
        <div>
          <label class="inline-flex items-center">
            <input type="checkbox" class="form-checkbox" v-model="paid" />
            <span class="ml-2"> Está pagado </span>
          </label>
        </div>
        <div>
          <label class="inline-flex items-center">
            <input type="checkbox" class="form-checkbox" v-model="active" />
            <span class="ml-2"> Está activado </span>
          </label>
        </div>

        <button class="bg-blue-500 p-4 m-4 self-end font-bold text-white grow rounded-md hover:bg-blue-900 border-b-4"
          type="submit">
          {{
            this.$route.params.installation
            ? "Editar Instalacion"
            : "Crear Instalacion"
          }}
        </button>
      </form>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import {
  Listbox,
  ListboxButton,
  ListboxOptions,
  ListboxOption
} from '@headlessui/vue'
import { CheckIcon } from '@heroicons/vue/20/solid'
export default {
  name: 'CreateLockerForm',
  components: {
    Listbox,
    ListboxButton,
    ListboxOptions,
    ListboxOption,
    CheckIcon
  },
  props: {
    installation: {
      type: Number
    }
  },
  created () {
    this.fetchAdministrations()
    if (this.$route.params.installation) {
      this.fetchInstallationInfo()
    }
  },
  data () {
    return {
      adminName: '',
      comuna: '',
      bank: '',
      direction: '',
      name: '',
      phone: '',
      paid: false,
      country: 'Chile',
      region: 'Región Metropolitana',
      msg: '',
      selectedAdministration: null,
      Administrations: [],
      active: false
    }
  },
  methods: {
    async createInstallation () {
      const installation = {
        administrador: this.adminName,
        banco: this.bank,
        comuna: this.comuna,
        cuenta_corriente: '',
        direccion: this.direction,
        id_administracion: this.selectedAdministration ? this.selectedAdministration.id : null,
        nombre_instalacion: this.name,
        pagado: this.paid,
        pais: this.country,
        region: this.region,
        telefono_contacto: this.phone,
        activo: this.active
      }
      const headers = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + localStorage.getItem('token')
        }
      }
      const response = await axios
        .post(
          process.env.VUE_APP_URL_BACKEND + '/instalaciones/crear',
          installation,
          headers
        )
        .then((response) => {
          if (response.status == 200) {
            this.$router.push({ name: 'ShowInstallationsView' })
          }
        })
        .catch((error) => {
          this.msg = 'ERROR AL CREAR INSTALACION: ' + error.message
          window.scrollTo(0, 0) // Scrolls to the top when error msg pops
        })
      return response
    },
    async fetchInstallationInfo () {
      const params = {
        id: this.$route.params.installation
      }
      const headers = {
        Authorization: 'Bearer ' + localStorage.getItem('token')
      }
      const response = await axios
        .get(
          process.env.VUE_APP_URL_BACKEND + '/instalaciones/getInstalacion',
          {
            params: params,
            headers: headers
          }
        )
        .then((response) => {
          this.adminName = response.data.administrador;
          this.comuna = response.data.comuna;
          this.direction = response.data.direccion;
          this.name = response.data.nombre_instalacion;
          this.phone = response.data.telefono_contacto;
          this.paid = response.data.pagado;
          this.country = response.data.pais;
          this.region = response.data.region;
          this.bank = response.data.banco;
          this.active = response.data.activo;
          this.selectedAdministration = response.data.administracion;
        })
        .catch((error) => {
          this.msg = 'ERROR AL OBTENER INSTALACION: ' + error.message
          window.scrollTo(0, 0) // Scrolls to the top when error msg pops
        })
      return response
    },
    async submitInstallation (e) { // Submits the form
      e.preventDefault()
      if (this.validateForm()) {
        if (this.$route.params.installation) {
          await this.editInstallation()
        } else {
          await this.createInstallation()
        }
      } else {
        this.msg = 'Debe completar todos los campos'
        window.scrollTo(0, 0) // Scrolls to the top when error msg popss
      }
    },
    async editInstallation () {
      const editedInstallation = {
        administrador: this.adminName,
        id: this.$route.params.installation,
        casilleros: [],
        comuna: this.comuna,
        direccion: this.direction,
        dispositivos: [],
        empresas: [],
        nombre_instalacion: this.name,
        telefono_contacto: this.phone,
        pagado: this.paid,
        pais: this.country,
        region: this.region,
        banco: this.bank,
        activo: this.active
      }
      const headers = {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + localStorage.getItem('token')
      }
      const response = await axios
        .put(
          process.env.VUE_APP_URL_BACKEND + '/instalaciones/actualizar',
          editedInstallation,
          { headers: headers }
        )
        .then((response) => {
          if (response.status === 200 && !this.selectedAdministration) {
            this.$router.push({ name: 'ShowInstallationsView' })
          } else {
            return response
          }
        })
        .catch((error) => {
          this.msg = 'ERROR AL EDITAR INSTALACION: ' + error.message
          window.scrollTo(0, 0) // Scrolls to the top when error msg pops
        })

      if (this.selectedAdministration.id) {
        var asociation = await axios
          .put(
            process.env.VUE_APP_URL_BACKEND + '/instalaciones/administracion',
            null,
            {
              headers: {
                Authorization: 'Bearer ' + localStorage.getItem('token')
              },
              params: {
                id_administracion: this.selectedAdministration.id,
                id_instalacion: this.$route.params.installation
              }
            }
          )
          .then((response) => {
            if (response.status === 200) {
              this.$router.push({ name: 'ShowInstallationsView' })
            }
          })
      }
      return [response, asociation]
    },
    validateForm () { // Validates the form
      if (this.adminName == '' ||
        this.comuna == '' ||
        this.direction == '' ||
        this.name == '' ||
        this.phone == '' ||
        this.country == '' ||
        this.region == '' ||
        this.bank == '') return false
      return true
    },
    async fetchAdministrations () { // Fetches all the administrations
      const headers = {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + localStorage.getItem('token')
      }
      const response = await axios
        .get(process.env.VUE_APP_URL_BACKEND + '/administraciones/getAdministraciones', {
          headers: headers
        })
        .then((response) => {
          if (response.status === 200) {
            this.administrations = response.data
          }
        })
        .catch((error) => {
          this.msg = 'ERROR AL OBTENER ADMINISTRACIONES: ' + error.message
          window.scrollTo(0, 0) // Scrolls to the top when error msg pops
        })
      return response
    }
  }
}
</script>
