<script setup>
import { defineProps, ref } from 'vue';

const menuPosition = ref({ top: '0px', left: '0px' });

const props = defineProps({
  name: {
    type: [String],
    required: true,
  },
  href: {
    type: [String],
    required: true,
  },
  referenceMenu: {
    type: [String],
    required: true,
  },
  current: {
    type: [Boolean],
    required: true,
  },
  changeContextMenu: {
    type: [Function],
    required: true,
  },
  showContextMenu: {
    type: [Boolean],
    required: true,
  },
});

const showMenu = (e) => {
  e.preventDefault();
  if(props.showContextMenu){
    hideMenu();
  } else{
    //props.showContextMenu = true;
    props.changeContextMenu(true, props.name);
    const containerRect = e.currentTarget.getBoundingClientRect();
    menuPosition.value = {
      top: `${e.clientY - containerRect.top}px`,
      left: `${e.clientX - containerRect.left}px`,
    };
    document.addEventListener('click', hideMenu);
  }
  
};

const hideMenu = () => {
  //props.showContextMenu = false;
  props.changeContextMenu(false, '');
  document.removeEventListener('click', hideMenu);
};

</script>

<template>
  <div class="">
    <a
      @contextmenu="showMenu"
      @click="$router.push(href)"
      :class="[
        current ? 'bg-blue-900 text-white' : 'text-white hover:bg-blue-700',
        'px-3 py-2 rounded-md text-sm font-medium cursor-pointer select-none', 'z-10'
      ]"
      :aria-current="current ? 'page' : undefined"
    >
      {{ name }}
    </a>
    <div v-if="props.showContextMenu && props.name == props.referenceMenu" :style="menuPosition" 
          class="absolute bg-[#0064ff] w-max p-1 border rounded-md text-white z-20">
      <!-- Contenido del menú aquí -->
      <ul>
          <li>
              <a 
                  :href="href" target="_blank"
                  class=" cursor-pointer select-none"
              >
                  Abrir en otra pestaña
              </a>
          </li>
      </ul>
    </div>
  </div>
</template>
