<template>
  <div>
    <div v-if="loading" class="h-full flex justify-center items-center">
      <div class="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-[#0064ff]"></div>
    </div>
    <form v-else>
      <p class="text-2xl mb-3">Bienvenido!</p>
      <label for="helper-text" class="block mb-6 text-xl">Ingresa código para abrir locker:</label>
      <div class="relative">
        <div class="otp-container my-5">
          <input v-for="(digit, index) in otp" :key="index" ref="otpInput" v-model="otp[index]"
            @input="handleInput(index, $event)" @keydown="handleKeyDown(index, $event)"
            @paste="handlePaste(index, $event)" class="otp-input" type="text" maxlength="1" />
        </div>
        <button type="submit" @click="sendTo"
          class="flex flex-row self-center p-4 text-sm font-medium rounded-lg text-white bg-blue-700 border-blue-700 hover:border-black hover:shadow-lg hover:bg-blue-800 hover:text-black">
          <p class="mr-2">Abrir locker</p>
          <svg style="color: white" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512" class="h-5 w-5">
            <path
              d="M352 192H384C419.3 192 448 220.7 448 256V448C448 483.3 419.3 512 384 512H64C28.65 512 0 483.3 0 448V256C0 220.7 28.65 192 64 192H288V144C288 64.47 352.5 0 432 0C511.5 0 576 64.47 576 144V192C576 209.7 561.7 224 544 224C526.3 224 512 209.7 512 192V144C512 99.82 476.2 64 432 64C387.8 64 352 99.82 352 144V192z"
              fill="white"></path>
          </svg>
        </button>
      </div>
    </form>
  </div>
</template>

<script>
export default {
  data () {
    return {
      otp: this.code ? this.code.split('') : ['', '', '', '', '', ''],
      loading: false
    }
  },
  props: ['code'],
  methods: {
    handleInput (index, event) {
      if (this.otp[index] && index < this.otp.length - 1) {
        // Move focus to the next input field
        this.$refs.otpInput[index + 1].focus()
      }

      // Update the value in the otp array
      this.otp[index] = event.target.value
    },
    handleKeyDown (index, event) {
      if (event.key === 'Backspace' && !this.otp[index] && index > 0) {
        // Move focus to the previous input field on Backspace press
        this.$refs.otpInput[index - 1].focus()
      }
    },
    handlePaste (index, event) {
      event.preventDefault() // Prevent default paste behavior

      const pastedData = event.clipboardData.getData('text/plain')

      // Validate pasted data or perform any necessary transformations
      const cleanedData = pastedData.slice(0, this.otp.length)

      // Update the otp array with the cleaned data
      for (let i = 0; i < cleanedData.length; i++) {
        this.otp[i] = cleanedData[i]
      }
    },
    sendTo (e) {
      e.preventDefault()
      if (this.otp.join('').length < 6) return alert('Ingresa un código de 6 caracteres')
      if (this.otp.join('').toLowerCase() == 'odihnx') return alert('El codigo no puede ser OdihnX! Revisa bien tu codigo.')
      this.loading = true
      this.$emit('receive', this.otp.join(''))
    }
  }
}
</script>

<style>
.otp-container {
  display: flex;
}
.otp-input {
  width: 40px;
  height: 40px;
  margin: 0px 5px;
  text-align: center;
  border: 1px solid #ccc;
  border-radius: 4px;
}
</style>
