<template>
  <div class="h-full flex flex-col justify-center gap-1	basis-1/2">
    <div class="flex flex-row mx-5 justify-center">
      <div class="flex flex-col gap-1 basis-1/2 mt-5 bg-white border-2 shadow rounded-2xl py-4 px-8 text-center">
        <h1 class="text-3xl font-bold text-gray-900">
          {{ this.$route.params.rack ? "Editar Rack" : "Crear Rack" }}
        </h1>
      </div>
    </div>
    <div class="flex flex-row mx-5 justify-center">
      <form class="flex flex-col gap-1 basis-1/2 mt-5 mb-20 bg-white border-2 shadow rounded-2xl py-4 px-8"
        v-on:submit="submitRack">
        <span class="text-red-600"> {{ msg }} </span>
        <br /><br />
        <span> Color: </span>
        <input v-model="color"
          class="w-full mb-4 border px-4 py-2 rounded-full focus:border-blue-500 focus:shadow-outline outline-none" type="text"
          placeholder="Color del rack" 
        />
        <span> Descripcion: </span>
        <input v-model="description"
          class="w-full mb-4 border px-4 py-2 rounded-full focus:border-blue-500 focus:shadow-outline outline-none" type="text"
          placeholder="Breve descripcion" 
        />
        <span> Modelo: </span>
        <input v-model="model"
          class="w-full mb-4 border px-4 py-2 rounded-full focus:border-blue-500 focus:shadow-outline outline-none" type="text"
          placeholder="Modelo del rack" 
        />
        <span> Ubicacion: </span>
        <input v-model="position"
          class="w-full mb-4 border px-4 py-2 rounded-full focus:border-blue-500 focus:shadow-outline outline-none" type="text"
          placeholder="Posición del rack" 
        />
        <span> Instalación vinculada: </span>
        <Listbox v-model="selectedInstallation">
          <ListboxButton
            class="relative w-full cursor-default rounded-full bg-white py-2 pl-3 pr-10 text-left shadow-md focus:outline-none focus:border-blue-500 focus-visible:border-indigo-500 focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 focus-visible:ring-offset-blue-300 sm:text-sm mt-2">
            {{ selectedInstallation
              ? selectedInstallation.nombre_instalacion
              : "Seleccione una instalación" }}
          </ListboxButton>
          <ListboxOptions
            class="mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
            <ListboxOption v-slot="{ active, selected }" v-for="installation in this.installations" :key="installation.id"
              :value="installation" as="template">
              <li :class="[
                active ? 'bg-blue-100 text-blue-900' : 'text-gray-900',
                'relative cursor-default select-none py-2 pl-10 pr-4',
              ]">
                <span :class="[
                  selected ? 'font-medium' : 'font-normal',
                  'block truncate',
                ]">{{ installation.nombre_instalacion }}</span>
                <span v-if="selected" class="absolute inset-y-0 left-0 flex items-center pl-3 text-blue-600">
                  <CheckIcon class="h-5 w-5" aria-hidden="true" />
                </span>
              </li>
            </ListboxOption>
          </ListboxOptions>
        </Listbox>


        <span class="mt-4"> Qr vinculado: </span>
        <Listbox v-model="qrSelected">
          <ListboxButton
            class="relative w-full cursor-default rounded-full bg-white py-2 pl-3 pr-10 text-left shadow-md focus:outline-none focus:border-blue-500 focus-visible:border-indigo-500 focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 focus-visible:ring-offset-blue-300 sm:text-sm mt-2">
            {{ qrSelected
              ? qrSelected.comentarios
              : "Seleccione un qr" }}
          </ListboxButton>
          <ListboxOptions
            class="mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
            <ListboxOption v-slot="{ active, selected }" v-for="qr in this.qrs" :key="qr.id"
              :value="qr" as="template">
              <li :class="[
                active ? 'bg-blue-100 text-blue-900' : 'text-gray-900',
                'relative cursor-default select-none py-2 pl-10 pr-4',
              ]">
                <span :class="[
                  selected ? 'font-medium' : 'font-normal',
                  'block truncate',
                ]">{{ qr.comentarios }}</span>
                <span v-if="selected" class="absolute inset-y-0 left-0 flex items-center pl-3 text-blue-600">
                  <CheckIcon class="h-5 w-5" aria-hidden="true" />
                </span>
              </li>
            </ListboxOption>
          </ListboxOptions>
        </Listbox>
        <button class="bg-blue-500 p-4 m-4 self-end font-bold text-white grow rounded-md hover:bg-blue-900 border-b-4"
          type="submit">
          {{ this.$route.params.rack ? "Editar Rack" : "Crear Rack" }}
        </button>
      </form>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import {
  Listbox,
  ListboxButton,
  ListboxOptions,
  ListboxOption
} from '@headlessui/vue'
import { CheckIcon } from '@heroicons/vue/20/solid'
export default {
  name: 'CreateRackForm',
  components: {
    Listbox,
    ListboxButton,
    ListboxOptions,
    ListboxOption,
    CheckIcon
  },
  props: {},
  data () {
    return {
      description: '',
      model: '',
      position: '',
      color: '',
      msg: '',
      installations: [],
      selectedInstallation: null,
      qrs: [],
      qrSelected: ''
    }
  },
  created () {
    this.fetchInstalations();
    this.getQrs();
    if (this.$route.params.rack) {
      this.fetchRackInfo();
    }
  },
  methods: {
    async createRack () {
      const rack = {
        color: this.color,
        tipo_rack: this.model,
        ubicacion: this.position,
        descripcion: this.description,
        modelo: this.model,
        id_instalacion: this.selectedInstallation ? this.selectedInstallation.id : null,
        qr_id: this.qrSelected.id ? this.qrSelected.id : null 
      }
      const headers = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + localStorage.getItem('token')
        }
      }
      const response = await axios
        .post(process.env.VUE_APP_URL_BACKEND + '/racks/crear', rack, headers)
        .then((response) => {
          if (response.status == 200) {
            this.$router.push({ name: 'ShowRacksView' })
          }
          return response
        }).catch((error) => {
          this.msg =
            'Error al crear rack' +
            (error.message
              ? error.message
              : 'No se logró indentificar el error')
          window.scrollTo(0, 0)
        })
      return response
    },
    async fetchRackInfo () { // Obtiene la informacion del rack a editar
      const params = {
        id: this.$route.params.rack
      }
      const headers = {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + localStorage.getItem('token')
      }
      const response = await axios
        .get(process.env.VUE_APP_URL_BACKEND + '/racks/getRack', {
          params: params,
          headers: headers
        })
        .then((response) => {
          if (response.status == 200) {           
            this.height = response.data.tipo_rack.alto;
            this.width = response.data.tipo_rack.ancho;
            this.depth = response.data.tipo_rack.largo;
            this.position = response.data.ubicacion;
            this.color = response.data.tipo_rack.color;
            this.model = response.data.tipo_rack.modelo;
            this.description = response.data.descripcion;
            this.selectedInstallation = response.data.instalacion;
            this.qrSelected = response.data.qrs;
          }
        })
        .catch((error) => {
          this.msg =
            'Error al obtener la información del rack' +
            (error.message
              ? error.message
              : 'No se logró indentificar el error')
          window.scrollTo(0, 0)
        })
      return response
    },
    async getQrs(){
      const headers = {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + localStorage.getItem('token')
      }
      const response = await axios
        .get(process.env.VUE_APP_URL_BACKEND + '/qr/getQrs', {
          headers: headers
      })
      .then((response) => {
        this.qrs = response.data;
      })
      .catch((error) => {
          this.msg =
            'Error al obtener la información de qrs' +
            (error.message
              ? error.message
              : 'No se logró indentificar el error')
          window.scrollTo(0, 0)
        })

      return response;

    },
    async submitRack (e) { // Envia el formulario
      e.preventDefault()
      if (this.validateForm()) return
      if (this.$route.params.rack) {
        await this.editRack()
      } else {
        await this.createRack()
      }
    },
    async editRack () { // Edita el rack
      const rack = {
        color: this.color,
        descripcion: this.description,
        id: this.$route.params.rack,
        id_instalacion: this.selectedInstallation ? this.selectedInstallation.id : null,
        modelo: this.model,
        tipo_rack: this.model,
        ubicacion: this.position,
        qr_id: this.qrSelected.id ? this.qrSelected.id : null 
      }
      const headers = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + localStorage.getItem('token')
        }
      }
      const response = await axios
        .put(
          process.env.VUE_APP_URL_BACKEND + '/racks/actualizar',
          rack,
          headers
        )
        .then((response) => {
          if (response.status === 200) {
            this.$router.push({ name: 'ShowRacksView' })
          }
        })
        .catch((error) => {
          this.msg =
            'Error al editar rack' +
            (error.message
              ? error.message
              : 'No se logró indentificar el error')
          window.scrollTo(0, 0)
        })
      return [response]
    },
    validateForm () { // Valida que los campos no esten vacios y que las medidas sean numeros
      if (
        this.model === '' ||
        this.color === '' ||
        this.position === '' ||
        this.rut === ''
      ) {
        this.msg = 'Debe completar todos los campos'
        window.scrollTo(0, 0)
        return true
      }
      return false
    },
    async fetchInstalations () { // Obtiene las instalaciones para el listbox
      const headers = {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + localStorage.getItem('token')
      }
      const response = await axios
        .get(process.env.VUE_APP_URL_BACKEND + '/instalaciones/getInstalaciones', {
          headers: headers
        })
        .then((response) => {
          if (response.status === 200) {
            this.installations = response.data
          }
        })
        .catch((error) => {
          this.msg =
            'Error al obtener las instalaciones' +
            (error.message
              ? error.message
              : 'No se logró indentificar el error')
          window.scrollTo(0, 0)
        })
      return response
    }
  }
}
</script>
