<script setup>
import { ref, onMounted, watch, toRaw } from 'vue';
import { useRoute } from 'vue-router';
import UserSearch from './UserSearch.vue';
import SelectPackage from './SelectPackage.vue';
import PackageSaved from './PackageSaved.vue';
import NewFooter from './NewFooter.vue';
import axios from 'axios';
import RejectedText from '@/components/modalboxs/RejectedText.vue';
import UniversalModal from '@/components/ui/UniversalModal.vue';
import LoadingComponent from '@/components/loading/LoadingComponent.vue';
import DeliveryVoucher from './DeliveryVoucher.vue';
import DeptoSelect from './DeptoSelect.vue';

//instancia de route
const route = useRoute();

//variables de navegación
const step = ref(2);
const intalationSelected = ref('');
const deptoSelected = ref('');
const userSelected = ref('');
const packageSelected = ref('');
const viewVoucher = ref('');
const confirmDelivery = ref('');
const finish = ref('');

//datos de voucher
const addressName = ref('');
const userName = ref('');
const lockerType = ref('');
const nroDpto = ref('');

//variables operación
const nroSerie = ref(''); //número de seria de dispositivo asociado a rack seleccionado
const typeLockersAvailable = ref({}); //identificador de lockers disponibles
const socket = ref(null); //variable almacenara instancia de web socket
const timeoutTimer = ref(null);
const showModal = ref(false);
const showLoading = ref(false);
const confirm = ref(false);
let numeroLocker = ref('');

// maneja funcionalidad de botón 
const backButton = () => {
    
    if (step.value === 2){ // vista buscar usuario
        step.value = step.value - 1;
        deptoSelected.value = '';
    // retroceder desde seleccion de departamento a seleccion de instalación
    }  else if(step.value === 3){
        step.value = step.value - 1;
        userSelected.value = '';
        nroDpto.value = '';
    // retroceder desde selección de paquete a selección de departamento
    } else if (step.value === 4){
        step.value = step.value - 1;
        lockerType.value = '';
        packageSelected.value = '';
    // retroceder desde vista voucher a selección de paquete
    } else if (step.value === 5){
        step.value = step.value - 1;
        lockerType.value = '';        
        packageSelected.value = '';
        viewVoucher.value = '';
    } else if (step.value === 6){
        step.value = 0;
        deleteSelections();
    }
}

// obtiene objeto con tipo de locker disponibles e id del mismo
const getTypeLockersAvailable = async (nroSerie) => {

    // const url = `${process.env.VUE_APP_URL_CERRADURAS}/arriendo/tipo-lockers-disponibles?nro_serie=${nroSerie}`
    const url = `${process.env.VUE_APP_URL_CERRADURAS}/locker?nro_serie=${nroSerie}`
    return axios
            .get(url)
            .then((response) => {
                return response.data;
            })
            .catch((ex) => {
                console.log(`error obtener tipo locker: ${ex}`)
            });
    
}

// Función envia mensaje a web socket
const openlocker = () => {
    // mostrar mensaje loading
    showLoading.value = true;

    // parametros
    const request = {
        id_tipo_locker: toRaw(packageSelected.value),
        correo: toRaw(userSelected.value),
        nro_serie: toRaw(nroSerie.value),
        type: 'REPARTIDOR-ODIHNX'
    }

    // envio de mensaje
    socket.value.send(JSON.stringify(request));

    //si en 10 segundos no hay mensajes exitosos, mostrar modal de error
    timeoutTimer.value = setTimeout(changeShowModal, 10000);

}

const parseNroLocker = (lockerResponse) => {
    numeroLocker = lockerResponse.split(' ')[2]
}

//Funciones para cambios de estado
const changeShowModal = () => {
    showLoading.value = false;
    showModal.value = !showModal.value;    
}

const deleteSelections = () => {
    showModal.value = false;

    // variables de navegación
    userSelected.value = "";
    packageSelected.value = "";
    viewVoucher.value = "";
    confirmDelivery.value = "";

    // variables de voucher
    userName.value = "";
    lockerType.value = "";

};

// obtener parametros según entrada desde qr
onMounted( async() => {

    const params = route.params;

    if ("id" in params && "name" in params && "device" in params){

        intalationSelected.value = Number(params.id);
        addressName.value = params.name;
        nroSerie.value = params.device;
        typeLockersAvailable.value = await getTypeLockersAvailable(toRaw(nroSerie.value));

    } else {
        console.log("error parametros");
    }
});

// luego del renderizado de componentes, se instancia web socket
onMounted(() => {
    socket.value = new WebSocket(`${process.env.VUE_APP_URL_SOCKET}`);

    socket.value.onmessage = (e) => {
      try {
        const jsonData = JSON.parse(e.data);
        // Mensaje recibido ok
        if (jsonData.messageDevice && jsonData.code == 200) {
            clearTimeout(timeoutTimer.value); // elimina setTimeout previo (no se muestra modal de error)
            if(jsonData.messageDevice.split(" ").length == 4) {
                console.log(jsonData.messageDevice)
                parseNroLocker(jsonData.messageDevice)
                showLoading.value = false; //Oculto loading
                confirm.value = true;
                finish.value = "ok";
            }
        // Mensaje con excepción desde servidor    
        } else {
          console.log(`solicitud: ${JSON.stringify(jsonData)}`);
        }

      } catch (error) {
        if (error instanceof SyntaxError) {
          console.log(
            'JSON string is not in the correct format:',
            error.message
          )
        } else {
          console.log('Error parsing JSON:', error.message);
        }
      }
    }
});

//observo valiable de guardado, para abrir locker (mensaje a web socket)
watch(confirmDelivery, () => {
    if (confirmDelivery.value){
        openlocker(); //enviar mensaje a web socket
    }
    
});

</script>

<template>
    <div class="flex flex-col items-center min-h-screen bg-[#0064ff]">

        <div class="max-w-md mt-8 space-y-8 px-6 flex-grow w-full">

            <!-- Botón de regreso y logo -->
            <div class="grid grid-cols-5 items-center">
                <!-- Primer espacio: Botón -->
                <button 
                    v-if="!finish && step !== 2"
                    @click="backButton"
                    class="mb-auto flex h-12 w-12 justify-center items-center rounded-md border border-transparent 
                            hover:bg-blue-300 bg-[#FFB800] py-2 px-4 text-sm text-black font-bold group">
                    <span class="text-lg">&lt;</span>
                </button>
                <!-- Primer espacio: Vacío si se completo el formulario -->
                <div v-if="addressName && !viewVoucher && !confirmDelivery" class="flex text-white space-x-2 w-max">
                    <h3>Ubicación:</h3>
                    <h3 class="font-semibold">
                        {{  
                            addressName && addressName.length > 25 ?
                            addressName.substring(0, 25) + " ..." :
                            addressName 
                        }}
                    </h3>
                </div>

            </div>
            
            <!-- Texto si websocket falla -->
            <UniversalModal @cancel="deleteSelections" :isOpen="showModal">
                <RejectedText v-if="showModal" :hideModal="deleteSelections"/>
            </UniversalModal>
            <LoadingComponent class="object-none" v-if="showLoading" />

            <!-- Formulario -->
            <div class="flex-grow">

                <div>

                    <DeptoSelect 
                        :intalationSelected="intalationSelected"
                        @update:deptoSelected="value => deptoSelected = value" 
                        @update:step="value => step = value" 
                        @update:nroDpto="value => nroDpto = value" 
                        v-if="step === 2 && intalationSelected !== ''"
                    />
                    <UserSearch
                        :deptoSelected="deptoSelected"
                        @update:userName="value => userName = value" 
                        @update:userSelected="value => userSelected = value" 
                        @update:step="value => step = value" 
                        v-else-if="step === 3"
                    />

                    <SelectPackage
                        :typeLockersAvailable="typeLockersAvailable"
                        :lockerType="lockerType"
                        @update:lockerType="value => lockerType = value" 
                        @update:packageSelected="value => packageSelected = value" 
                        @update:viewVoucher="value => viewVoucher = value" 
                        @update:step="value => step = value" 
                        v-else-if="step === 4"
                    />

                    <DeliveryVoucher
                        :addressName="addressName"  
                        :userName="userName"
                        :lockerType="lockerType"
                        :nroDpto="nroDpto"
                        @update:confirmDelivery="value => confirmDelivery = value" 
                        @update:step="value => step = value" 
                        v-else-if="step === 5"
                    />

                    <PackageSaved
                        :numeroLocker="numeroLocker"
                        v-else-if="step === 6 && !showLoading && !showModal && confirm"
                    />

                </div>
            </div>
            
        </div>

        <NewFooter/>
        
    </div>
</template>