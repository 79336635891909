<template>
  <div class="bg-blue-600 h-screen flex flex-col items-center justify-center">
    <div class="border-4 drop-shadow-md bg-white border-white rounded-full p-6 mb-8 flex items-center justify-center">
      <svg xmlns="http://www.w3.org/2000/svg" class="w-24 h-24 text-red-500 bg-white rounded-full p-2" fill="none" viewBox="0 0 24 24" stroke="currentColor">
        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12"></path>
      </svg>
      <h1 class="text-3xl font-bold ml-4 ">No tienes permisos para acceder a esta vista</h1>
    </div>
    <router-link to="/" class="bg-white text-blue rounded-md drop-shadow-md font-bold py-2 px-4 text-center hover:bg-black hover:text-white">Volver a Inicio</router-link>
  </div>
</template>
<script>
export default {
  name: 'UnauthorizedView',
  created () {
    localStorage.removeItem('token')
    localStorage.removeItem('user')
    localStorage.removeItem('role')
  }

}
</script>
