<script setup>
import { onMounted, ref } from 'vue';
import { useRoute } from 'vue-router';
import axios from 'axios';
import router from '@/router';

const selectedInstallation = ref('');
const devices = ref('');
const route = useRoute();

onMounted( async () => {
    const params = route.params;

    if ("qr" in params){
        await axios
            .get(`${process.env.VUE_APP_URL_BACKEND}/qr/getRackByQrValue?value=${params?.qr}`)
            .then((response) => {
                selectedInstallation.value = response.data?.instalacion;
                devices.value = response.data?.dispositivos;
            })
            .catch( () => {
                selectedInstallation.value = null;
                devices.value = null;
            });

        const selectedId = selectedInstallation.value?.id;
        const selectedName = selectedInstallation.value?.nombre_instalacion;

        if (selectedId && selectedName){
            if (devices.value !== null) {
                const devSelected = devices.value[0]?.nro_serie;

                router.push({name: 'StartWithQrVue', params: {device: devSelected, id: selectedId, name: selectedName}});
            } else {
                router.push({name: 'Error 404'});
            }
        } else {
            router.push({name: 'Error 404'});
        }
    } else {
        router.push({name: 'Error 404'});
    }
});

</script>

<template>
    <div></div>
</template>
