<template>
    <div class="min-h-screen flex flex-col justify-between">
        <MenuNavbar />
        <OpenInvitation class="h-full grow justify-self-start" />
        <FooterComponent />
    </div>
</template>

<script>
import OpenInvitation from '@/components/OpenInvitation.vue'
import MenuNavbar from '@/components/ui/MenuNavbar.vue'
import FooterComponent from '@/components/ui/FooterComponent.vue'
export default {
  name: 'InvitationView',
  components: {
    OpenInvitation,
    MenuNavbar,
    FooterComponent
  }
}
</script>
