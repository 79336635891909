<script setup>

</script>

<template>
    <footer class="text-center z-20 w-full p-4">
        <div class="text-xs text-center text-white mb-2">
            Términos y condiciones | Políticas de privacidad
        </div>
        <div class="text-sm text-center text-white font-bold">
            © 2024 Odihnx - V1.0
        </div>
    </footer>
</template>