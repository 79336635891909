<template>
  <div>
    <Disclosure as="nav" class="bg-[#0064ff]" v-slot="{ open }">
      <div class="mx-auto max-w-7xl px-2 sm:px-6 lg:px-8">
        <div class="relative flex h-16 items-center justify-between">
          <div class="absolute inset-y-0 left-0 flex items-center sm:hidden">
            <!-- Mobile menu button-->
            <DisclosureButton
              class="inline-flex items-center justify-center rounded-md p-2 text-white hover:bg-blue-200 hover:text-white focus:outline-nonef focus:ring-2 focus:ring-inset focus:ring-white">
              <Bars3Icon v-if="!open" class="block h-6 w-6" aria-hidden="true" />
              <XMarkIcon v-else class="block h-6 w-6" aria-hidden="true" />
            </DisclosureButton>
          </div>
          <div class="flex flex-1 items-center justify-center sm:items-stretch sm:justify-start">
            <div class="flex flex-shrink-0 items-center">
              <img class="block h-8 w-auto lg:hidden cursor-pointer" src="@/assets/xs_odihnx_logo.png"
                @click="$router.push('/menu')" />
              <img class="hidden h-8 w-auto lg:block cursor-pointer" src="@/assets/xs_odihnx_logo.png"
                @click="$router.push('/menu')" />
            </div>
            <div class="hidden sm:ml-6 sm:block">
              <div class="flex space-x-4">
                <NavbarContent
                  v-for="item in navigation"
                  :key="item.name"
                  v-model:name="item.name"
                  v-model:href="item.href"
                  v-model:current="item.current"
                  v-model:referenceMenu="referenceMenu"
                  :changeContextMenu="changeContextMenu"
                  v-model:showContextMenu="showContextMenu"
                  class="relative"
                />
              </div>
            </div>
          </div>
          <div class="absolute inset-y-0 right-0 flex items-center pr-2 sm:static sm:inset-auto sm:ml-6 sm:pr-0">
            <Menu as="div" class="relative ml-3 flex items-center">

              <div>
                <MenuButton
                  class="flex  p-3 text-sm focus:outline-none focus:ring-1focus:ring-offset-2">
                  <p class="truncate-text text-white place-self-center mr-3 cursor-pointer text-sm font-medium" 
                    :class="{ 'truncate': isMdOrSmaller }">
                    {{ truncatedName }}
                  </p>
                  <span class="sr-only">Open user menu</span>
                  <img class="h-8 w-8 rounded-full" src="@/assets/user.png" />
                </MenuButton>
              </div>
              <transition enter-active-class="transition ease-out duration-100"
                enter-from-class="transform opacity-0 scale-95" enter-to-class="transform opacity-100 scale-100"
                leave-active-class="transition ease-in duration-75" leave-from-class="transform opacity-100 scale-100"
                leave-to-class="transform opacity-0 scale-95">
                <MenuItems
                  class="absolute right-0 z-10 mt-10 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                  <MenuItem v-slot="{ active }">
                  <a @click="
                    $router.push({
                      name: 'ProfileComponentView',
                      params: { user: name },
                    })
                    " :class="[
    active ? 'bg-gray-100' : '',
    'block px-4 py-2 text-sm cursor-pointer truncate',
  ]">{{ name }}</a>
                  </MenuItem>
                  <MenuItem v-slot="{ active }">
                  <a @click="logout" :class="[
                    active ? 'bg-gray-100' : '',
                    'block px-4 py-2 text-sm cursor-pointer',
                  ]">Cerrar Sesión</a>
                  </MenuItem>
                </MenuItems>
              </transition>
            </Menu>
          </div>
        </div>
      </div>

      <DisclosurePanel class="sm:hidden">
        <div class="space-y-1 px-2 pt-2 pb-3">
          <DisclosureButton v-for="item in navigation" :key="item.name" as="a" :href="item.href" :class="[
            item.current
              ? 'bg-gray-900  text-white '
              : 'text-white  hover:bg-gray-700 hover:text-white',
            'block px-3 py-2 cursor-pointer rounded-md text-base font-medium ',
          ]" :aria-current="item.current ? 'page' : undefined">{{ item.name }}</DisclosureButton>
        </div>
      </DisclosurePanel>
    </Disclosure>
  </div>
</template>

<script>
import {
  Disclosure,
  DisclosureButton,
  DisclosurePanel,
  Menu,
  MenuButton,
  MenuItem,
  MenuItems
} from '@headlessui/vue'
import { Bars3Icon, XMarkIcon } from '@heroicons/vue/24/outline'
import NavbarContent from './NavbarContent.vue';

export default {
  name: 'MenuNavbar',
  created () {
    this.name = localStorage.getItem('user')
    if (localStorage.getItem('role') == 1) {
      this.navigation.splice(2, 0, {
        name: 'Entidades',
        href: '/menu/entities',
        current: false
      })
    }

    //responsividad de nombre usuario
    this.checkScreenSize();
    window.addEventListener('resize', this.checkScreenSize);
  },
  components: {
    Disclosure,
    DisclosureButton,
    DisclosurePanel,
    Menu,
    MenuButton,
    MenuItem,
    MenuItems,
    Bars3Icon,
    XMarkIcon,
    NavbarContent
  },
  data () {
    return {
      name,
      isMdOrSmaller: false,
      navigation: [
        { name: 'Reservar', href: '/menu/schedule', current: false },
        { name: 'Mis reservas', href: '/menu/active', current: false },
        { name: 'Inicio', href: '/menu', current: false }
      ],
      showContextMenu: false,
      referenceMenu: ''
    }
  },
  computed: {
    truncatedName() {
      if (this.isMdOrSmaller) {
        if(this.name.length > 7){
          return this.name.slice(0, 7) + "...";
        }else {
          return this.name;
        }
        
      } else {
        return this.name;
      }
    },
  },
  methods: {
    logout () {
      localStorage.removeItem('token')
      localStorage.removeItem('user')
      localStorage.removeItem('role')
      this.$router.push('/')
    },
    changeContextMenu(value, name){
      this.referenceMenu = name;
      this.showContextMenu = value;
    },
    checkScreenSize() {
      this.isMdOrSmaller = window.innerWidth < 768; // Ajusta el valor según tus necesidades
    }
  },
  beforeUnmount() {
    window.removeEventListener('resize', this.checkScreenSize);
  },
}
</script>
