<template>
  <div class="h-full flex flex-col justify-center gap-1	basis-1/2">
    <div class="flex flex-row mx-5 justify-center">
      <div class="flex flex-col gap-1 basis-1/2 mt-5 bg-white border-2 shadow rounded-2xl py-4 px-8 text-center">
        <h1 class="text-3xl font-bold text-gray-900">
          {{ this.$route.params.company ? "Editar Administracion" : "Añadir Administración" }}
        </h1>
      </div>
    </div>
    <div class="flex flex-row mx-5 justify-center">
      <form class="flex flex-col gap-1 basis-1/2 mt-5 mb-20 bg-white border-2 shadow rounded-2xl py-4 px-8"
      v-on:submit="submitCompany">
        <span class="text-red-600"> {{ msg }} </span>
        <br /><br />
        <span class="mt-10"> Correo: </span><br />
        <input v-model="mail"
          class="w-full border px-4 py-2 rounded-full focus:border-blue-500 focus:shadow-outline outline-none "
          type="text" placeholder="Correo de contacto de la empresa" />
        <span> Nombre Contacto: </span><br />
        <input v-model="contactName"
          class="w-full border px-4 py-2 rounded-full focus:border-blue-500 focus:shadow-outline outline-none" type="text"
          placeholder="Ingrese el nombre de la persona a cargo" />
        <span> Numero de contacto: </span><br />
        <input v-model="contactNumber"
          class="w-full border px-4 py-2 rounded-full focus:border-blue-500 focus:shadow-outline outline-none" type="text"
          placeholder="Numero de la persona a cargo" />
        <span> Rut: </span><br />
        <input v-model="rut"
          class="w-full border px-4 py-2 rounded-full focus:border-blue-500 focus:shadow-outline outline-none" type="text"
          placeholder="Rut de la empresa" />
        <button class="bg-blue-500 p-4 m-4 self-end font-bold text-white grow rounded-md hover:bg-blue-900 border-b-4"
          type="submit">
          {{ this.$route.params.company ? "Editar empresa" : "Crear empresa" }}
        </button>
      </form>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
export default {
  name: 'CreateCompanyForm',
  props: {},
  data () {
    return {
      mail: '',
      contactName: '',
      contactNumber: '',
      rut: '',
      msg: ''
    }
  },
  created () {
    if (this.$route.params.company) {
      this.fetchCompanyInfo()
    }
  },
  methods: {
    async createCompany () {
      const company = {
        correo: this.mail,
        nombre_contacto: this.contactName,
        telefono_contacto: this.contactNumber,
        rut: this.rut
      }
      const headers = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + localStorage.getItem('token')
        }
      }
      const response = await axios
        .post(
          process.env.VUE_APP_URL_BACKEND + '/administraciones/crear',
          company,
          headers
        )
        .then((response) => {
          if (response.status === 200) {
            this.$router.push({ name: 'ShowCompaniesView' })
          }
        })
        .catch((error) => {
          this.msg = 'ERROR AL CREAR EMPRESA: ' + error.message
          window.scrollTo(0, 0) // Scrolls to the top when error msg pops
        })
      return response
    },
    async fetchCompanyInfo () {
      const params = {
        id: this.$route.params.company
      }
      const headers = {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + localStorage.getItem('token')
      }
      const response = await axios
        .get(
          process.env.VUE_APP_URL_BACKEND +
          '/administraciones/getAdministracion',
          {
            params: params,
            headers: headers
          }
        )
        .then((response) => {
          if (response.status == 200) {
            this.mail = response.data.correo
            this.contactName = response.data.nombre_contacto
            this.contactNumber = response.data.telefono_contacto
            this.rut = response.data.rut
          }
        })
        .catch((error) => {
          this.msg = 'ERROR AL OBTENER EMPRESA: ' + error.message
          window.scrollTo(0, 0) // Scrolls to the top when error msg pops
        })
      return response
    },
    async submitCompany (e) {
      e.preventDefault()
      if (this.validateForm()) return
      if (this.$route.params.company) {
        await this.editCompany()
      } else {
        await this.createCompany()
      }
    },
    async editCompany () {
      const company = {
        correo: this.mail,
        nombre_contacto: this.contactName,
        telefono_contacto: this.contactNumber,
        rut: this.rut,
        id: this.$route.params.company
      }
      const headers = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + localStorage.getItem('token')
        }
      }
      const response = await axios
        .put(
          process.env.VUE_APP_URL_BACKEND + '/administraciones/actualizar',
          company,
          headers
        )
        .then((response) => {
          if (response.status === 200) {
            this.$router.push({ name: 'ShowCompaniesView' })
          }
        })
        .catch((error) => {
          this.msg = 'ERROR AL EDITAR EMPRESA' + error.response.data.message
          window.scrollTo(0, 0) // Scrolls to the top when error msg pops
        })
      return response
    },
    validateForm () {
      const regexEmail =
        /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/
      const regexRut = /^[\d\.-]+$/; //eslint-disable-line
      const regexPhone = /^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s\./0-9]*$/; //eslint-disable-line
      const regexName = /[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/; //eslint-disable-line
      if (!regexEmail.test(this.mail)) {
        this.msg = 'Correo invalido'
        window.scrollTo(0, 0) // Scrolls to the top when error msg pops
        return true
      }
      if (regexName.test(this.contactName)) {
        this.msg = 'Nombre de contacto invalido'
        window.scrollTo(0, 0) // Scrolls to the top when error msg pops
        return true
      }
      if (!regexPhone.test(this.contactNumber)) {
        this.msg = 'Numero de contacto invalido'
        window.scrollTo(0, 0) // Scrolls to the top when error msg pops
        return true
      }
      if (!regexRut.test(this.rut)) {
        this.msg = 'Rut invalido'
        window.scrollTo(0, 0) // Scrolls to the top when error msg pops
        return true
      }
      return false
    }
  }
}
</script>
