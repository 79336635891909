<script setup>
import { ref, onMounted, computed, watch, toRaw } from "vue";
import axios from 'axios';
import router from '@/router';
import { useRoute } from 'vue-router';

const route = useRoute();

// update data
const qr = ref(null);
const checkbox = ref(null);
const checkboxUpdateImg = computed(() => {
  if ((route?.params?.qr && checkbox.value === true )|| !route?.params?.qr) {
    return false;
  } else {
    return true;
  }
});

// form params
const comment = ref('');
const image = ref(null);
const value = ref('');
const urlBucketImg = ref('');

const setImage = (e) => {
  image.value = e.target.files[0];
}

const getQrById = async (id) => {
  const headers = {
    'Content-Type': 'application/json',
    Authorization: 'Bearer ' + localStorage.getItem('token')
  }

  const response = await axios
    .get(process.env.VUE_APP_URL_BACKEND + '/qr/getQr?id=' + id, {
      headers: headers
    })
    .then((response) => {
      qr.value = response.data;
      comment.value = response.data?.comentarios;
      value.value = response.data?.value;
      urlBucketImg.value = response.data?.url_bucket_img;
    })
    .catch((error) => {
      console.log(error);
    });

  return response;

}

const submitQr = async () => {
  try {
    if (!route?.params?.qr) { // create

      await createQr();

    } else { // update

      await updateQr();

    }

  } catch (ex) {
    console.log(`Error: ${ex}`);
  }
};

const updateQr = async () => {
  try {
    const url = `${process.env.VUE_APP_URL_BACKEND}/qr/actualizar`;

    const formData = new FormData();
    formData.append('comentarios', comment.value);
    formData.append('value', value.value);
    formData.append('id', route.params.qr);

    if (checkbox.value){
      formData.append('image', image.value);
      formData.append('url_bucket_img', 'none');
    } else {
      formData.append('url_bucket_img', urlBucketImg.value);
    }

    await axios.post(url, formData, {
      headers: {
        Authorization: 'Bearer ' + localStorage.getItem('token'),
        'Content-Type': 'multipart/form-data'
      }
    })
      .then(() => router.push({ name: 'ShowQrsView' })
      )
      .catch(ex => {
        console.log(`API call response: ${ex}`);
      });


  } catch (ex) {
    console.log(`Error: ${ex}`);
  }

}

const createQr = async () => {
  try {
    const url = `${process.env.VUE_APP_URL_BACKEND}/qr/crear`;
    const formData = new FormData();
    formData.append('comentarios', comment.value);
    formData.append('value', value.value);
    formData.append('image', image.value);

    await axios.post(url, formData, {
      headers: {
        Authorization: 'Bearer ' + localStorage.getItem('token'),
        'Content-Type': 'multipart/form-data'
      }
    })
      .then(() => router.push({ name: 'ShowQrsView' })
      )
      .catch(ex => {
        console.log(`API call response: ${ex}`);
      });
  } catch (ex) {
    console.log(`Error: ${ex}`);
  }
}


onMounted(async () => {
  const params = route.params;
  if ("qr" in params) {
    await getQrById(params['qr']);
  }
});

watch(checkbox, () => {
  console.log(toRaw(checkbox.value));
});

</script>

<template>
  <div class="h-full flex flex-col justify-center gap-1 basis-1/2">

    <div class="flex flex-row mx-5 justify-center">
      <div class="flex flex-col gap-1 basis-1/2 mt-5 bg-white border-2 shadow rounded-2xl py-4 px-8 text-center">
        <h1 class="text-3xl font-bold text-gray-900">
          <!-- {{ this.$route.params.company ? "Editar Qr" : "Añadir Qr" }} -->
          {{ "Añadir Qr" }}
        </h1>
      </div>
    </div>

    <div class="flex flex-row mx-5 justify-center">
      <form class="flex flex-col gap-1 basis-1/2 mt-5 mb-20 bg-white border-2 shadow rounded-2xl py-4 px-8"
        @submit.prevent="submitQr()">
        <br />
        <span class="mt-10"> Comentarios: </span>
        <br />
        <input v-model="comment"
          class="w-full border px-4 py-2 rounded-full focus:border-blue-500 focus:shadow-outline outline-none "
          type="text" placeholder="Comentarios asociados" />

        <br />
        <span class="mt-10"> Valor de Qr: </span>
        <br />
        <input v-model="value"
          class="w-full border px-4 py-2 rounded-full focus:border-blue-500 focus:shadow-outline outline-none "
          type="text" placeholder="Valor qr de un rack" />


        <br />
        <label v-if="route?.params?.qr" class="inline-flex items-center">
          <input type="checkbox" v-model="checkbox" />
          <span class="ml-2 text-xs text-gray-400"> ¿Reemplazar imagen? </span>
        </label>
        <span class="mt-2"> Subir imagen: </span>
        <input v-on:change="setImage"
          class="w-full border px-4 py-2 rounded-full focus:border-blue-500 focus:shadow-outline outline-none"
          type="file" accept="image/*" :disabled="checkboxUpdateImg" />
        <br />

        <button class="bg-blue-500 p-4 m-4 self-end font-bold text-white grow rounded-md hover:bg-blue-900 border-b-4"
          type="submit">
          <!-- {{ this.$route.params.company ? "Editar empresa" : "Crear empresa" }} -->
          <!-- {{ "Crear qr" }} -->
          {{ route?.params?.qr ? "Editar Qr" : "Crear Qr" }}
        </button>
      </form>
    </div>
  </div>

</template>