<template>
    <div class="px-10">
      <h2>Email Support</h2>


      <!-- <form @submit.prevent="sendInstalationEmail"> -->
      <form>
        <div class="mb-4">
          <label for="instalationId">Instalaciones:</label>
          <select v-model="instalationId" id="instalations" name="instalations" class="border rounded px-2 py-1">
            <option value="select" disabled selected>-- Seleccionar --</option>
            <option v-for="instalation in instalations" :key="instalation.id" :value="instalation.id"
              >{{ instalation.nombre_instalacion }}
            </option>
          </select>          
        </div>

        <div class="flex mb-5 space-x-5">
          <button @click.prevent="choiceButton('instalacion')" class="bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-4 rounded">
            Correo a instalación
          </button>
          <button @click.prevent="choiceButton('usuario')" class="bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-4 rounded">
            Correo a usuario
          </button>
        </div>

        <div v-if="usersByInstalation && choice === 'usuario'" class="mb-4">
          <label for="instalationId">Usuarios:</label>
          <select v-model="user" id="user" name="user" class="border rounded px-2 py-1">
            <option value="select" disabled selected>-- Seleccionar --</option>
            <option v-for="(userinst, index) in usersByInstalation" :key="index" :value="userinst"
              >{{ userinst }}
            </option>
          </select>          
        </div>

        <div>
          <div class="mb-4">
            <label for="subject">Subject:</label>
            <input v-model="subject" type="text" id="subject" class="border rounded px-2 py-1">
          </div>

          <div class="mb-4">
            <label for="message">Message:</label>
            <textarea v-model="message" id="message" class="border rounded px-2 py-1"></textarea>
          </div>

          <div class="flex flex-col w-40">
            <div v-if="!notificationExcel">
              <button v-if="choice === 'instalacion'" @click="sendInstalationEmail()" class="bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-4 rounded">
                Send Email to instalation
              </button>
              <button v-if="choice === 'usuario'" @click="sendEmail()" class="bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-4 rounded">
                Send Email to user
              </button>
            </div>

            <p v-if="notificationEmail">
              {{ notificationEmail }}
            </p>          
          </div> 
        </div>       
      </form>

      <div v-if="instalationId">
      <button
        v-if="!notificationExcel && choice"
          class="mt-5 w-40 bg-green-500 hover:bg-green-600 text-white font-bold py-2 px-4 rounded cursor-pointer" 
          :disabled="!instalationId"
          @click="generateExcel()"
        >
          excel
        </button>
        <p v-if="notificationExcel">
          {{ notificationExcel }}
        </p>
      </div>

    </div>
  </template>
  
  <script>
  import axios from 'axios';
  import * as XLSX from 'xlsx';
  
  export default {
    data() {
      return {
        user: '',
        subject: '',
        message: '',
        usersEmail: [],
        instalations: [],
        instalationId: '',
        usersByInstalation: '',
        notificationExcel: '',
        notificationEmail: '',
        choice: ''
      };
    },
    mounted(){
      this.getUsers();
      this.getInstalations();
    },
    watch:{
      instalationId: function () {
      this.getUsersByInstalation();
    }
    },
    methods: {
      async sendEmail() {
        try {
          const url = `${process.env.VUE_APP_URL_CERRADURAS}/email/send`;
          await axios.post(url, {
            emailto: this.user,
            asunto: this.subject,
            body: this.message,
          }, {
            headers: {
              Authorization: 'Bearer ' + localStorage.getItem('token')
            }
          });
                    
          // Clear the form fields
          this.user = '';
          this.subject = '';
          this.message = '';
  
          // You can also display a success message to the user
        } catch (error) {
          console.error('Error sending email:', error);
          // Handle error and show an error message to the user
        }
      },
      choiceButton(value){
        this.choice = value;
      },
      async sendInstalationEmail(){
        try{
          const url = `${process.env.VUE_APP_URL_BACKEND}/auth/usuarios/instalacion/${this.instalationId}/emails/send`;
          
          await axios.post(url, {
              asunto: this.subject,
              body: this.message,
            }, {
            headers: {
              Authorization: 'Bearer ' + localStorage.getItem('token'),
              "Content-Type": "application/json"
            }
          })
          .then(() => {
            this.notificationEmail = 'Email enviado';
            setTimeout(()=> {
              this.notificationEmail = '';
            }, 3000)
          })
          .catch(ex => {
            this.notificationEmail = 'Email no enviado';
            setTimeout(()=> {
              this.notificationEmail = '';
            }, 3000)
            console.log(`error: ${ex}`);
          })
          
        }catch(ex){
          console.log(`Error sendInstalationEmail(): ${ex}`);
        }
      },
      async getUsers(){
        try{
          const url = `${process.env.VUE_APP_URL_BACKEND}/auth/getUsuarios`;
          const res =  await axios.get(url, {
            headers: {
              Authorization: 'Bearer ' + localStorage.getItem('token')
            }
          });

          const userData = res?.data?.content;
          this.usersEmail = Object.keys(userData).map(key =>{
            return {
              correo: userData[key].correo, 
              instalacion: userData[key].instalacion,
              id: userData[key].id
            };
          })

        }catch(error){
          console.error('Error getUsers:', error);
        }
      },
      async getUsersByInstalation(){
        try{
          this.notificationExcel = '';
          const url = `${process.env.VUE_APP_URL_BACKEND}/auth/usuarios/instalacion/${this.instalationId}/emails`;
          const res =  await axios.get(url, {
            headers: {
              Authorization: 'Bearer ' + localStorage.getItem('token')
            }
          }).catch(error =>{
            console.log(error)
          })

          const users = res?.data

          if (users.length === 0){
            this.notificationExcel = "Instalación sin usuarios";
            // setTimeout(()=>{
            //   this.notificationExcel = '';
            // }, 3000);
            return;
          } 

          this.usersByInstalation = users;

          //this.generateExcel();

        }catch(ex){
          console.log(`Error getUsersByInstalation(): ${ex}`);
        }
      },
      async getInstalations(){
        const url = `${process.env.VUE_APP_URL_BACKEND}/instalaciones/getInstalaciones`;
        const res =  await axios.get(url, {
          headers: {
            Authorization: 'Bearer ' + localStorage.getItem('token')
          }
        });

        const instalationsData = res?.data;
        this.instalations = Object.keys(instalationsData).map(key =>{
          return {
            id: instalationsData[key].id,
            nombre_instalacion: instalationsData[key].nombre_instalacion
          }
        })

      },
      generateExcel(){
        const data = this.usersByInstalation.map((correo) => [correo]); // Convierte los correos en una matriz de una sola columna
        const ws = XLSX.utils.aoa_to_sheet(data);
        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, 'Correos');
        const filename = 'correos.xlsx';
        XLSX.writeFile(wb, filename);

      }
    },
  };
  </script>