<template>
  <div class="m-8 py-3 px-4 mb-3 sm:px-6 lg:px-8 bg-white shadow-md h-full">
    <div class="flex flex-col flex-nowrap">
      <div class="flex justify-between">
        <h1 class="text-3xl font-bold tracking-tight text-gray-900">
          Información de administracion
        </h1>
      </div>
      <div class="grid grid-cols-2">
        <div class="m-2 p-2 border rounded-md">
          RUT: {{ company.rut ? company.rut : "No disponible" }}
        </div>
        <div class="m-2 p-2 border rounded-md">
          Nombre contacto:
          {{
            company.nombre_contacto ? company.nombre_contacto : "No disponible"
          }}
        </div>
        <div class="m-2 p-2 border rounded-md">
          Correo: {{ company.correo ? company.correo : "No disponible" }}
        </div>
        <div class="m-2 p-2 border rounded-md">
          Telefono:
          {{
            company.nombre_contacto ? company.nombre_contacto : "No disponible"
          }}
        </div>
      </div>
      <div class="grid grid-cols-2"></div>
      <div class="flex justify-between">
        <h1 class="text-3xl font-bold tracking-tight text-gray-900 my-4"> Instalaciones </h1>
      </div>
      <table class="w-full pt-5 table-auto border-x border-b">
        <thead>
          <tr>
            <th
              class="border-b border-l border-blue-500 bg-blue-500 p-2 text-center font-bold text-white"
            >
              ID Reserva
            </th>
            <th
              class="border-b border-l border-blue-500 bg-blue-500 p-2 text-center font-bold text-white"
            >
              ID Locker
            </th>
            <th
              class="border-b border-l border-blue-500 bg-blue-500 p-2 text-center font-bold text-white"
            >
              N° Locker
            </th>
            <th
              class="border-b border-l border-blue-500 bg-blue-500 p-2 text-center font-bold text-white"
            >
              Acciones
            </th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-bind:key="index"
            :value="row.id"
            v-for="(row, index) in activeReservations"
            class="odd:bg-gray-100 hover:!bg-stone-200 cursor-pointer"
          >
            <td class="border-b border-l p-2 text-left">
              {{ row.id }}
            </td>
            <td class="border-b border-l p-2 text-left">
              {{ row.id_device }}
            </td>
            <td
              class="border-b border-l p-2 text-left"
              @click="
                $router.push({
                  name: 'InputPassView',
                  query: {
                    code: row.clave_actual,
                    id_device: row.id_device,
                    n_locker: row.nro_locker,
                  },
                })
              "
            >
              {{ row.nro_locker }}
            </td>
            <td
              class="border-b border-l p-2 flex justify-center"
              @click="deleteReserve(row.id)"
            >
              <svg class="max-w-10 max-h-10" viewBox="0 0 20 20">
                <path
                  d="M17.114,3.923h-4.589V2.427c0-0.252-0.207-0.459-0.46-0.459H7.935c-0.252,0-0.459,0.207-0.459,0.459v1.496h-4.59c-0.252,0-0.459,0.205-0.459,0.459c0,0.252,0.207,0.459,0.459,0.459h1.51v12.732c0,0.252,0.207,0.459,0.459,0.459h10.29c0.254,0,0.459-0.207,0.459-0.459V4.841h1.511c0.252,0,0.459-0.207,0.459-0.459C17.573,4.127,17.366,3.923,17.114,3.923M8.394,2.886h3.214v0.918H8.394V2.886z M14.686,17.114H5.314V4.841h9.372V17.114z M12.525,7.306v7.344c0,0.252-0.207,0.459-0.46,0.459s-0.458-0.207-0.458-0.459V7.306c0-0.254,0.205-0.459,0.458-0.459S12.525,7.051,12.525,7.306M8.394,7.306v7.344c0,0.252-0.207,0.459-0.459,0.459s-0.459-0.207-0.459-0.459V7.306c0-0.254,0.207-0.459,0.459-0.459S8.394,7.051,8.394,7.306"
                  fill="red"
                ></path>
              </svg>
            </td>
          </tr>
        </tbody>
      </table>

    </div>
  </div>
</template>
<script>
import axios from 'axios'
export default {
  name: 'CompanyDetailComponent',
  data () {
    return {
      company: {}
    }
  },
  async created () {
    const response = await axios
      .get(
        `${process.env.VUE_APP_URL_BACKEND}/administraciones/getAdministracion`,
        {
          headers: {
            Authorization: 'Bearer ' + localStorage.getItem('token')
          },
          params: {
            id: this.$route.params.company
          }
        }
      )
      .then((response) => {
        this.company = response.data
      })
    return response
  },
  methods: {}
}
</script>
