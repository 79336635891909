<template>
  <TransitionRoot appear :show="isOpen" as="template">
    <Dialog as="div" @close="cancel" class="relative z-50">
      <TransitionChild
        as="template"
        enter="duration-300 ease-out"
        enter-from="opacity-0"
        enter-to="opacity-100"
        leave="duration-200 ease-in"
        leave-from="opacity-100"
        leave-to="opacity-0"
      >
        <div class="fixed inset-0 bg-black bg-opacity-25" />
      </TransitionChild>

      <div class="fixed inset-0 overflow-y-auto">
        <div
          class="flex min-h-full items-center justify-center p-4 text-center"
        >
          <TransitionChild
            as="template"
            enter="duration-300 ease-out"
            enter-from="opacity-0 scale-95"
            enter-to="opacity-100 scale-100"
            leave="duration-200 ease-in"
            leave-from="opacity-100 scale-100"
            leave-to="opacity-0 scale-95"
          >
            <DialogPanel
              class="w-full max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all"
            >
              <DialogTitle
                as="h3"
                class="text-lg font-medium leading-6 text-gray-900"
              >
                {{ modalTitle }}
              </DialogTitle>
              <div
                v-if="loadingModal"
                class="h-full flex justify-center items-center"
              >
                <div
                  class="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-[#0064ff]"
                ></div>
              </div>
              <div v-else class="">
                <div class="mt-2">
                  <p class="text-sm text-gray-500">
                    {{ msg }}
                  </p>
                </div>
                <div class="mt-4">
                  <button
                    type="button"
                    class="inline-flex mr-5 justify-center rounded-md border border-transparent bg-blue-100 px-4 py-2 text-sm font-medium text-blue-900 hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
                    @click="send"
                  >
                    Confirmar
                  </button>
                  <button
                    v-if="canCancel"
                    type="button"
                    class="inline-flex justify-center rounded-md border border-transparent bg-blue-100 px-4 py-2 text-sm font-medium text-blue-900 hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
                    @click="cancel"
                  >
                    Cancelar
                  </button>
                </div>
              </div>
            </DialogPanel>
          </TransitionChild>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>
</template>

<script>
import {
  TransitionRoot,
  TransitionChild,
  Dialog,
  DialogPanel,
  DialogTitle
} from '@headlessui/vue'

export default {
  components: {
    TransitionRoot,
    TransitionChild,
    Dialog,
    DialogPanel,
    DialogTitle
  },
  props: {
    modalTitle: { type: String },
    msg: { type: String, default: '123456' },
    loadingModal: { type: Boolean, default: false },
    number: { type: String, default: 'XX' },
    onSend: {
      type: Function
    },
    onCancel: {
      type: Function
    },
    isOpen: { type: Boolean, default: false },
    canCancel: { type: Boolean, default: true }
  },
  methods: {
    send () {
      this.$emit('send')
    },
    cancel () {
      this.$emit('cancel')
    }
  }
}
</script>
