<template>
    <DialogTitle as="h3" class="text-lg font-semibold leading-6 text-gray-900 mb-3">
        Procesando solicitud
    </DialogTitle>
    <div class="w-full h-full flex flex-col items-center justify-center p-4">
        <div class="animate-spin rounded-full h-16 w-16 my-4 border-t-4 border-blue-500"></div>
        <div class="flex justify-end self-end gap-2 mt-2">
            <button type="button" @click="hideModal"
                class="inline-flex justify-center rounded-md border border-transparent bg-blue-100 px-4 py-2 text-sm font-medium text-blue-900 hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2 my-4">
                Volver
            </button>
        </div>
    </div>
</template>

<script>
import {
  DialogTitle
} from '@headlessui/vue'
export default {
  name: 'LoadingText',
  components: {
    DialogTitle
  },
  props: {
    hideModal: {
      type: Function
    }
  }
}
</script>
