<template>
  <div class="min-h-screen bg-[#0064ff]">
    <LoginForm />
  </div>
</template>
<script>
import LoginForm from '@/components/forms/LoginForm.vue'
export default {
  name: 'LoginView',
  components: { LoginForm },
  mounted () {
    const googleLogin = document.createElement('script')
    googleLogin.setAttribute('src', 'https://accounts.google.com/gsi/client')
    document.head.appendChild(googleLogin)
  }
}
</script>
