<script setup>
import { defineEmits, defineProps } from 'vue';
import AdaptableButton from './AdaptableButton.vue';

const emits = defineEmits(['update:confirmDelivery', 'update:step']);

const props = defineProps({
    addressName: {
    type: String,
    required: true,
  },
    userName: {
    type: String,
    required: true,
  },
    lockerType: {
    type: String,
    required: true,
  },
    nroDpto: {
    type: String,
    required: true,
  }

});

const confirmDelivery = () => {
    emits('update:confirmDelivery', 'ok');
    emits('update:step', 6);
}

</script>

<template>
    <div class="flex flex-col">
        <div class="text-center">
            <h2 class="text-2xl font-semibold text-white"> Confirmación </h2>
        </div>

        <div class="overflow-x-auto mt-6 mb-6">
          <table class="min-w-full border border-white border-4">
            <tr>
              <th class="w-1/12 px-2 py-3 text-left text-sm font-medium text-white uppercase border-r-4 border-gray-200">Dirección:</th>
              <td class="px-2 py-3 text-sm font-medium text-white uppercase truncate">
                {{
                  props.addressName && props.addressName.length > 25 ?
                  props.addressName.substring(0, 25) + " ..." :
                  props.addressName
                }}
              </td>
            </tr>
            <tr>
              <th class="w-1/12 px-2 py-3 text-left text-sm font-medium text-white uppercase border-r-4 border-gray-200">NRO DEPTO:</th>
              <td class="px-2 py-3 text-sm font-medium text-white uppercase truncate">
                {{
                  props.nroDpto ?
                  props.nroDpto :
                  '-'
                }}
              </td>
            </tr>
            <tr>
              <th class="w-1/12 px-2 py-3 text-left text-sm font-medium text-white uppercase border-r-4 border-gray-200 w-2/5">Usuario:</th>
              <td class="px-2 py-3 text-sm font-medium text-white uppercase truncate w-3/5">
                {{
                  props.userName && props.userName.length > 25 ?
                  props.userName.substring(0, 25) + " ..." :
                  props.userName
                }}
              
              </td>
            </tr>
            <tr>
              <th class="w-1/12 px-2 py-3 text-left text-sm font-medium text-white uppercase border-r-4 border-gray-200 w-2/5">Locker:</th>
              <td class="px-2 py-3 text-sm font-medium text-white uppercase truncate w-3/5">
                {{
                  props.lockerType && props.lockerType.length > 25 ?
                  props.lockerType.substring(0, 25) + " ..." :
                  props.lockerType
                }}
              
              </td>
            </tr>
          </table>
        </div>


        <AdaptableButton
            :text="'Entregar paquete'"
            :bgColor="'black'"
            :textColor="'white'"
            @input-function="confirmDelivery"
        /> 

    </div>
 
</template>