<script setup>
import { onMounted } from 'vue';
import { useRoute } from 'vue-router';
import router from '@/router';

const route = useRoute();

onMounted( async () => {
    const params = route.params;

    if ("nro" in params){

        window.location.href = `http://wa.me/${params.nro}`;

    } else {
        router.push({name: 'Error 404'});
    }
});

</script>

<template>
    <div></div>
</template>