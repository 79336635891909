<template>
    <DialogTitle as="h3" class="text-lg font-semibold leading-6 text-gray-900 mb-3">
        Eliminar Reserva
    </DialogTitle>
    <p class="text-center text-2xl my-5"> ¿Desea Eliminar la reserva?</p>
    <div class="flex justify-end gap-2">
        <button type="button" @click="acceptFunction"
            class="inline-flex justify-center rounded-md border border-transparent bg-blue-100 px-4 py-2 text-sm font-medium text-blue-900 hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2 my-4">
            Aceptar
        </button>
        <button type="button" @click="hideModal"
            class="inline-flex justify-center rounded-md border border-transparent bg-blue-100 px-4 py-2 text-sm font-medium text-blue-900 hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2 my-4">
            Cancelar
        </button>
    </div>
</template>
<script>
import {
  DialogTitle
} from '@headlessui/vue'

export default {
  name: 'DeleteReservationText',
  components: {
    DialogTitle
  },
  props: {
    acceptFunction: {
      type: Function
    },
    hideModal: {
      type: Function
    }
  }
}
</script>
