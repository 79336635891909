<template>
  <div class="flex flex-row justify-between content-center mb-7">
    <div class="grow"></div>
    <div
      class="flex gap-2 sm:px-8 sm:pb-8 mx-4 p-4 flex-col justify-center content-center mt-10 flex-nowrap bg-white/80 border-2 rounded-lg border-slate-300">
      <div v-if="isAdmin" class="flex flex-col flex-nowrap gap-2 sm:w-96 justify-self-start">
        <p class="text-3xl text-center font-bold mr-4">Reservas de Locker</p>
        <DropDown :elements="racks" :elementFormat="optionsFormat" :selectedFormat="selectedFormat"
          @selected="beSelect" class="my-5" />
      </div>

      <div class="flex flex-row justify-center flex-nowrap gap-2 sm:w-96"></div>
      <div id="row-1" class="flex flex-row justify-center flex-nowrap gap-2 sm:w-96">
        <LockerButton :buttonProps="lockerInfo[0]" @click="openModal" />
        <LockerButton :buttonProps="lockerInfo[1]" @click="openModal" />
        <LockerButton :buttonProps="lockerInfo[2]" @click="openModal" />
        <LockerButton :buttonProps="lockerInfo[3]" @click="openModal" />
      </div>
      <div id="row-2" class="flex flex-row justify-center flex-nowrap gap-2 sm:w-96">
        <LockerButton :buttonProps="lockerInfo[4]" @click="openModal" />
        <LockerButton :buttonProps="lockerInfo[5]" @click="openModal" />
      </div>
      <div id="row-3" class="flex flex-row justify-center flex-nowrap gap-2 sm:w-96">
        <LockerButton :buttonProps="lockerInfo[6]" @click="openModal" />
        <LockerButton :buttonProps="lockerInfo[7]" @click="openModal" />
      </div>
      <div id="row-4" class="flex flex-row justify-center flex-nowrap gap-2 sm:w-96">
        <LockerButton :buttonProps="lockerInfo[8]" @click="openModal" />
        <LockerButton :buttonProps="lockerInfo[9]" @click="openModal" />
      </div>
      <div id="row-5" class="flex flex-row justify-center flex-nowrap gap-2 sm:w-96">
        <LockerButton :buttonProps="lockerInfo[10]" @click="openModal" />
        <LockerButton :buttonProps="lockerInfo[11]" @click="openModal" />
      </div>
      <div id="row-6" class="flex flex-row justify-center flex-nowrap gap-2 sm:w-96">
        <LockerButton :buttonProps="lockerInfo[12]" @click="openModal" />
        <LockerButton :buttonProps="lockerInfo[13]" @click="openModal" />
      </div>
      <div id="row-7" class="flex flex-row justify-center flex-nowrap gap-2 sm:w-96">
        <LockerButton :buttonProps="lockerInfo[14]" @click="openModal" />
        <LockerButton :buttonProps="lockerInfo[15]" @click="openModal" />
      </div>
      <RentModal ref="childComponentRef" @send="openLocker" @cancel="hideModal"
        :modalTitle="`¿Desea arrendar el locker ${this.number}?`"
        :msg="` Si estas seguro tu código de apertura lo puedes encontrar en el menú en la seccion de 'Arriendos Activos' y además será enviado a tu correo.`"
        :isOpen="this.showModal" :loadingModal="this.loadingModal" />
    </div>
    <div class="grow"></div>
  </div>
</template>
<script>
import LockerButton from '@/components/ui/LockerButton.vue'
import DropDown from '@/components/ui/DropDownDevices.vue'
import RentModal from '@/components/ui/RentModal.vue'
import axios from 'axios'
import { toRaw } from 'vue'

export default {
  name: 'OpenLocker',
  components: { LockerButton, DropDown, RentModal },
  data () {
    return {
      number: null,
      lockerId: null,
      isAdmin: null,
      showModal: false,
      loadingModal: false,
      installations: [],
      selectedInstallation: null,
      lockerInfo: [
        {
          id: 1,
          active: true,
          size: 'small'
        },
        {
          id: 2,
          active: true,
          size: 'small'
        },
        {
          id: 3,
          active: true,
          size: 'small'
        },
        {
          id: 4,
          active: true,
          size: 'small'
        },
        {
          id: 5,
          active: true,
          size: 'small'
        },
        {
          id: 6,
          active: true,
          size: 'small'
        },
        {
          id: 7,
          active: true,
          size: 'small'
        },
        {
          id: 8,
          active: true,
          size: 'small'
        },
        {
          id: 9,
          active: true,
          size: 'small'
        },
        {
          id: 10,
          active: true,
          size: 'small'
        },
        {
          id: 11,
          active: true,
          size: 'small'
        },
        {
          id: 12,
          active: true,
          size: 'small'
        },
        {
          id: 13,
          active: true,
          size: 'small'
        },
        {
          id: 14,
          active: true,
          size: 'small'
        },
        {
          id: 15,
          active: true,
          size: 'big'
        },
        {
          id: 16,
          active: true,
          size: 'big'
        }
      ]
    }
  },
  async created () { // Fetches data from backend when the component is created
    this.isAdmin = localStorage.getItem('role') == 1
    const responseInstallations = await axios
      .get(`${process.env.VUE_APP_URL_BACKEND}/instalaciones/getInstalaciones`, {
        headers: {
          Authorization: 'Bearer ' + localStorage.getItem('token')
        }
      })
      .then((response) => {
        this.installations = response.data
      })

    return [responseInstallations]
  },
  computed: {
    racks () {
      const racks = []
      for (const installation of this.installations) {
        for (const rack of installation.racks) {
          if (rack.dispositivos.length > 0) {
            racks.push([installation.id, installation.nombre_instalacion + (installation.racks.length > 1 ? (' - ' + rack.ubicacion) : ''), toRaw(rack)])
          }
        }
      }
      return racks
    }
  },
  methods: {
    async beSelect (e) { // Selects device
      this.selectedInstallation = e
      //console.log(e)
      await this.loadData();
    },
    openModal (e) { // Opens modal
      if (this.selectedInstallation && this.lockerInfo[e.target.id - 1].active) {
        this.showModal = true;
        this.number = e.target.id;
      }
    },
    hideModal () {
      this.showModal = false;
    },
    async openLocker () { // Actually rents locker
      this.loadingModal = true;
      const response = await axios.post(
        `${process.env.VUE_APP_URL_CERRADURAS}/arriendo`,
        {
          nro_locker: this.number,
          id_device: this.selectedInstallation[2].dispositivos[0].nro_serie,
          usuario: localStorage.getItem('user'),
          instalacion: this.selectedInstallation[0]
        },
        {
          headers: {
            Authorization: 'Bearer ' + localStorage.getItem('token')
          }
        }
      )
      this.hideModal()
      this.loadingModal = false
      await this.loadData()
      return response
    },
    async loadData () { // Loads data from backend
      const response = await axios
        .get(
          `${process.env.VUE_APP_URL_CERRADURAS}/arriendo/device/${this.selectedInstallation[2].dispositivos[0].nro_serie}/arriendos`,
          {
            headers: {
              Authorization: 'Bearer ' + localStorage.getItem('token')
            }
          }
        )
        .then((response) => {
          console.log(response.data);
          return response.data.content
        })
      for (const j in this.lockerInfo) {
        this.lockerInfo[j].active = true
      }
      for (const i in response) {
        this.lockerInfo[response[i].nro_locker - 1].active = !this.lockerInfo[
          response[i].nro_locker - 1
        ].active
      }
      console.log(this.lockerInfo)
    },
    optionsFormat (element) { // Formats options for dropdown
      return element ? element[1] : 'Error: no hay nombre'
    },
    selectedFormat (selected) { // Formats selected option for dropdown
      return selected ? selected[1] : 'Selecciona tu ubicacion'
    }
  },
  props: {
    lockerCode: String
  }
}
</script>
