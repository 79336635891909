<template>
  <button
    :class="[
        'font-bold h-12 bg-green-500 border-green-700 hover:bg-green-600 text-white flex-1 rounded border-b-4 truncate aspect-square focus:ring-green-900 focus:ring-opacity-25 transition-all duration-150 ease-in-out',
        {
          'bg-red-500 border-red-700 hover:bg-red-700 hover:border-red-900 cursor-not-allowed': !props.buttonProps.active,
          'h-24': props.buttonProps.size === 'big',
          'bg-[#4299e1] border-[#2c5282] hover:bg-[#2c5282] hover:border-[#1a365d]':props.buttonProps.id?.toString() === props.selectedLocker?.toString() && props.buttonProps.active
        }
      ]"
    :disabled="!props.buttonProps.active"
    :id="props.buttonProps.id"
    @click="handleClick(props.buttonProps.id)"
    >
    
    <div :id="props.buttonProps.id" v-if="props.buttonProps.active">
      {{ props.buttonProps.id }}
    </div>
    <div :id="props.buttonProps.id" class="flex place-content-center" v-else>
      <svg class="h-9 w-9" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
        viewBox="-47.39 -47.39 568.71 568.71" xml:space="preserve" fill="#000000" stroke="#000000"
        stroke-width="15.165792" transform="rotate(0)matrix(1, 0, 0, 1, 0, 0)">
        <g stroke-width="0" transform="translate(236.9655,236.9655), scale(0)">
          <rect x="-47.39" y="-47.39" width="568.71" height="568.71" rx="284.355" fill="#000106" strokewidth="0"></rect>
        </g>
        <g stroke-linecap="round" stroke-linejoin="round" stroke="#CCCCCC" stroke-width="35.070893999999996"></g>
        <g>
          <circle style="fill:#E84849;" cx="236.966" cy="236.966" r="236.966"></circle>
          <path style="fill:#F4F5F5;"
            d="M429.595,245.83c0,16.797-13.624,30.417-30.417,30.417H74.73c-16.797,0-30.421-13.62-30.421-30.417 v-17.743c0-16.797,13.624-30.417,30.421-30.417h324.448c16.793,0,30.417,13.62,30.417,30.417V245.83z">
          </path>
        </g>
      </svg>
    </div>

  </button>
</template>

<script setup>
import { defineEmits, ref, defineProps } from 'vue';

const emits = defineEmits(['update:buttonColorControl', 'update:selectedLocker']);

const props = defineProps({
  buttonProps: {
    type: [Object],
    required: true,
  },

  buttonColorControl: {
    type: [String, Number, null],
    required: true,
  },
  selectedLocker: {
    type: [String, Number, null],
    required: true,
  },
});

const currentId = ref('');

const handleClick = (id) =>{
  currentId.value = id; 
  emits('update:buttonColorControl', id);
  emits('update:selectedLocker', id);
}
</script>
