<template>
  <div class="h-full flex flex-col justify-center gap-1	basis-1/2">
    <div class="flex flex-row mx-5 justify-center">
      <div class="flex flex-col gap-1 basis-1/2 mt-5 bg-white border-2 shadow rounded-2xl py-4 px-8 text-center">
        <h1 class="text-3xl font-bold text-gray-900">
          {{ this.$route.params.user ? "Editar Usuario" : "Crear usuario" }}
        </h1>
      </div>
    </div>
    <div class="flex flex-row mx-5 justify-center">
      <form class="flex flex-col gap-2 basis-1/2 mt-5 mb-20 bg-white border-2 shadow rounded-2xl py-4 px-8"
        v-on:submit="submitUser">
        <span class="text-red-600"> {{ msg }} </span>
        <br /><br />
        <span> Correo: </span><br />
        <input v-model="mail"
          class="w-full border px-4 py-2 rounded-full focus:border-blue-500 focus:shadow-outline outline-none" type="text"
          placeholder="ejemplo@123.cl" />
        <span> Usuario: </span><br />
        <input v-model="username"
          class="w-full border px-4 py-2 rounded-full focus:border-blue-500 focus:shadow-outline outline-none" type="text"
          placeholder="Usuario para el registro" />
        <span> Nombre: </span><br />
        <input v-model="name"
          class="w-full border px-4 py-2 rounded-full focus:border-blue-500 focus:shadow-outline outline-none" type="text"
          autocomplete="off" placeholder="Nombre completo" />
        <span> Contraseña: </span><br />
        <input v-model="password"
          class="w-full border px-4 py-2 rounded-full focus:border-blue-500 focus:shadow-outline outline-none"
          type="password" autocomplete="off" placeholder="*********" />
        <span> Repetir Contraseña: </span><br />
        <input v-model="confirmPassword"
          class="w-full border px-4 py-2 rounded-full focus:border-blue-500 focus:shadow-outline outline-none"
          type="password" autocomplete="off" placeholder="*********" />
        <span> Direccion: </span><br />
        <input v-model="direction"
          class="w-full border px-4 py-2 rounded-full focus:border-blue-500 focus:shadow-outline outline-none" type="text"
          placeholder="Direccion" />
        <span> Telefono: </span><br />
        <input v-model="phone"
          class="w-full border px-4 py-2 rounded-full focus:border-blue-500 focus:shadow-outline outline-none" type="text"
          placeholder="Numero de Telefono" />
        <span> Instalación: </span><br />
        <Listbox v-model="selectedInstallation">
          <ListboxButton
            class="relative w-full cursor-default rounded-lg bg-white py-2 pl-3 pr-10 text-left shadow-md focus:outline-none focus-visible:border-indigo-500 focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 focus-visible:ring-offset-blue-300 sm:text-sm">
            {{
              selectedInstallation
              ? selectedInstallation.nombre_instalacion
              : "Seleccione una instalación"
            }}
          </ListboxButton>
          <ListboxOptions
            class="mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
            <ListboxOption v-slot="{ active, selected }" v-for="thing in this.installations" :key="thing.id"
              :value="thing" as="template">
              <li :class="[
                active ? 'bg-blue-100 text-blue-900' : 'text-gray-900',
                'relative cursor-default select-none py-2 pl-10 pr-4',
              ]">
                <span :class="[
                  selected ? 'font-medium' : 'font-normal',
                  'block truncate',
                ]">{{ thing.nombre_instalacion }}</span>
                <span v-if="selected" class="absolute inset-y-0 left-0 flex items-center pl-3 text-blue-600">
                  <CheckIcon class="h-5 w-5" aria-hidden="true" />
                </span>
              </li>
            </ListboxOption>
          </ListboxOptions>
        </Listbox><br />
        <span> Rol: </span><br />
        <input v-model="role"
          class="w-full border px-4 py-2 rounded-full focus:border-blue-500 focus:shadow-outline outline-none" type="text"
          placeholder="ID de rol" />
        <button class="bg-blue-500 p-4 m-4 self-end font-bold text-white grow rounded-md hover:bg-blue-900 border-b-4"
          type="submit">
          {{ this.$route.params.user ? "Editar usuario" : "Crear usuario" }}
        </button>
      </form>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import {
  Listbox,
  ListboxButton,
  ListboxOptions,
  ListboxOption
} from '@headlessui/vue'
import { CheckIcon } from '@heroicons/vue/20/solid'
export default {
  name: 'CreateUserForm',
  components: {
    Listbox,
    ListboxButton,
    ListboxOptions,
    ListboxOption,
    CheckIcon
  },
  props: {},
  data() {
    return {
      username: '',
      mail: '',
      password: '',
      confirmPassword: '',
      name: '',
      direction: '',
      phone: '',
      selectedInstallation: null,
      group: '',
      role: '',
      msg: '',
      installations: []
    }
  },
  async created() {
    const headers = {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + localStorage.getItem('token')
    }
    this.installations = await axios
      .get(process.env.VUE_APP_URL_BACKEND + '/instalaciones/getInstalaciones', {
        headers: headers
      })
      .then((response) => {
        return response.data
      })
    if (this.$route.params.user) {
      this.fetchUserInfo()
    }
  },
  methods: {
    async createUser() {
      const user = {
        active: true,
        casa_depto: this.direction,
        email: this.mail,
        grupo: this.group,
        instalacion: this.selectedInstallation.id,
        nombre: this.name,
        password: this.password,
        password_pregunta_secreta: '',
        phone: this.phone,
        pregunta_secreta: 'string',
        username: this.username,
        rol: this.role
      }
      const headers = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + localStorage.getItem('token')
        }
      }
      const response = await axios
        .post(
          process.env.VUE_APP_URL_BACKEND + '/auth/registrar',
          user,
          headers
        )
        .then((response) => {
          if (response.status == 200) {
            this.$router.push({ name: 'ShowUsersView' })
          }
        })
        .catch((error) => {
          this.msg = 'ERROR AL CREAR USUARIO' + error.message
          window.scrollTo(0, 0)
        })
      return response
    },
    async fetchUserInfo() {
      const params = {
        emailOrUser: this.$route.params.user
      }
      const headers = {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + localStorage.getItem('token')
      }
      const response = await axios
        .get(process.env.VUE_APP_URL_BACKEND + '/auth/usuario/info', {
          params: params,
          headers: headers
        })
        .then((response) => {
          if (response.status === 200) {
            this.username = response.data.usuario
            this.mail = response.data.correo
            this.name = response.data.nombre
            this.direction = response.data.casa_depto
            this.phone = response.data.telefono
            this.selectedInstallation = response.data.instalacion
          }
        })
        .catch((error) => {
          this.msg = 'Error al obtener informacion del usuario' + error.message
          window.scrollTo(0, 0)
        })
      return response
    },
    async submitUser(e) {
      e.preventDefault()
      if (this.validatesForm()) return
      if (this.$route.params.user) {
        await this.editUser()
      } else {
        await this.createUser()
      }
    },
    async editUser() {
      const user = {
        casa_depto: this.direction,
        correo: this.mail,
        nombre: this.name,
        telefono: this.phone,
        usuario: this.username
      }
      const headers = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + localStorage.getItem('token')
        }
      }
      const response = await axios
        .put(
          process.env.VUE_APP_URL_BACKEND + '/auth/actualizar',
          user,
          headers
        )
        .then((response) => {
          if (response.status === 200) {
            this.$router.push({ name: 'ShowUsersView' })
          }
        })
        .catch((error) => {
          this.msg = 'Error al editar usuario' + error.message
          window.scrollTo(0, 0)
        })
      return response
    },
    validatesForm() {
      const regexEmail =
        /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/
      const regexPhone = /^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s\./0-9]*$/; //eslint-disable-line
      if (!regexEmail.test(this.mail)) {
        this.msg = 'El correo no es valido'
        window.scrollTo(0, 0)
        return true
      }
      if (this.username.length < 6) {
        this.msg = 'El usuario debe tener al menos 6 caracteres'
        window.scrollTo(0, 0)
        return true
      }
      if (this.password.length < 6) {
        this.msg = 'La contraseña debe tener al menos 6 caracteres'
        window.scrollTo(0, 0)
        return true
      }
      if (this.name === '') {
        this.msg = 'El nombre no puede estar vacio'
        window.scrollTo(0, 0)
        return true
      }
      if (this.password != this.confirmPassword) {
        this.msg = 'Las contraseñas no coinciden'
        window.scrollTo(0, 0)
        return true
      }
      if (!regexPhone.test(this.phone)) {
        this.msg = 'El numero de telefono no es valido'
        window.scrollTo(0, 0)
        return true
      }
      if (
        this.direction === ''
      ) {
        this.msg = 'Todos los campos son obligatorios'
        window.scrollTo(0, 0)
        return true
      }
      return false
    }
  }
}
</script>
