<template>
  <div class="flex flex-row justify-between min-h-screen">
    <div />
    <div class="flex flex-col justify-between gap-10">
      <div />
      <div class="flex flex-col justify-center gap-7 bg-white border p-10 border-1 rounded-xl border-gray-500">
        <OTP @receive="this.receiveCode" :code="this.$route.query.code" />
        <p class="text-sm">
          No compartas este codigo con <a class="font-bold"> nadie </a>
        </p>
      </div>
      <div />
      <div />
    </div>
    <div />
  </div>
</template>

<script>
import OTP from '@/components/ui/OTPForm.vue'
export default {
  name: 'InputCode',
  components: { OTP },
  data () {
    return {
      code: this.$route.query.code ? this.$route.query.code : '',
      loading: false,
      timeoutTimer: null,
      socket: null
    }
  },
  created () {
    this.socket = new WebSocket(process.env.VUE_APP_URL_SOCKET)
    const vm = this
    this.socket.onmessage = (e) => {
      try {
        const jsonData = JSON.parse(e.data)
        if (jsonData.messageDevice && jsonData.code === 200) {
          clearTimeout(vm.timeoutTimer)
          vm.$router.push({ name: 'SuccessOpenView' })
        }
      } catch (error) {
        if (error instanceof SyntaxError) {
          console.log(
            'JSON string is not in the correct format:',
            error.message
          )
        } else {
          console.log('Error parsing JSON:', error.message)
        }
      }
    }
  },
  methods: {
    sendToSocket: function () {
      this.loading = true
      const request = {
        usuario: 'ccampos',
        mesasageRequest: 'test websocket',
        type: 'apertura',
        clave: this.code,
        // chapa: {
        chapaDTO: {
          id: this.$route.query.id_device,
          nro_chapa: this.$route.query.n_locker,
          fecha_arriendo: Date.now()
        }
      }
      this.socket.send(JSON.stringify(request))
      this.timeoutTimer = setTimeout(this.redirectToFailView, 10000)
    },
    redirectToFailView: function () {
      this.$router.push({ name: 'FailureOpenView' })
    },
    receiveCode: function (code) {
      this.code = code
      this.sendToSocket()
    }
  }
}
</script>
