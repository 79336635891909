<template>
  <div>
    <div class="pb-4 bg-white mt-5">
      <label for="table-search" class="sr-only">Search</label>
      <div class="relative mt-1">
        <div class="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
          <svg class="w-5 h-5 text-gray-500" aria-hidden="true" fill="currentColor" viewBox="0 0 20 20"
            xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd"
              d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
              clip-rule="evenodd"></path>
          </svg>
        </div>
        <input v-model="search" type="text" id="table-search"
          class="block p-2 pl-10 text-sm text-gray-900 border border-gray-300 rounded-lg w-80 bg-gray-50 focus:ring-blue-500 focus:border-blue-500"
          placeholder="Buscar en la pagina" />
      </div>
    </div>
    <table class="w-full table-auto border-x border-b">
      <thead>
        <tr>
          <th v-for="(column, index) in headers" :key="index"
            class="border-b border-l border-blue-500 bg-blue-500 p-2 text-left font-bold text-white truncate cursor-pointer"
            @click="sort(column.field)">
            <div class="flex flex-row justify-center items-center">
              <p> {{ column.name }} </p>
              <a v-if="column.sortable"><svg xmlns="http://www.w3.org/2000/svg" class="w-3 h-3 ml-1" aria-hidden="true"
                  fill="currentColor" viewBox="0 0 320 512">
                  <path
                    d="M27.66 224h264.7c24.6 0 36.89-29.78 19.54-47.12l-132.3-136.8c-5.406-5.406-12.47-8.107-19.53-8.107c-7.055 0-14.09 2.701-19.45 8.107L8.119 176.9C-9.229 194.2 3.055 224 27.66 224zM292.3 288H27.66c-24.6 0-36.89 29.77-19.54 47.12l132.5 136.8C145.9 477.3 152.1 480 160 480c7.053 0 14.12-2.703 19.53-8.109l132.3-136.8C329.2 317.8 316.9 288 292.3 288z" />
                </svg></a>
            </div>
          </th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(row, row_index) in dataInfo" :key="row_index"
          class="odd:bg-gray-100 even:bg-white hover:!bg-stone-200">
          <td v-for="(box, key) in headers" :key="key" class="border-b border-l p-2 text-left">
            {{ row[box.field] }}
          </td>
        </tr>
      </tbody>
    </table>
    <nav class="flex items-center justify-between pt-4" aria-label="Table navigation">
      <span v-if="this.pagination.totalPages" class="text-sm font-normal text-gray-500">
        Mostrando
        <span class="font-semibold text-gray-900">{{ this.pagination.currentPage * this.pagination.perPage + 1 }}-{{
          this.pagination.totalItems >
          this.pagination.currentPage * this.pagination.perPage + 10
          ? this.pagination.currentPage * this.pagination.perPage + 11
          : this.pagination.totalItems + 1
        }}</span>
        de
        <span class="font-semibold text-gray-900">
          {{ this.pagination.totalItems + 1 }}
        </span></span>
      <ul class="inline-flex items-center -space-x-px">
        <li v-if="this.pagination.currentPage > 0" @click="previousPage()" class="cursor-pointer">
          <a
            class="block px-3 py-2 ml-0 leading-tight text-gray-500 bg-white border border-gray-300 rounded-l-lg hover:bg-gray-100 hover:text-gray-700">
            <span class="sr-only">Previous</span>
            <svg class="w-5 h-5" aria-hidden="true" fill="currentColor" viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg">
              <path fill-rule="evenodd"
                d="M12.707 5.293a1 1 0 010 1.414L9.414 10l3.293 3.293a1 1 0 01-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z"
                clip-rule="evenodd"></path>
            </svg>
          </a>
        </li>
        <li>
          <a class="px-3 py-2 leading-tight text-gray-500 bg-white border border-gray-300">{{ this.pagination.currentPage
            + 1 }}</a>
        </li>
        <li v-if="this.pagination.currentPage < this.pagination.totalPages" @click="nextPage()" class="cursor-pointer">
          <a
            class="block px-3 py-2 leading-tight text-gray-500 bg-white border border-gray-300 rounded-r-lg hover:bg-gray-100 hover:text-gray-700">
            <span class="sr-only">Next</span>
            <svg class="w-5 h-5" aria-hidden="true" fill="currentColor" viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg">
              <path fill-rule="evenodd"
                d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                clip-rule="evenodd"></path>
            </svg>
          </a>
        </li>
      </ul>
    </nav>
  </div>
</template>

<script>
export default {
  name: 'UniversalTable',
  props: {
    headers: {
      type: Array,
      required: true
    },
    dataInfo: {
      type: Array
    },
    pagination: {
      type: Object,
      default: () => {
        return {
          currentPage: 0,
          perPage: 10,
          sortingOrder: 'asc',
          currentField: '',
          totalPages: 0,
          totalItems: 0
        }
      }
    }

  },
  data () {
    return {
      search: ''
    }
  },
  methods: {
    sort (field) {
      this.$emit('sort', field)
    },
    nextPage () {
      this.$emit('nextPage', this.pagination)
    },
    previousPage () {
      this.$emit('previousPage', this.pagination)
    }
  },
  computed: {
    filteredInfo () {
      return this.dataInfo
        .map((item) => {
          return {
            id: item.id,
            id_device: item.id_device,
            fecha: this.formatDate(item.fecha),
            fecha_arriendo: this.formatDate(item.fecha_arriendo),
            resultado: item.estado[0]
              ? item.estado[0].nombre
              : 'No se consigna',
            comentario: item.estado[0]
              ? item.estado[0].descripcion
              : 'No se consigna'
          }
        })
        .filter((i) => {
          for (const j in i) {
            const field = i[j].toString()
            if (field.toLowerCase().includes(this.search.toLowerCase())) {
              return true
            }
          }
          return false
        })
    }
  },
  watch: {
    search: {
      handler () {
        this.$emit('search', this.search)
      }
    }
  }
}
</script>
