<template>
    <Transition name="fade">
      <div
        class="m-4 sm:m-20 grid grid-cols-2 md:grid-cols-3 gap-4 sm:gap-y-6 content-center object-center place-content-stretch justify-items-center">
        <button v-for="item in this.items" :key="item.id" @click="$router.push({ path: `/menu/admin/${item.href}` })"
          class="xs:w-44 xs:h-46 transition-all bg-white flex flex-col shadow-xl p-4 sm:p-6 border-gray-700 rounded-lg border-4 cursor-pointer hover:bg-gray-200 focus:border-blue-700 focus:border-6 hover:shadow-2xl">
          <img v-bind:src="item.img" class="rounded-md  sm:w-48 sm:h-48" />
          <h3 class="text-xl mt-4 text-gray-700 text-center font-bold break-words select-none w-full">
            {{ item.name }}
          </h3>
        </button>
      </div>
    </Transition>
  </template>
  
  <script>
  export default {
    name: 'AdminMenu',
    props: {},
    data () {
      return {
        items: [
          {
            id: 1,
            name: 'Reservas',
            href: 'reservation',
            img: require('@/assets/book.png')
          },
          {
            id: 2,
            name: 'Estado dispositivos',
            href: 'devices',
            img: require('@/assets/open_locker.svg')
          },
/*
          {
            id: 3,
            name: 'Mensajería',
            href: 'services',
            img: require('@/assets/wip.svg')
          },
          {
            id: 4,
            name: 'Bloquear instalaciones',
            href: '',
            img: require('@/assets/wip.svg')
          },
          {
            id: 5,
            name: 'Listar usuarios',
            href: 'users',
            img: require('@/assets/wip.svg')
          },
*/          
          {
            id: 6,
            name: 'Gestión Repartidor',
            href: 'Delivery',
            img: require('@/assets/package.png')
          }
        ]
      }
    }
  }
  </script>
  