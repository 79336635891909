<template>
  <button
    class="bg-blue-500 hover:bg-blue-700 text-white font-bold sm:py-2 sm:px-4 rounded"
    @click="onClickFunction"
  >
    {{ text }}
  </button>
</template>

<script>
export default {
  name: 'BlueButton',
  props: {
    text: String,
    onClickFunction: Function
  },
  data () {
    return {
      editable: false
    }
  }
}
</script>
