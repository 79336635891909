<template>
    <tbody class="xs:hidden">
        <tr class="odd:bg-gray-100 even:bg-white md:h-12">
            <td class="border-b border-l p-2 text-center  animate-pulse">
                <div class="flex justify-center content-center">
                    <div class="h-2.5 bg-gray-300 rounded-full  w-24"></div>
                </div>
            </td>
            <td class="border-b border-l p-2 text-center animate-pulse">
                <div class="flex justify-center content-center">
                    <div class="h-2.5 bg-gray-300 rounded-full  w-24"></div>
                </div>
            </td>
            <td class="border-b border-l p-2 text-center  animate-pulse">
                <div class="flex justify-center content-center">
                    <div class="h-2.5 bg-gray-300 rounded-full  w-24"></div>
                </div>
            </td>
            <td class="border-b border-l p-2 text-center  animate-pulse">
                <div class="flex justify-center content-center">
                    <div class="h-2.5 bg-gray-300 rounded-full  w-24"></div>
                </div>
            </td>
            <td class="border-b border-l p-2 text-center  animate-pulse">
                <div class="flex justify-center content-center">
                    <div class="h-2.5 bg-gray-300 rounded-full  w-24"></div>
                </div>
            </td>
            <td class="border-b border-l p-2 gap-2 text-center  animate-pulse">
                <div class="flex justify-center content-center">
                    <div class="h-2.5 bg-gray-300 rounded-full grow"></div>
                    <div class="ml-2 h-2.5 bg-gray-300 rounded-full grow"></div>
                    <div class="ml-2 h-2.5 bg-gray-300 rounded-full grow"></div>
                </div>
            </td>
        </tr>
        <tr class="odd:bg-gray-100 even:bg-white md:h-12">
            <td class="border-b border-l p-2 text-center  animate-pulse">
                <div class="flex justify-center content-center">
                    <div class="h-2.5 bg-gray-300 rounded-full  w-24"></div>
                </div>
            </td>
            <td class="border-b border-l p-2 text-center animate-pulse">
                <div class="flex justify-center content-center">
                    <div class="h-2.5 bg-gray-300 rounded-full  w-24"></div>
                </div>
            </td>
            <td class="border-b border-l p-2 text-center  animate-pulse">
                <div class="flex justify-center content-center">
                    <div class="h-2.5 bg-gray-300 rounded-full  w-24"></div>
                </div>
            </td>
            <td class="border-b border-l p-2 text-center  animate-pulse">
                <div class="flex justify-center content-center">
                    <div class="h-2.5 bg-gray-300 rounded-full  w-24"></div>
                </div>
            </td>
            <td class="border-b border-l p-2 text-center  animate-pulse">
                <div class="flex justify-center content-center">
                    <div class="h-2.5 bg-gray-300 rounded-full  w-24"></div>
                </div>
            </td>
            <td class="border-b border-l p-2 gap-2 text-center  animate-pulse">
                <div class="flex justify-center content-center">
                    <div class="h-2.5 bg-gray-300 rounded-full grow"></div>
                    <div class="ml-2 h-2.5 bg-gray-300 rounded-full grow"></div>
                    <div class="ml-2 h-2.5 bg-gray-300 rounded-full grow"></div>
                </div>
            </td>
        </tr>
        <tr class="odd:bg-gray-100 even:bg-white md:h-12">
            <td class="border-b border-l p-2 text-center animate-pulse">
                <div class="flex justify-center content-center">
                    <div class="h-2.5 bg-gray-300 rounded-full  w-24"></div>
                </div>
            </td>
            <td class="border-b border-l p-2 text-center animate-pulse">
                <div class="flex justify-center content-center">
                    <div class="h-2.5 bg-gray-300 rounded-full  w-24"></div>
                </div>
            </td>
            <td class="border-b border-l p-2 text-center animate-pulse">
                <div class="flex justify-center content-center">
                    <div class="h-2.5 bg-gray-300 rounded-full  w-24"></div>
                </div>
            </td>
            <td class="border-b border-l p-2 text-center animate-pulse">
                <div class="flex justify-center content-center">
                    <div class="h-2.5 bg-gray-300 rounded-full  w-24"></div>
                </div>
            </td>
            <td class="border-b border-l p-2 text-center animate-pulse">
                <div class="flex justify-center content-center">
                    <div class="h-2.5 bg-gray-300 rounded-full  w-24"></div>
                </div>
            </td>
            <td class="border-b border-l p-2 gap-2 text-center animate-pulse">
                <div class="flex justify-center content-center">
                    <div class="h-2.5 bg-gray-300 rounded-full grow"></div>
                    <div class="ml-2 h-2.5 bg-gray-300 rounded-full grow"></div>
                    <div class="ml-2 h-2.5 bg-gray-300 rounded-full grow"></div>
                </div>
            </td>
        </tr>
    </tbody>
    <tbody class="sm:hidden">
        <tr class="odd:bg-gray-100 even:bg-white md:h-12">
            <td class="border-b border-l p-2 text-center  animate-pulse">
                <div class="flex justify-center content-center">
                    <div class="h-2.5 bg-gray-300 rounded-full  w-24"></div>
                </div>
            </td>
            <td class="border-b border-l p-2 gap-2 text-center  animate-pulse">
                <div class="flex justify-center content-center">
                    <div class="h-2.5 bg-gray-300 rounded-full grow"></div>
                    <div class="ml-2 h-2.5 bg-gray-300 rounded-full grow"></div>
                    <div class="ml-2 h-2.5 bg-gray-300 rounded-full grow"></div>
                </div>
            </td>
        </tr>
        <tr class="odd:bg-gray-100 even:bg-white md:h-12">
            <td class="border-b border-l p-2 text-center  animate-pulse">
                <div class="flex justify-center content-center">
                    <div class="h-2.5 bg-gray-300 rounded-full  w-24"></div>
                </div>
            </td>
            <td class="border-b border-l p-2 gap-2 text-center  animate-pulse">
                <div class="flex justify-center content-center">
                    <div class="h-2.5 bg-gray-300 rounded-full grow"></div>
                    <div class="ml-2 h-2.5 bg-gray-300 rounded-full grow"></div>
                    <div class="ml-2 h-2.5 bg-gray-300 rounded-full grow"></div>
                </div>
            </td>
        </tr>
        <tr class="odd:bg-gray-100 even:bg-white md:h-12">
            <td class="border-b border-l p-2 text-center animate-pulse">
                <div class="flex justify-center content-center">
                    <div class="h-2.5 bg-gray-300 rounded-full  w-24"></div>
                </div>
            </td>
            <td class="border-b border-l p-2 gap-2 text-center animate-pulse">
                <div class="flex justify-center content-center">
                    <div class="h-2.5 bg-gray-300 rounded-full grow"></div>
                    <div class="ml-2 h-2.5 bg-gray-300 rounded-full grow"></div>
                    <div class="ml-2 h-2.5 bg-gray-300 rounded-full grow"></div>
                </div>
            </td>
        </tr>
    </tbody>
</template>
<script>
export default {
  name: 'SkeletonTable'
}
</script>
