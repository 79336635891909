<script setup>
import { ref, onMounted, defineEmits } from 'vue';
import { VueRecaptcha } from 'vue-recaptcha';

const emit = defineEmits(['update:recaptchaToken']);

// variables reactivas
const siteKey = ref(process.env.VUE_APP_RECAPTCHA);

const callback = (response) => {
    emit('update:recaptchaToken', response)
} 

onMounted(() => {
    let googleRecaptcha = document.createElement('script');
    googleRecaptcha.setAttribute('src', `https://www.google.com/recaptcha/enterprise.js?render=${process.env.VUE_APP_RECAPTCHA}`); //Test reCaptcha   
    document.head.appendChild(googleRecaptcha);

})

</script>

<template>
    <div>
        <VueRecaptcha 
            class="my-5" 
            :sitekey="siteKey" 
            @verify="callback">        
        </VueRecaptcha>
    </div>
</template>