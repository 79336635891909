<template>
  <div class="h-full">
    <header class="bg-[#0064ff] py-4">
      <div class="container mx-auto px-4">
        <img class="h-8 cursor-pointer" src="../assets/odihnx.png" @click="$router.push('/')" />
      </div>
    </header>
    <InputCode />
    <FooterComponent />
  </div>
  <footer class="bg-[#0064ff] py-4 text-center">
    <p class="text-white">©2024 OdihnX </p>
  </footer>
</template>

<script>
import InputCode from '@/components/InputCodeForm.vue'
export default {
  name: 'InsertCodeView',
  components: {

    InputCode
  }
}
</script>
