<template>
  <div class="relative overflow-auto p-8 h-full">
    <header class="bg-white shadow">
      <div class="mx-auto py-3 px-4 mb-3 sm:px-6 lg:px-8">
        <div class="flex justify-between">
          <h1 class="text-3xl font-bold tracking-tight text-gray-900">
            Historial
          </h1>
        </div>
      </div>
    </header>

    <UniversalTable :dataInfo="filteredInfo" :headers="headers" :pagination="pagination" @nextPage="increasePage" @previousPage="decreasePage" @sort="sort" @search="searchInfo" />
  </div>
</template>

<script>
import axios from 'axios'
import UniversalTable from './UniversalTable.vue'
export default {
  name: 'HistoryTable',
  components: {
    UniversalTable
  },
  data () {
    return {
      historyInfo: [],
      headers: [
        { name: 'ID Reserva', field: 'id', sortable: true },
        { name: 'Ubicacion', field: 'nombre_instalacion', sortable: true },
        { name: 'Creación', field: 'fecha', sortable: true },
        { name: 'Fecha Reserva', field: 'fecha_arriendo', sortable: true },
        { name: 'Resultado', field: 'resultado', sortable: false },
        { name: 'Comentario', field: 'comentario', sortable: false }
      ],
      pagination: {
        currentPage: 0,
        perPage: 10,
        totalPages: null,
        totalItems: null,
        currentField: null,
        sortingOrder: null
      },
      search: ''
    }
  },
  watch: {
    pagination: {
      handler () {
        this.loadData()
      },
      deep: true
    }
  },
  computed: {
    filteredInfo () {
      return this.historyInfo
        .map((item) => {
          return {
            id: item.id,
            nombre_instalacion: item.nombre_instalacion ? item.nombre_instalacion : 'No se consigna',
            fecha: this.formatDate(item.fecha),
            fecha_arriendo: this.formatDate(item.fecha_arriendo),
            resultado: item.estado[0]
              ? item.estado[0].nombre
              : 'No se consigna',
            comentario: item.estado[0]
              ? item.estado[0].descripcion
              : 'No se consigna'
          }
        })
        .filter((i) => {
          for (const j in i) {
            const field = i[j].toString()
            if (field.toLowerCase().includes(this.search.toLowerCase())) {
              return true
            }
          }
          return false
        })
    }
  },
  async created () {
    this.loadData()
    this.pagination.currentField = this.headers[0].field
    this.pagination.sortingOrder = 'desc'
  },
  methods: {
    async loadData () {
      const response = await axios
        .get(
          // `${process.env.VUE_APP_URL_CERRADURAS}/arriendo/mis-arriendos/all`,
          `${process.env.VUE_APP_URL_CERRADURAS}/historico`,
          {
            headers: {
              Authorization: 'Bearer ' + localStorage.getItem('token')
            },
            params: {
              page: this.pagination.currentPage,
              size: this.pagination.perPage,
              sort: this.pagination.currentField
                ? this.pagination.currentField +
                ',' +
                this.pagination.sortingOrder
                : null
            }
          }
        )
        .then((response) => {
          this.historyInfo = response.data.content
          this.pagination.totalPages = response.data.totalPages
          this.pagination.totalItems = response.data.totalElements
        })
      return response
    },
    formatDate (date) {
      const day = new Date(date).toLocaleDateString('es-ES', {
        year: 'numeric',
        month: 'long',
        day: 'numeric'
      })
      const hour = new Date(date).toLocaleTimeString('es-ES', {
        hour: 'numeric',
        minute: 'numeric'
      })
      return hour + 'hrs, ' + day
    },
    formatState (state) {
      const data = state[0]
      if (data) {
        if (data.id == 1) {
          return 'Terminado con exito'
        } else {
          return 'Finalizado'
        }
      } else {
        return 'Error'
      }
    },
    formatComment (comment) {
      const data = comment[0]
      if (data) {
        return data.descripcion
      } else {
        return 'Error'
      }
    },
    sort (field) {
      this.pagination.currentField = field
      if (this.pagination.sortingOrder == null) {
        this.pagination.sortingOrder = 'asc'
      } else {
        this.pagination.sortingOrder =
          this.pagination.sortingOrder == 'asc' ? 'desc' : 'asc'
      }
    },
    increasePage () {
      this.pagination.currentPage++
    },
    decreasePage () {
      this.pagination.currentPage--
    },
    searchInfo (text) {
      this.search = text
    }
  }
}
</script>
