<script setup>
import { defineEmits } from "vue";
const emit = defineEmits(['send-code']);

const sendCode = (e) => {   
    e.preventDefault(); 
    const { target } = e;
    const code = target.code_1.value + 
                target.code_2.value  +
                target.code_3.value  +
                target.code_4.value  +
                target.code_5.value  +
                target.code_6.value;

    emit('send-code', code);
}

const handlePaste = (e) => {
    const clipboardData = e.clipboardData || window.clipboardData;
    const pastedData = clipboardData.getData('text');
    
    if (pastedData.length === 6) {
        // Assuming pasted data is a 6-digit code
        Array.from(pastedData).forEach((digit, index) => {
            // Update each input field with the corresponding digit
            document.getElementById(`code_${index + 1}`).value = digit;
        });
    }
}
</script>

<template>
    <div class="p-4 max-sm:w-80 shadow-lg border rounded-md">
        <form @submit.prevent="sendCode">
            <h1 class="font-bold">Acabamos de enviar un código a tu email</h1>
            <h2 class="font-semibold">Por favor, digita tu código</h2>
            <div class="flex mt-4">
                <input
                    v-for="i in 6"
                    :key="i"
                    :id="`code_${i}`"
                    class="max-sm:w-8 max-sm:h-8 w-12 h-12 mx-2 border border-gray-500 text-center text-xl font-semibold"
                    type="text"
                    maxlength="1"
                    @paste="handlePaste"
                />
            </div>
            <div class="text-center">
                <button 
                    type="submit" 
                    :class="`w-full flex mt-8 justify-center py-2 px-4 border 
                            border-transparent rounded-md shadow-sm text-sm 
                            font-medium text-white bg-[#0064ff] hover:bg-blue-400
                            focus:outline-none focus:ring-2 focus:ring-offset-2 
                            focus:ring-blue-500`"
                >
                    ENVIAR!
                </button>
            </div>
        </form>
    </div>
</template>
