<template>
    <div class="h-full flex flex-col justify-center gap-1 basis-1/2">
        <div class="flex flex-row mx-5 justify-center">
            <div class="flex flex-col gap-1 basis-1/2 mt-5 bg-white border-2 shadow rounded-2xl py-4 px-8 text-center">
                <h1 class="text-3xl font-bold text-gray-900">
                    Editar perfil
                </h1>
            </div>
        </div>
        <div class="flex flex-row mx-5 justify-center">
            <span class="text-red-600"> {{ msg }} </span>
            <form class="grid grid-cols-2 gap-3 basis-1/2 mt-5 mb-20 bg-white border-2 shadow rounded-2xl py-4 px-8"
                @submit.prevent="submitForm">
                <div>
                    <span> Correo: </span>
                    <input disabled v-model="formData.correo"
                        class="w-full border px-4 py-2 rounded-md bg-gray-200 text-gray-500 focus:border-blue-500 focus:shadow-outline outline-none"
                        type="text" placeholder="ejemplo@123.cl" />
                </div>
                <div>
                    <span> Usuario: </span>
                    <input disabled v-model="formData.usuario"
                        class="w-full border px-4 py-2 rounded-md bg-gray-200 text-gray-500 focus:border-blue-500 focus:shadow-outline outline-none"
                        type="text" placeholder="Usuario para el registro" />
                </div>
                <div>
                    <span> Nombre: </span>
                    <input v-model="formData.nombre"
                        class="w-full border px-4 py-2 rounded-md focus:border-blue-500 focus:shadow-outline outline-none"
                        type="text" autocomplete="off" placeholder="Nombre completo" />
                </div>
                <div>
                    <span> Direccion: </span>
                    <input v-model="formData.casa_depto"
                        class="w-full border px-4 py-2 rounded-md focus:border-blue-500 focus:shadow-outline outline-none"
                        type="text" placeholder="Direccion" />
                </div>
                <div>
                    <span> Telefono: </span>
                    <input v-model="formData.telefono"
                        class="w-full border px-4 py-2 rounded-md focus:border-blue-500 focus:shadow-outline outline-none"
                        type="text" placeholder="Numero de Telefono" />
                </div>
                <div>
                    <span> Ubicacion mas cercana: </span>
                    <Listbox v-model="selectedInstallation">
                        <div class="relative">
                            <ListboxButton
                                class="relative border w-full cursor-default rounded-lg bg-white py-2 pl-3 pr-10 text-left focus:outline-none focus-visible:border-indigo-500 focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 focus-visible:ring-offset-blue-300 sm:text-sm">
                                <span class="block truncate">{{ selectedInstallation ?
                                    selectedInstallation.nombre_instalacion :
                                    "Selecciona tu ubicación" }}</span>
                                <span class="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                                    <ChevronUpDownIcon class="h-5 w-5 text-gray-400" aria-hidden="true" />
                                </span>
                            </ListboxButton>
                            <transition leave-active-class="transition duration-100 ease-in" leave-from-class="opacity-100"
                                leave-to-class="opacity-0">
                                <ListboxOptions
                                    class="absolute mt-1 max-h-60 w-full z-20 overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                                    <ListboxOption v-slot="{ active, selected }" v-for="installation in installations"
                                        :key="installation" :value="installation" as="template">
                                        <li :class="[
                                            active ? 'bg-blue-100 text-blue-900' : 'text-gray-900',
                                            'relative cursor-default select-none py-2 pl-10 pr-4',
                                        ]">
                                            <span :class="[
                                                selected ? 'font-medium' : 'font-normal',
                                                'block truncate',
                                            ]">{{ installation.nombre_instalacion }}</span>
                                            <span v-if="selected"
                                                class="absolute inset-y-0 left-0 flex items-center pl-3 text-blue-600">
                                                <CheckIcon class="h-5 w-5" aria-hidden="true" />
                                            </span>
                                        </li>
                                    </ListboxOption>
                                </ListboxOptions>
                            </transition>
                        </div>
                    </Listbox>
                </div>
                <button
                    class="bg-blue-500 p-4 m-4 flex justify-center items-center font-bold text-white grow rounded-md hover:bg-blue-900 border-b-4"
                    type="submit">
                    <div v-if="isLoading" class="animate-spin rounded-md h-5 w-5 border-t-2 border-b-2 border-white-500">
                    </div>
                    <div v-else>Guardar</div>
                </button>
                <button
                    class="bg-blue-500 p-4 m-4 self-end font-bold text-white grow rounded-md hover:bg-blue-900 border-b-4"
                    @click="$router.go(-1)">
                    Volver
                </button>
            </form>
        </div>
    </div>
</template>

<script>
import axios from 'axios'
import schemaFilter from '@/utils/schemaFilter'
import { Listbox, ListboxButton, ListboxOptions, ListboxOption } from '@headlessui/vue'
import { CheckIcon, ChevronUpDownIcon } from '@heroicons/vue/20/solid'

export default {
  name: 'CreateUserForm',
  components: {
    Listbox,
    ListboxButton,
    ListboxOptions,
    ListboxOption,
    CheckIcon,
    ChevronUpDownIcon
  },
  props: {},
  data () {
    return {
      formData: {
        nombre: '',
        correo: '',
        usuario: '',
        casa_depto: '',
        telefono: '',
        instalacion: ''
      },
      msg: '',
      isLoading: false,
      selectedInstallation: null
    }
  },
  async created () {
    this.fetchUserInfo()
    this.fetchInstallations()
  },
  methods: {
    async fetchUserInfo () {
      const params = {
        emailOrUser: this.$route.params.user
      }
      const headers = {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + localStorage.getItem('token')
      }
      const response = await axios
        .get(process.env.VUE_APP_URL_BACKEND + '/auth/usuario/info', {
          params: params,
          headers: headers
        })
        .then((response) => {
          if (response.status === 200) {
            this.formData = schemaFilter(response.data, [
              'nombre',
              'correo',
              'usuario',
              'casa_depto',
              'telefono',
              'instalacion'
            ])
          }
        })
        .catch((error) => {
          console.log(error)
        })
      return response
    },
    async fetchInstallations () {
      const response = await axios
        .get(
          process.env.VUE_APP_URL_BACKEND + '/instalaciones/listarInstalaciones'
        )
        .then((response) => {
          if (response.status == 200) {
            // filtro solo instalaciones pagadas
            this.installations = response.data.filter(item => item?.pagado);
          }
        })
        .catch((error) => {
          this.errorMsg =
                        'Error al obtener instalaciones' +
                        (error.message
                          ? error.message
                          : 'No se logró indentificar el error')
          window.scrollTo(0, 0) // Scrolls to the top when error msg pops
        })
        
      return response
    },
    async submitForm () {
      this.isLoading = true
      const response = await axios.put(
        process.env.VUE_APP_URL_BACKEND + '/auth/actualizarme',
        this.formData,
        {
          headers: {
            Authorization: 'Bearer ' + localStorage.getItem('token')
          }
        }).then((response) => {
        if (response.status == 200) {
          this.$router.push({ name: 'ProfileComponentView' })
        }
        this.isLoading = false
      }).catch((error) => {
        this.errorMsg =
                        'Error al obtener instalaciones' +
                        (error.message
                          ? error.message
                          : 'No se logró indentificar el error')
        window.scrollTo(0, 0) // Scrolls to the top when error msg pops
      }
      )
      return response
    },
    validatesForm () {
      const regexEmail =
                /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/
            const regexPhone = /^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s\./0-9]*$/; //eslint-disable-line
      if (!regexEmail.test(this.formData.mail)) {
        this.msg = 'El correo no es valido'
        return true
      }
      if (this.formData.username.length < 6) {
        this.msg = 'El usuario debe tener al menos 6 caracteres'
        return true
      }
      if (this.formData.password.length < 6) {
        this.msg = 'La contraseña debe tener al menos 6 caracteres'
        return true
      }
      if (this.formData.name === '') {
        this.msg = 'El nombre no puede estar vacio'
        return true
      }
      if (!regexPhone.test(this.formData.phone)) {
        this.msg = 'El numero de telefono no es valido'
        return true
      }
      return false
    }
  },
  watch: {
    selectedInstallation: function (val) {
      this.formData.instalacion = val.id
    }
  }
}
</script>
