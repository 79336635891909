<template>
  <div class="min-h-screen flex flex-col justify-between overflow-auto">
    <MenuNavbar />
    <div v-if="$route.name !== 'HomeMenuView'"
      class="flex flex-row items-center m-4 border-slate-300 border-2 rounded-full w-fit pr-4 cursor-pointer bg-white hover:bg-gray-200 transition-all"
      @click="$router.go(-1)">
      <img src="../assets/backButton.png" class="h-8 w-8" />
      <p class="font-bold font-sans text-xl mb-1 select-none">Volver</p>
    </div>

    <router-view class="h-full grow justify-self-start"></router-view>
    <FooterComponent />
  </div>
</template>

<script>
import MenuNavbar from '@/components/ui/MenuNavbar.vue'
import FooterComponent from '@/components/ui/FooterComponent.vue'

export default {
  name: 'MobileLayoutView',
  components: { MenuNavbar, FooterComponent }
}
</script>
