<template>
  <div class="flex justify-center items-center">
    <div
      class="animate-spin rounded-full h-5 w-5 border-t-2 border-b-2 border-white-500"
    ></div>
  </div>
</template>

<script>
export default {
  name: 'LoadingComponent'
}
</script>
