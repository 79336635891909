<script setup>
//import { ref, computed, onMounted } from 'vue';
import { ref, onMounted } from 'vue';
import { useRouter } from 'vue-router';
import axios from 'axios';
import BlueButton from '@/components/ui/BlueButton.vue';
import EditablePagination from '@/components/ui/EditablePagination.vue'; 

const router = useRouter();

onMounted( async () => {
    await getUserInfo();
});

//const userInfo = ref([]);
const itemsPerPage = ref(20);
const currentPage = ref(0);
const paginatedData = ref([]);
const totalPages = ref(0);
//const pageToShow = computed(() => currentPage.value + 1);

const headers = ref([
  { name: "Usuario", field: "usuario" },
  { name: "Correo", field: "correo" },
  { name: "Nombre", field: "nombre" },
  { name: "Acciones", field: "acciones" },
]);

const jumpPage = async (n) => {
    currentPage.value = n - 1; //se le resta para estar alineado con el request del servidor
    await getUserInfo();
}

const getUserInfo = async () => {
    const headers = {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('token')}`
    }

    const response = await axios
    .get(`${process.env.VUE_APP_URL_BACKEND}/auth/getUsuarios`, {
        headers: headers,
        params: {
            page: currentPage.value,
            size: itemsPerPage.value,
            sort: "id,desc"
        }
    });

    const data = response.data;
    totalPages.value = data.totalPages;
    paginatedData.value = data.content;
    return response;
}

const deleteUser = async (username) => {
    const response = await axios
        .delete(`${process.env.VUE_APP_URL_BACKEND}/auth/eliminar`, {
            headers: {
                Authorization: 'Bearer ' + localStorage.getItem('token')
            },
            params: {
                emailOrUser: username
            }
        })
        .then((response) => {
            if (response.status == 200) {
                getUserInfo();
            }
        })
        .catch((error) => {
            console.log(error);
        })
    return response
}

const createUser = () => {
    router.push({ name: 'CreateUsersView' });
}

const editUser = (username) => {
    router.push({ name: 'EditUsersView', params: { user: username } });
}


</script>

<template>
    <div class="relative overflow-auto p-8 h-full">

        <header class="bg-white shadow">
            <div class="mx-auto my-5 p-5 sm:px-6 lg:px-8">
                <div class="flex justify-between">
                    <h1 class="text-3xl font-bold tracking-tight text-gray-900">
                        Usuarios
                    </h1>
                    <BlueButton :text="'Crear Usuario'" :onClickFunction="createUser" />
                </div>
            </div>
        </header>

        <table class="w-full table-fixed border-x border-b">
            <!-- Encabezado de la tabla -->
            <thead>
                <tr>
                    <th v-for="(col, index) in headers" :key="index" class="border-b border-l border-blue-500 bg-blue-500 p-2 text-center font-bold text-white">
                        {{ col.name }}
                    </th>
                </tr>
            </thead>

            <!-- Datos paginados -->
            <tbody>
                <tr v-bind:key="index" :value="row.username" v-for="(row, index) in paginatedData"
                    class="odd:bg-gray-100 even:bg-white hover:!bg-stone-200 cursor-pointer">
                    <td class="border-b border-l p-2 text-left truncate " @click="
                        $router.push({
                            name: 'ProfileComponentView',
                            params: { user: row.usuario },
                        })
                        ">
                        {{ row.usuario }}
                    </td>
                    <td class="border-b border-l p-2 text-left truncate " @click="
                        $router.push({
                            name: 'ProfileComponentView',
                            params: { user: row.usuario },
                        })
                        ">
                        {{ row.correo }}
                    </td>
                    <td class="border-b border-l p-2 text-left truncate " @click="
                        $router.push({
                            name: 'ProfileComponentView',
                            params: { user: row.usuario },
                        })
                        ">
                        {{ row.nombre }}
                    </td>
                    <td class="border-b border-l p-2 flex justify-around">
                        <svg class="max-w-10 max-h-10" viewBox="0 0 20 20" @click="deleteUser(row.usuario)">
                            <path
                                d="M17.114,3.923h-4.589V2.427c0-0.252-0.207-0.459-0.46-0.459H7.935c-0.252,0-0.459,0.207-0.459,0.459v1.496h-4.59c-0.252,0-0.459,0.205-0.459,0.459c0,0.252,0.207,0.459,0.459,0.459h1.51v12.732c0,0.252,0.207,0.459,0.459,0.459h10.29c0.254,0,0.459-0.207,0.459-0.459V4.841h1.511c0.252,0,0.459-0.207,0.459-0.459C17.573,4.127,17.366,3.923,17.114,3.923M8.394,2.886h3.214v0.918H8.394V2.886z M14.686,17.114H5.314V4.841h9.372V17.114z M12.525,7.306v7.344c0,0.252-0.207,0.459-0.46,0.459s-0.458-0.207-0.458-0.459V7.306c0-0.254,0.205-0.459,0.458-0.459S12.525,7.051,12.525,7.306M8.394,7.306v7.344c0,0.252-0.207,0.459-0.459,0.459s-0.459-0.207-0.459-0.459V7.306c0-0.254,0.207-0.459,0.459-0.459S8.394,7.051,8.394,7.306"
                                fill="red"></path>
                        </svg>
                        <svg class="max-w-9 max-h-9 mt-1" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"
                            @click="editUser(row.usuario)">
                            <path
                                d="M20 12V5.74853C20 5.5894 19.9368 5.43679 19.8243 5.32426L16.6757 2.17574C16.5632 2.06321 16.4106 2 16.2515 2H4.6C4.26863 2 4 2.26863 4 2.6V21.4C4 21.7314 4.26863 22 4.6 22H11"
                                stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" />
                            <path d="M8 10H16M8 6H12M8 14H11" stroke="currentColor" stroke-linecap="round"
                                stroke-linejoin="round" />
                            <path
                                d="M16 5.4V2.35355C16 2.15829 16.1583 2 16.3536 2C16.4473 2 16.5372 2.03725 16.6036 2.10355L19.8964 5.39645C19.9628 5.46275 20 5.55268 20 5.64645C20 5.84171 19.8417 6 19.6464 6H16.6C16.2686 6 16 5.73137 16 5.4Z"
                                fill="currentColor" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" />
                            <path
                                d="M17.9541 16.9394L18.9541 15.9394C19.392 15.5015 20.102 15.5015 20.5399 15.9394V15.9394C20.9778 16.3773 20.9778 17.0873 20.5399 17.5252L19.5399 18.5252M17.9541 16.9394L14.963 19.9305C14.8131 20.0804 14.7147 20.2741 14.6821 20.4835L14.4394 22.0399L15.9957 21.7973C16.2052 21.7646 16.3988 21.6662 16.5487 21.5163L19.5399 18.5252M17.9541 16.9394L19.5399 18.5252"
                                stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" />
                        </svg>
                    </td>
                </tr>
            </tbody>

        </table>

         <!-- Paginación -->
        <EditablePagination
            @jump-page="jumpPage"
            :currentPage="currentPage"
            :totalPages="totalPages"
        />

    </div>
</template>