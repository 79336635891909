<script setup>
import { ref, toRaw } from 'vue';
import axios from 'axios';
import * as XLSX from 'xlsx';

const excelData = ref([]);
const textArea = ref('');
const reqData = ref({});
const showMsg = ref('');
const showErrorMsg = ref('');
const showInputErrorMsg = ref('');

const handleFileUpload = (event) => {
    const file = event.target.files[0];
    if (file) {
        readExcel(file);
        
    }
};

const readExcel = (file) => {
    const reader = new FileReader();
    reader.onload = (e) => {
        const arrayBuffer = new Uint8Array(e.target.result);
        const workbook = XLSX.read(arrayBuffer, { type: 'array' });
        //const sheetName = workbook.SheetNames[0];
        const sheetName = 'bd_wsp';
        const sheet = workbook.Sheets[sheetName];
        const parsedData = XLSX.utils.sheet_to_json(sheet, { header: 1 });
        excelData.value = parsedData.slice(1); // Excluir fila de header
        excelData.value = excelData.value.map(item => {
            return item = {
                "nombre": item[0],
                "nro_depto": item[1],
                "correo": item[2],
                "telefono": item[3]
            }
        }).filter(item => item.telefono !== "" && /569( |-)?[0-9]{8}/gm.test(item.telefono));
        if (excelData.value.length > 0){
            reqData.value["departamentos"] = excelData.value;
        } else {
            showErrorMsg.value = 'Planilla con datos invalidos o vacia';
            setTimeout(()=> {
                showErrorMsg.value = '';
            }, 3000);
        }
    };
    reader.readAsArrayBuffer(file);
};

const sendReq = async () => {
    if(textArea.value !== ""){
        const url = `${process.env.VUE_APP_URL_BACKEND}/departamentos/wspBatch`;
    
        const requestData = {
                "departamentos": toRaw(reqData.value["departamentos"]),
                "message": toRaw(textArea.value)
            };
        await axios.post(url, 
            requestData, {
            headers: {
                Authorization: 'Bearer ' + localStorage.getItem('token')
            }
        })
        .then(() => {
            showMsg.value = 'Mensaje enviado con exito!';
            setTimeout(()=> {
                showMsg.value = '';
            }, 3000);
        })
        .catch(ex => {
            console.log(`error guardar excel: ${ex}`);
        });
    
        textArea.value = '';
        excelData.value = [];
        reqData.value = {};
    } else{
        showInputErrorMsg.value = 'No escribio mensaje a enviar';
        setTimeout(()=> {
            showInputErrorMsg.value = '';
        }, 3000);
    }
}


</script>

<template>
    <div class="flex flex-col px-4 container mx-auto my-8 justify-center items-center">
           
            <div  class="flex flex-col space-y-4 justify-center items-center">

                <!-- Input archivo excel -->
                <div v-if="excelData.length == 0  && !showMsg && !showErrorMsg" class="mb-8">
                    
                    <input 
                        type="file" 
                        @change="handleFileUpload" 
                        class="hidden" 
                        accept=".xls, .xlsx" 
                        id="fileInput" 
                    />
                    <label 
                        for="fileInput" 
                        class="bg-blue-500 text-white px-4 py-2 rounded-md cursor-pointer hover:bg-blue-700"
                    >
                        Seleccionar Archivo 
                    </label>

                    <h3 class="text-xs mt-2">*Números deben tener la siguiente estructura:  <strong>ej: 56911111111</strong></h3>
                    <h3 class="text-xs mt-2">*Hoja de planilla debe llamarse: <strong>bd_wsp</strong></h3>
                </div>

                <div v-if="excelData.length > 0" class="mb-8">
                    <h2 
                        class="text-2xl font-bold mb-4"
                    >
                        Mensaje a enviar
                    </h2>

                    <textarea 
                        class="bg-blue-200 text-black p-4 border border-blue-400 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500 w-full resize"
                        v-model="textArea"
                        id="textInput"
                        rows="5"
                        maxlength="1150"
                    ></textarea>

                </div>
    
                <!-- Tabla con datos de excel -->
                <div 
                    v-if="excelData.length > 0"
                >
                    <h2 
                        class="text-2xl font-bold mb-4"
                    >
                        Contenido del Excel:
                    </h2>
                    <table 
                        class="table-auto border-separate "
                    >
                        <thead>
                            <tr class="border bg-blue-500 font-bold text-white truncate px-4 py-2">
                                <th>Nombre</th>
                                <th>N° Dep</th>
                                <th>Correo</th>
                                <th>Número</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(row, index) in excelData" :key="index">
                                <td class="border px-4 py-2" v-for="(cell, cellIndex) in row" :key="cellIndex">
                                    {{ cell }}
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>

            <!-- Boton para enviar datos -->
            <div v-if="Object.keys(reqData).length > 0 && !showMsg" class="mt-12">
                <button v-if="!showInputErrorMsg" @click="sendReq" class="bg-blue-500 text-white px-4 py-2 rounded-md cursor-pointer hover:bg-blue-700">
                    Enviar mensaje
                </button>
                <div v-else>
                    <span class="text-xl font-bold">{{ showInputErrorMsg }}</span>
                </div>
            </div>

            <div v-if="showMsg">
                <span class="text-xl font-bold">{{ showMsg }}</span>
            </div>
            <!-- msj input invalido -->
            <div v-if="showErrorMsg">
                <span class="text-xl font-bold">{{ showErrorMsg }}</span>
            </div>

    </div>
</template>  