<script setup>
import { ref, onMounted, watch } from 'vue';
import axios from 'axios';

const instalations = ref([]);
const instalationId = ref('');
const arrUsers = ref([]);

const getInstalations = async () => {
    const url = `${process.env.VUE_APP_URL_BACKEND}/instalaciones/getInstalaciones`;
    const res = await axios.get(url, {
        headers: {
            Authorization: 'Bearer ' + localStorage.getItem('token')
        }
    });

    const instalationsData = res?.data;
    instalations.value = Object.keys(instalationsData).map(key => {
        return {
            id: instalationsData[key].id,
            nombre_instalacion: instalationsData[key].nombre_instalacion
        }
    });
}

const getUsersByInstalation = async () => {
    try {
        const url = `${process.env.VUE_APP_URL_BACKEND}/auth/usuarios/instalacion/${instalationId.value}/emails`;
        const res = await axios.get(url, {
            headers: {
                Authorization: 'Bearer ' + localStorage.getItem('token')
            }
        }).catch(error => {
            console.log(error)
        })

        const users = res?.data

        arrUsers.value = users;

        //this.generateExcel();

    } catch (ex) {
        console.log(`Error getUsersByInstalation(): ${ex}`);
    }
}

onMounted(async () => {
    await getInstalations();
});

watch(instalationId, async ()=>{
    await getUsersByInstalation();
})

</script>

<template>
    <div class="mb-4 pl-5">
        <label class="font-bold" for="instalationId">Instalaciones: </label>
        <select v-model="instalationId" id="instalations" name="instalations" class="border rounded px-2 py-1">
            <option value="select" disabled selected>-- Seleccionar --</option>
            <option 
                v-for="instalation in instalations" 
                :key="instalation.id" 
                :value="instalation.id"
                class="text-gray-500"
            >
                {{ instalation.nombre_instalacion }}
            </option>
        </select>

        <div 
            v-if="instalationId"
            class="mt-5 mb-5"
        >
            <p 
                v-for="(users, index) in arrUsers" 
                :key="index"
                class="font-semibold"
            >
                {{ users }}
            </p>
        </div>
    </div>
</template>