<script setup>
import axios from 'axios';
import { ref, onMounted, computed, watch } from 'vue';
import EditablePagination from './ui/EditablePagination.vue';

const showDropDown = ref(false);
const dropDownData = ref(''); // lista en dropdown
const suppData = ref([]); // variable de soporte para persistir datos de dropdown
const currentColumns = ref("");
const currentHeaderWidth = ref("");

//variables de paginado
const itemsPerPage = ref(100); // numero de elemntos maximos por pagina
const currentPage = ref(0); // pagina acual mostrada
const totalPages = ref(0); // numero total de paginas en la bbdd
const devices = ref([]); // objeto con elementos de pagina actual

const suppFilter = (data) =>{
    suppData.value = data;
}

const devicesId = computed(() => {
    if(!dropDownData.value){
        let arrDevicecsId = devices.value.map(state => {
            return state?.id_device
        });
        const result = arrDevicecsId.reduce((acc,item)=>{
            if(!acc.includes(item)){
            acc.push(item);
            }
            return acc;
        },[])
        suppFilter(result);
        return result;
    } else {
        return suppData.value;
    }
    

});

const changeDropDownState = (name) => {
    currentColumns.value = name;
    showDropDown.value = !showDropDown.value;

}

const getDropdownData = (id) =>{
    dropDownData.value = id;
    showDropDown.value = false;
}

const headers = ref([
    { name: 'Fecha', field: 'fecha_dispositivo', sortable: false },
    { name: 'Estados', field: 'locker_estados', sortable: false },
    { name: 'Id', field: 'id_device', sortable: true },
    { name: 'Temp C°', field: 'temperatura', sortable: false }
]);

const filteredInfo = (id) => {
  
    const values = devices.value.filter(item => {
        return item?.id_device === id;
    })

    devices.value = values;
};

const jumpPage = async (n) => {
    currentPage.value = n - 1; //se le resta para estar alineado con el request del servidor
    await getDevicesData();
}

const getDevicesData = async() => {
    // const url = `${process.env.VUE_APP_URL_CERRADURAS}/historico/estados`;
    const url = `${process.env.VUE_APP_URL_CERRADURAS}/historico`;
    const response = await axios.get(url, {
        headers: {
              Authorization: 'Bearer ' + localStorage.getItem('token')
            },
        params: {
            page: currentPage.value,
            size: itemsPerPage.value,
            sort: "id,desc"
        }
    });

    const data = response.data;

    totalPages.value = data.totalPages; // para paginado
    devices.value = data.content; // para paginado
    showDropDown.value = false;
}

const formatDate = (date) =>{
    // Crear un objeto Date a partir de la cadena ISO
    const fechaOriginal = new Date(date);

    // Restar 3 horas
    fechaOriginal.setHours(fechaOriginal.getHours());

    // Obtener partes de la fecha y hora
    const año = fechaOriginal.getFullYear();
    const mes = String(fechaOriginal.getMonth() + 1).padStart(2, "0");
    const dia = String(fechaOriginal.getDate()).padStart(2, "0");
    const hora = String(fechaOriginal.getHours()).padStart(2, "0");
    const minutos = String(fechaOriginal.getMinutes()).padStart(2, "0");
    const segundos = String(fechaOriginal.getSeconds()).padStart(2, "0");

    // Formatear la fecha y hora
    return `${año}/${mes}/${dia} ${hora}:${minutos}:${segundos}`;
}

const captureColumnInfo = (columnName, width) => {

    const roundedWidth = Math.ceil(width / 10) * 10; // Redondear al siguiente número múltiplo de 10
    currentHeaderWidth.value =  roundedWidth;

}

watch(dropDownData, ()=> {
    filteredInfo(dropDownData.value)
});

onMounted(async() =>{
    await getDevicesData();

});

</script>

<template>
    <div class="xs:px-2 px-20 mb-10">
        <h1 class="w-full text-center font-bold mb-5">Datos estado raspberrys</h1>
        <div>       
            <table class="w-full table-auto xs:table-fixed border-x border-b">
                <thead>
                    <th v-for="(column, index) in headers" 
                            :key="index"
                            class="border-b border-l border-blue-500 bg-blue-500 p-2 text-left font-bold text-white truncate">                        
                        <div class="flex flex-col justify-center items-center">
                            <div class="flex flex-row">

                                <p> {{ column.name }} </p>                 
                                <a v-if="column.sortable"
                                    class="cursor-pointer"
                                >
                                    <button @click="changeDropDownState(column.name); captureColumnInfo(column.name, $event.target.closest('th').clientWidth)">
                                        <svg xmlns="http://www.w3.org/2000/svg" class="w-3 h-3 ml-1"
                                            aria-hidden="true" fill="currentColor" viewBox="0 0 320 512">
                                            <path
                                                d="M27.66 224h264.7c24.6 0 36.89-29.78 19.54-47.12l-132.3-136.8c-5.406-5.406-12.47-8.107-19.53-8.107c-7.055 0-14.09 2.701-19.45 8.107L8.119 176.9C-9.229 194.2 3.055 224 27.66 224zM292.3 288H27.66c-24.6 0-36.89 29.77-19.54 47.12l132.5 136.8C145.9 477.3 152.1 480 160 480c7.053 0 14.12-2.703 19.53-8.109l132.3-136.8C329.2 317.8 316.9 288 292.3 288z" />
                                        </svg>
                                    </button>                                                         
                                </a>   

                            </div>
                            
                        </div>
                         
                    </th>
                </thead>
                <thead v-if="showDropDown">
                    <th v-for="(column, index) in headers" 
                            :key="index"                            
                            class="bg-white text-left font-bold text-white truncate w-auto">                        
                        <div class="flex flex-col justify-center items-center">
                            
                            <!-- Dropdown menu -->
                            <div v-if="column.sortable" 
                                id="dropdown" 
                                class="z-10 bg-white divide-y divide-blue-100 rounded-lg shadow w-full dark:bg-blue-500">
                                
                                <ul v-if="showDropDown && currentColumns == column?.name"
                                    :class="{ 'absolute': true, 'bg-blue-400': true, 'rounded-b-lg': true, 'py-2': true, 'text-sm': true, 'text-blue-400': true, 'dark:text-blue-200': true }"
                                    :style="{ width: 'calc(' + currentHeaderWidth + 'px)' }"
                                    aria-labelledby="dropdownDefaultButton">

                                        <li 

                                            v-for="(id, index) in devicesId"
                                            :key="index"
                                            
                                        >
                                            <button @click="getDropdownData(id)"
                                                class="w-full block px-4 py-2 hover:bg-blue-100 dark:hover:bg-blue-300 dark:hover:text-white"
                                            >
                                                {{ id }}
                                            </button>
                                        </li>

                                    <div class="py-2">
                                        <button 
                                            class="w-full block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 dark:hover:bg-gray-600 dark:text-gray-200 dark:hover:text-white"
                                            @click="getDevicesData"
                                        >
                                            Limpiar filtro
                                        </button>
                                    </div>
                                </ul>
                                
                            </div> 
                        </div>                        
                         
                    </th>
                </thead>


                <tbody>
                    <tr v-bind:key="index" :value="row.id" v-for="(row, index) in devices"
                        class="odd:bg-gray-100 even:bg-white hover:!bg-stone-200 cursor-pointer xs:text-xs">

                        <td class="border-b border-l p-2 text-left truncate ">
                            {{ formatDate(row.fecha_dispositivo) }}
                        </td>
                        <td class="border-b border-l p-2 text-left truncate ">
                            {{ row.locker_estados }}
                        </td>
                        <td class="border-b border-l p-2 text-left truncate ">
                            {{ row.id_device }}
                        </td>
                        <td class="border-b border-l p-2 text-left truncate ">
                            {{ row.temperatura }}
                        </td>
                    </tr>
                </tbody>
            </table>   

            <!-- Paginación -->
            <EditablePagination
                @jump-page="jumpPage"
                :currentPage="currentPage"
                :totalPages="totalPages"            
            />

        </div>
    </div>
</template>