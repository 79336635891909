<template>
  <Transition name="fade">
    <div
      class="m-4 sm:m-20 grid grid-cols-2 md:grid-cols-3 gap-4 sm:gap-y-6 content-center object-center place-content-stretch justify-items-center">
      <div v-for="item in items" :key="item.id" class="relative">
        <button @contextmenu="showMenu($event, item)" @click="$router.push({ path: `/menu/${item.href}` })"
          class="xs:w-44 xs:h-46 transition-all bg-white flex flex-col shadow-xl p-2 sm:p-4 border-gray-700 rounded-lg 
                border-4 cursor-pointer hover:bg-gray-200 focus:border-blue-700 focus:border-6 hover:shadow-2xl
                ">
          <img :src="item.img" class="rounded-md sm:w-52 sm:h-52" />
          <h3 class="text-xl mt-4 text-gray-700 text-center font-bold break-words select-none w-full">
            {{ item.name }}
          </h3>
        </button>

        <div v-if="showContextMenu && this.currentComponent == item.id" :style="menuPosition" 
            class="absolute bg-[#0064ff] w-max p-1 border rounded-md text-white">
          <!-- Contenido del menú aquí -->
          <ul>
            <li>
              <a 
                :href="`/menu/${item.href}`" target="_blank"
                class="cursor-pointer select-none"
              >
                Abrir en otra pestaña
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </Transition>
</template>

<script>
export default {
  name: 'MobileLayout',
  props: {},
  created () {
    if (localStorage.getItem('role') == 1) {
      this.items.push(
        {
          id: 1,
          name: 'Reservar locker',
          href: 'schedule',
          img: require('@/assets/reservar-2.svg')
        },
        {
          id: 2,
          name: 'Mis reservas',
          href: 'active',
          img: require('@/assets/mis-reservas-2.svg')
        },
        {
          id: 3,
          name: 'Historial',
          href: 'history',
          img: require('@/assets/historial-2.svg')
        },        
        {
          id: 4,
          name: 'Entidades',
          href: 'entities',
          img: require('@/assets/configuration.svg')
        },
        {
          id: 5,
          name: 'Reservar rapido',
          href: 'rent',
          img: require('@/assets/entities.svg')
        },
        {
          id: 6,
          name: 'P. de control',
          href: 'admin',
          img: require('@/assets/boss.svg')
        }
      )
    }
  },
  data () {
    return {
      currentComponent: '',
      showContextMenu: false,
      menuPosition: { top: '0px', left: '0px' },
      items: [
        {
          id: 7,
          name: 'Reservas instalación',
          href: 'reservations',
          img: require('@/assets/book.png')
        }
      ]
    }
  },
  methods: {
    hideMenu() {
      this.showContextMenu = false;
      document.removeEventListener('click', this.hideMenu);
    },
    showMenu(e, item) {
      e.preventDefault();
      this.currentComponent = item.id;
      if (this.showContextMenu) {
        this.hideMenu();
      } else {
        this.showContextMenu = true;
        const containerRect = e.currentTarget.getBoundingClientRect();
        this.menuPosition = {
          top: `${e.clientY - containerRect.top}px`,
          left: `${e.clientX - containerRect.left}px`,
        };
        document.addEventListener('click', this.hideMenu);
      }
    }
  }
};

</script>
