<template>
  <div class="overflow-auto p-8">
    <header class="bg-white border">
      <div class="mx-auto py-3 px-4 sm:px-6 lg:px-8">
        <div class="flex justify-between">
          <h1 class="text-3xl mr-4 font-bold tracking-tight text-gray-900 ">
            Mis reservas activas
          </h1>
          <EditableButton 
            text="Nueva Reserva" 
            textColor="black"
            bgColor="yellow"
            @click="createReservation"/>
        </div>
      </div>
    </header>
    <table class="w-full pt-5 table-auto drop-shadow-2xl xs:table-fixed">
      <thead>
        <tr>
          <th class="xs:hidden border-b border-l border-blue-500 bg-blue-500 p-2 text-center font-bold text-white"> 
            N° Reserva
          </th>
          <th class="xs:hidden border-b border-l border-blue-500 bg-blue-500 p-2 text-center font-bold text-white"> 
            Ubicación
          </th>
          <th class="xs:hidden border-b border-l border-blue-500 bg-blue-500 p-2 text-center font-bold text-white"> 
            N° Locker
          </th>
          <th class="xs:hidden border-b border-l border-blue-500 bg-blue-500 p-2 text-center font-bold text-white"> 
            Tiempo restante
          </th>
          <th class="xs:hidden border-b border-l border-blue-500 bg-blue-500 p-2 text-center font-bold text-white">
            Acciones
          </th>

          <th class="sm:hidden border-b border-l border-blue-500 bg-blue-500 p-2 text-center font-bold text-white">
            Informacion 
          </th>
          <th class="sm:hidden border-b border-l border-blue-500 bg-blue-500 p-2 text-center font-bold text-white">
            Acciones 
          </th>
        </tr>
      </thead>
      <tbody v-if="!loading">
        <tr v-bind:key="index" :value="row.id" v-for="(row, index) in activeReservations"
          class="odd:bg-gray-100 even:bg-white hover:!bg-stone-200 cursor-pointer ">
          <td class="border-b border-l p-2 text-center xs:hidden">
            {{ String(row.id).padStart(8, '0') }}
          </td>
          <td class="border-b border-l text-center p-2 xs:hidden">
            {{ row.nombre_instalacion }}
          </td>
          <td class="border-b border-l p-2 text-center xs:hidden">
            {{ String(row.nro_locker).padStart(2, '0') }}
          </td>
          <td class="xs:hidden border-b border-l p-2 text-center">
            <TimerCountDown :duration="row.fecha_arriendo" />
          </td>
          <td class="xs:hidden border-b border-l p-2 flex justify-evenly">
            <!-- <svg fill="#000000" class="max-w-10 max-h-10" @click="copyLinkModal(row)" viewBox="0 0 32 32" version="1.1"
              xmlns="http://www.w3.org/2000/svg">
              <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
              <g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g>
              <g id="SVGRepo_iconCarrier">
                <title>share</title>
                <path
                  d="M0 25.472q0 2.368 1.664 4.032t4.032 1.664h18.944q2.336 0 4-1.664t1.664-4.032v-8.192l-3.776 3.168v5.024q0 0.8-0.544 1.344t-1.344 0.576h-18.944q-0.8 0-1.344-0.576t-0.544-1.344v-18.944q0-0.768 0.544-1.344t1.344-0.544h9.472v-3.776h-9.472q-2.368 0-4.032 1.664t-1.664 4v18.944zM5.696 19.808q0 2.752 1.088 5.28 0.512-2.944 2.24-5.344t4.288-3.872 5.632-1.664v5.6l11.36-9.472-11.36-9.472v5.664q-2.688 0-5.152 1.056t-4.224 2.848-2.848 4.224-1.024 5.152zM32 22.080v0 0 0z">
                </path>
              </g>
            </svg>
            <svg class="max-w-10 max-h-10" viewBox="0 0 20 20" @click="deleteReservationModal(row)">
              <path
                d="M17.114,3.923h-4.589V2.427c0-0.252-0.207-0.459-0.46-0.459H7.935c-0.252,0-0.459,0.207-0.459,0.459v1.496h-4.59c-0.252,0-0.459,0.205-0.459,0.459c0,0.252,0.207,0.459,0.459,0.459h1.51v12.732c0,0.252,0.207,0.459,0.459,0.459h10.29c0.254,0,0.459-0.207,0.459-0.459V4.841h1.511c0.252,0,0.459-0.207,0.459-0.459C17.573,4.127,17.366,3.923,17.114,3.923M8.394,2.886h3.214v0.918H8.394V2.886z M14.686,17.114H5.314V4.841h9.372V17.114z M12.525,7.306v7.344c0,0.252-0.207,0.459-0.46,0.459s-0.458-0.207-0.458-0.459V7.306c0-0.254,0.205-0.459,0.458-0.459S12.525,7.051,12.525,7.306M8.394,7.306v7.344c0,0.252-0.207,0.459-0.459,0.459s-0.459-0.207-0.459-0.459V7.306c0-0.254,0.207-0.459,0.459-0.459S8.394,7.051,8.394,7.306"
                fill="red"></path>
            </svg> -->
            <img class="bg-green-500 p-2 rounded-xl max-w-10 max-h-10 w-auto cursor-pointer" @click="openLockerModal(row)"
              src="@/assets/OPEN-2.svg" alt="Odihnx" 
            />
            <img class="bg-gray-500 p-2 rounded-xl max-w-10 max-h-10 w-auto cursor-pointer" @click="copyLinkModal(row)"
              src="@/assets/SHARE-2.svg" alt="Odihnx" 
            />
            <img class="bg-red-500 p-2 rounded-xl max-w-10 max-h-10 w-auto cursor-pointer" @click="deleteReservationModal(row)"
              src="@/assets/TRASH-2.svg" alt="Odihnx" 
            />            
          </td>
          <td class="sm:hidden border-b border-l p-1">
            <div class="grid grid-cols-3 place-items-top place-content-evenly text-custom-xs" style="overflow-wrap: break-word;">
              <div>
                <p class="font-bold text-center">Locker</p>
                <p class="text-center">{{ row.nro_locker }}</p>
              </div>
              <div>
                <p class="font-bold text-center">Tiempo</p>
                <p class="text-center">
                  <TimerCountDown :duration="row.fecha_arriendo" />
                </p>
              </div>
              <div>
                <p class="font-bold text-center">Ubicación</p>
                <p class="text-center">{{ row.nombre_instalacion }}</p>
              </div>
            </div>
          </td>
          <td class="sm:hidden border-b border-l p-2">
            <div class="grid grid-cols-2 gap-2 place-content-evenly">
              <img class="bg-green-500 p-2 rounded-2xl w-auto cursor-pointer" @click="openLockerModal(row)"
                src="@/assets/OPEN-2.svg" alt="Odihnx" />
              <img class="bg-gray-500 p-2 rounded-2xl w-auto cursor-pointer" @click="copyLinkModal(row)"
                src="@/assets/SHARE-2.svg" alt="Odihnx" />
              <img class="bg-red-500 p-2 rounded-2xl w-auto cursor-pointer" @click="deleteReservationModal(row)"
                src="@/assets/TRASH-2.svg" alt="Odihnx" />              
            </div>
          </td>
        </tr>
      </tbody>
      <SkeletonTable v-else />
    </table>
    <UniversalModal @cancel="hideModal" :isOpen="showModal">
      <OpenLockerText v-if="modalType == 1" :acceptFunction="openLocker" :hideModal="hideModal" />
      <DeleteReservationText v-if="modalType == 2" :acceptFunction="deleteReserve" :hideModal="hideModal" />
      <CopyCodeText v-if="modalType == 3" :acceptFunction="copyLink" :link="link" :modalMessage="modalMessage" />
      <LoadingText v-if="modalType == 4" :hideModal="hideModal" />
      <AcceptText v-if="modalType == 5" :hideModal="hideModal" />
      <RejectedText v-if="modalType == 6" :hideModal="hideModal" />
      <OutTimeText v-if="modalType == 7" :hideModal="hideModal" />
    </UniversalModal>
  </div>
</template>

<style scoped>
.text-custom-xs {
  font-size: 0.65rem; /* Ajusta según tus necesidades */
}
</style>

<script>
import axios from 'axios'
import TimerCountDown from '@/components/ui/TimerCountDown.vue'
import UniversalModal from '@/components/ui/UniversalModal.vue'
import OpenLockerText from '@/components/modalboxs/OpenLockerText.vue'
import CopyCodeText from '@/components/modalboxs/CopyCodeText.vue'
import DeleteReservationText from '@/components/modalboxs/DeleteReservationText.vue'
import LoadingText from '@/components/modalboxs/LoadingText.vue'
import AcceptText from '@/components/modalboxs/AcceptText.vue'
import RejectedText from '@/components/modalboxs/RejectedText.vue'
import SkeletonTable from '@/components/ui/SkeletonTable.vue'
import OutTimeText from '@/components/modalboxs/OutTimeText.vue'
import EditableButton from '../ui/EditableButton.vue'

export default {
  name: 'ActiveTable',
  components: {
    TimerCountDown,
    UniversalModal,
    OpenLockerText,
    CopyCodeText,
    DeleteReservationText,
    LoadingText,
    AcceptText,
    SkeletonTable,
    RejectedText,
    OutTimeText,
    EditableButton
  },
  data () {
    return {
      activeReservations: [],
      showModal: false,
      loadingModal: false,
      link: '',
      socket: null,
      modalType: 0,
      selectedRow: null,
      modalMessage: '',
      loading: true,
      timeoutTimer: null

    }
  },
  async beforeMount () {
    await this.fetchData()
  },
  created () {
    this.socket = new WebSocket(`${process.env.VUE_APP_URL_SOCKET}`)
    const vm = this
    this.socket.onmessage = (e) => {
      try {
        const jsonData = JSON.parse(e.data)
        if (jsonData.messageDevice && jsonData.code == 200) {
          clearTimeout(vm.timeoutTimer);
          vm.modalType = 5;
        } else if (jsonData.code == 409){
          clearTimeout(vm.timeoutTimer);
          vm.modalType = 7;
        } else if (jsonData.code == 400){
          vm.modalType = 6;
        }
      } catch (error) {
        if (error instanceof SyntaxError) {
          console.log(
            'JSON string is not in the correct format:',
            error.message
          )
        } else {
          console.log('Error parsing JSON:', error.message)
        }
      }
    }
  },
  computed: {
    formatDate () {
      const today = new Date()
      return today.getFullYear() + '-' + (today.getMonth() + 1).toString().padStart(2, '0') + '-' + today.getDate().toString().padStart(2, '0') + ' ' + today.getHours().toString().padStart(2, '0') + ':' + today.getMinutes().toString().padStart(2, '0') + ':' + today.getSeconds().toString().padStart(2, '0')
    }
  },
  methods: {
    createReservation () {
      this.$router.push({ name: 'ScheduleView' })
    },
    copyLink () {
      navigator.clipboard.writeText(this.link)
      this.modalMessage = 'Link copiado!'
    },
    async deleteReserve () {
      const response = await axios
        .delete(
          // `${process.env.VUE_APP_URL_CERRADURAS}/arriendo/cancelar`,
          `${process.env.VUE_APP_URL_CERRADURAS}/device`,
          {},
          {
            headers: {
              Authorization: 'Bearer ' + localStorage.getItem('token')
            },
            params: {
              id: this.selectedRow.id
            }
          }
        )
        .then((response) => {
          if (response.status == 200) {
            this.fetchData()
            this.showModal = false
          }
        })
      return response
    },
    async fetchData () {
      const response = await axios
        .get(`${process.env.VUE_APP_URL_CERRADURAS}/arriendo/mis-arriendos`, {
          headers: {
            Authorization: 'Bearer ' + localStorage.getItem('token')
          }
        })
        .then((response) => {
          this.activeReservations = response.data.content
          this.loading = false
        })
      return response
    },
    hideModal () {
      this.showModal = false
      this.modalMessage = ''
    },
    openLocker () {      
      const request = {
        usuario: localStorage.getItem('user'),
        mesasageRequest: 'websocket',
        type: 'apertura_cliente',
        clave: this.selectedRow.clave_actual,
        token: localStorage.getItem('token'),
        fecha_arriendo: this.formatDate,
        // chapa: {
          chapaDTO: {
          id: this.selectedRow.id_device,
          nro_chapa: this.selectedRow.nro_locker
        }
      }
      this.socket.send(JSON.stringify(request))
      this.modalType = 4
      this.timeoutTimer = setTimeout(this.failedMessage, 10000)
    },
    openLockerModal (row) {
      this.modalType = 1
      this.showModal = true
      this.selectedRow = row
    },
    deleteReservationModal (row) {
      this.modalType = 2
      this.showModal = true
      this.selectedRow = row
    },
    copyLinkModal (row) {
      this.modalType = 3
      this.showModal = true
      this.selectedRow = row
      const params = new URLSearchParams({
        code: this.selectedRow.clave_actual,
        device: this.selectedRow.id_device,
        locker: this.selectedRow.nro_locker,
        instalacion: this.selectedRow.nombre_instalacion
      })
      this.link =
        process.env.VUE_APP_URL_FRONTEND +
        '/open/invitation?' +
        params.toString()
    },
    failedMessage () {
      this.modalType = 6
    }

  },
  props: {}
}
</script>
