<template>
  <div class="flex-col">
    <p class="text-3xl my-3 mb-6 font-bold text-gray-600 text-center">Agendar Reserva</p>
    <TabGroup :selectedIndex="selectedTab">
      <TabList
        class="flex mb-4 items-center mx-8 sm:mx-96 my-3 p-3 space-x-2 text-sm font-medium text-center text-gray-500 bg-white border border-gray-200 rounded-lg shadow-sm  sm:p-4 sm:space-x-4">
        <Tab v-slot="{ selected }" class="flex items-center">
          <span :class="{ 'text-blue-500 border-blue-600': selected, 'bg-white border-black text-black': !selected }"
            class="flex items-center justify-center w-5 h-5 mr-2 text-xs border-2  rounded-full shrink-0">
            1
          </span>
          <span :class="{ 'text-blue-500': selected, 'bg-white text-black': !selected }"
            class="hidden sm:inline-flex sm:ml-2">Fecha de reserva</span>
          <svg :class="{ 'text-blue-500': selected, 'bg-white text-black': !selected }" class="w-3 h-3 ml-2 sm:ml-4"
            aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 12 10">
            <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
              d="m7 9 4-4-4-4M1 9l4-4-4-4" />
          </svg>
        </Tab>
        <Tab v-slot="{ selected }" class="flex items-center">
          <span :class="{ 'text-blue-500 border-blue-600': selected, 'bg-white border-black text-black': !selected }"
            class="flex items-center justify-center w-5 h-5 mr-2 text-xs border-2  rounded-full shrink-0">
            2
          </span>
          <span :class="{ 'text-blue-500': selected, 'bg-white text-black': !selected }"
            class="hidden sm:inline-flex sm:ml-2"> Seleccionar locker </span>
          <svg :class="{ 'text-blue-500': selected, 'bg-white text-black': !selected }" class="w-3 h-3 ml-2 sm:ml-4"
            aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 12 10">
            <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
              d="m7 9 4-4-4-4M1 9l4-4-4-4" />
          </svg>
        </Tab>
        <Tab v-slot="{ selected }" class="flex items-center">
          <span :class="{ 'text-blue-500 border-blue-600': selected, 'bg-white border-black text-black': !selected }"
            class="flex items-center justify-center w-5 h-5 mr-2 text-xs border-2  rounded-full shrink-0">
            3
          </span>
          <span :class="{ 'text-blue-500': selected, 'bg-white text-black': !selected }"
            class="hidden sm:inline-flex sm:ml-2"> Confirmación </span>
        </Tab>
      </TabList>
      <TabPanels class="m-8 sm:mx-96">
        <TabPanel class="p-10 mb-20 sm:mb-48 rounded-lg bg-white/75 border shadow space-y-6">
          <p class="mb-5 text-xl"> 1. Seleccione los datos de la reserva </p>

          <!-- Selector de instalación -->
          <Listbox v-model="selectedInstallation">
            <div class="relative mt-1">
              <ListboxButton
                class="relative border w-full cursor-default rounded-lg bg-white py-2 pl-3 pr-10 text-left shadow-md focus:outline-none focus-visible:border-indigo-500 focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 focus-visible:ring-offset-blue-300 sm:text-sm">
                <span class="block truncate">{{ selectedInstallation ? selectedInstallation[1] : "Selecciona tu ubicación" }}</span>
                <span class="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                  <ChevronUpDownIcon class="h-5 w-5 text-gray-400" aria-hidden="true" />
                </span>
              </ListboxButton>
              <transition leave-active-class="transition duration-100 ease-in" leave-from-class="opacity-100"
                leave-to-class="opacity-0">
                <ListboxOptions
                  class="absolute mt-1 max-h-60 w-full z-20 overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                  <ListboxOption v-slot="{ active, selected }" v-for="installation in racks" :key="installation"
                    :value="installation" as="template">
                    <li :class="[
                      active ? 'bg-blue-100 text-blue-900' : 'text-gray-900',
                      'relative cursor-default select-none py-2 pl-10 pr-4',
                    ]">
                      <span :class="[
                        selected ? 'font-medium' : 'font-normal',
                        'block truncate',
                      ]">{{ installation[1]}}</span>
                      <span v-if="selected" class="absolute inset-y-0 left-0 flex items-center pl-3 text-blue-600">
                        <CheckIcon class="h-5 w-5" aria-hidden="true" />
                      </span>
                    </li>
                  </ListboxOption>
                </ListboxOptions>
              </transition>
            </div>
          </Listbox>

          <!-- Selector de usuario  -->
          <Listbox v-if="role" v-model="selectedPerson" :disabled="!arrUsers">
            <div class="relative mt-1">
              <ListboxButton
                class="relative border w-full cursor-default rounded-lg bg-white py-2 pl-3 pr-10 text-left shadow-md focus:outline-none focus-visible:border-indigo-500 focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 focus-visible:ring-offset-blue-300 sm:text-sm"
                :class="arrUsers.length === 0 ? 'bg-gray-100 text-gray-400' : ''"
                >
                
                <span class="block truncate">{{ selectedPerson ? selectedPerson : "Selecciona un usuario" }}</span>
                <span class="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                  <ChevronUpDownIcon class="h-5 w-5 text-gray-400" aria-hidden="true" />
                </span>
              </ListboxButton>
              <transition leave-active-class="transition duration-100 ease-in" leave-from-class="opacity-100"
                leave-to-class="opacity-0">
                <ListboxOptions
                  class="absolute mt-1 max-h-60 w-full z-20 overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                  <ListboxOption v-slot="{ active, selected }" v-for="(user, index) in arrUsers" :key="user" 
                    :class="index === 0 ? 'text-blue-500': ''"
                    :value="user" as="template">
                    <li :class="[
                      active ? 'bg-blue-100 text-blue-900' : 'text-gray-900',
                      'relative cursor-default select-none py-2 pl-10 pr-4',
                    ]">
                      <span :class="[
                        selected ? 'font-medium' : 'font-normal',
                        'block truncate',
                      ]">{{ user }}</span>
                      <span v-if="selected" class="absolute inset-y-0 left-0 flex items-center pl-3 text-blue-600">
                        <CheckIcon class="h-5 w-5" aria-hidden="true" />
                      </span>
                    </li>
                  </ListboxOption>
                </ListboxOptions>
              </transition>
            </div>
          </Listbox>

          <!-- Selector de fecha -->
          <VueDatePicker :disabled="!selectedInstallation" v-model="date" :min-date="new Date()" placeholder="Selecciona el dia de inicio" auto-apply
            :close-on-auto-apply="true" :enable-time-picker="false" class="my-5">
          </VueDatePicker>

          <!-- Selector de hora -->
          <Listbox v-model="selectedHour" class="my-5" :disabled="!date">
            <div class="relative mt-1">
              <ListboxButton :disabled="!date"
                class="relative w-full disabled:cursor-default border rounded-lg bg-white py-2 pl-3 pr-10 text-left shadow-md focus:outline-none focus-visible:border-indigo-500 focus-visible:ring-2 disabled:bg-gray-100 disabled:text-gray-400 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 focus-visible:ring-offset-blue-300 sm:text-sm">
                <span class="block truncate"> {{ selectedHour ? selectedHour : "Selecciona la hora de inicio de la reserva" }} </span>
                <span class="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                  <ChevronUpDownIcon class="h-5 w-5 text-gray-400" aria-hidden="true" />
                </span>
              </ListboxButton>
              <transition leave-active-class="transition duration-100 ease-in" leave-from-class="opacity-100"
                leave-to-class="opacity-0">
                <ListboxOptions
                  class="absolute mt-1 max-h-60 w-full z-20 overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                  <ListboxOption v-slot="{ active, selected }" v-for="item in filteredHours" :key="item" :value="item"
                    as="template">
                    <li :class="[
                      active ? 'bg-blue-100 text-blue-900' : 'text-gray-900',
                      'relative cursor-default select-none py-2 pl-10 pr-4',
                    ]">
                      <span :class="[
                        selected ? 'font-medium' : 'font-normal',
                        'block truncate',
                      ]">{{ item }}</span>
                      <span v-if="selected" class="absolute inset-y-0 left-0 flex items-center pl-3 text-blue-600">
                        <CheckIcon class="h-5 w-5" aria-hidden="true" />
                      </span>
                    </li>
                  </ListboxOption>
                </ListboxOptions>
              </transition>
            </div>
          </Listbox>
          <div class="flex justify-end">
            <button :disabled="!firstStepValid" :class="{ 'opacity-25 cursor-not-allowed': !firstStepValid }"
              class="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 mr-4 rounded"
              @click="secondStepHandler"> Seleccionar locker <span class="text-white" aria-hidden="true">&rarr;</span>
            </button>
          </div>
        </TabPanel>
        
        <TabPanel
          class="p-10 rounded-lg flex flex-col justify-center sm:items-center gap-2 mb-32 bg-white/75 border shadow">
          <p class="text-xl my-5"> 2. Elige tu locker</p>
          <div class="flex flex-row justify-center flex-nowrap gap-2 sm:w-96">
            <LockerButton :buttonProps="lockerLayout[0]" @click="handleLocker" v-model:buttonColorControl="buttonColorControl" v-model:selectedLocker="selectedLocker"/>
            <LockerButton :buttonProps="lockerLayout[1]" @click="handleLocker" v-model:buttonColorControl="buttonColorControl" v-model:selectedLocker="selectedLocker"/>
            <LockerButton :buttonProps="lockerLayout[2]" @click="handleLocker" v-model:buttonColorControl="buttonColorControl" v-model:selectedLocker="selectedLocker"/>
            <LockerButton :buttonProps="lockerLayout[3]" @click="handleLocker" v-model:buttonColorControl="buttonColorControl" v-model:selectedLocker="selectedLocker"/>
          </div>
          <div class="flex flex-row justify-center flex-nowrap gap-2 sm:w-96">
            <LockerButton :buttonProps="lockerLayout[4]" @click="handleLocker" v-model:buttonColorControl="buttonColorControl" v-model:selectedLocker="selectedLocker"/>
            <LockerButton :buttonProps="lockerLayout[5]" @click="handleLocker" v-model:buttonColorControl="buttonColorControl" v-model:selectedLocker="selectedLocker"/>
          </div>
          <div class="flex flex-row justify-center flex-nowrap gap-2 sm:w-96">
            <LockerButton :buttonProps="lockerLayout[6]" @click="handleLocker" v-model:buttonColorControl="buttonColorControl" v-model:selectedLocker="selectedLocker"/>
            <LockerButton :buttonProps="lockerLayout[7]" @click="handleLocker" v-model:buttonColorControl="buttonColorControl" v-model:selectedLocker="selectedLocker"/>
          </div>
          <div class="flex flex-row justify-center flex-nowrap gap-2 sm:w-96">
            <LockerButton :buttonProps="lockerLayout[8]" @click="handleLocker" v-model:buttonColorControl="buttonColorControl" v-model:selectedLocker="selectedLocker"/>
            <LockerButton :buttonProps="lockerLayout[9]" @click="handleLocker" v-model:buttonColorControl="buttonColorControl" v-model:selectedLocker="selectedLocker"/>
          </div>
          <div class="flex flex-row justify-center flex-nowrap gap-2 sm:w-96">
            <LockerButton :buttonProps="lockerLayout[10]" @click="handleLocker" v-model:buttonColorControl="buttonColorControl" v-model:selectedLocker="selectedLocker"/>
            <LockerButton :buttonProps="lockerLayout[11]" @click="handleLocker" v-model:buttonColorControl="buttonColorControl" v-model:selectedLocker="selectedLocker"/>
          </div>
          <div class="flex flex-row justify-center flex-nowrap gap-2 sm:w-96">
            <LockerButton :buttonProps="lockerLayout[12]" @click="handleLocker" v-model:buttonColorControl="buttonColorControl" v-model:selectedLocker="selectedLocker"/>
            <LockerButton :buttonProps="lockerLayout[13]" @click="handleLocker" v-model:buttonColorControl="buttonColorControl" v-model:selectedLocker="selectedLocker"/>
          </div>
          <div class="flex flex-row justify-center flex-nowrap gap-2 sm:w-96">
            <LockerButton :buttonProps="lockerLayout[14]" @click="handleLocker" v-model:buttonColorControl="buttonColorControl" v-model:selectedLocker="selectedLocker"/>
            <LockerButton :buttonProps="lockerLayout[15]" @click="handleLocker" v-model:buttonColorControl="buttonColorControl" v-model:selectedLocker="selectedLocker"/>
          </div>
          <div class="flex justify-around gap-2 w-full mt-5">
            <button class="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 ml-4 rounded"
              @click="selectedTab--"> <span class="text-white" aria-hidden="true">&larr;</span> Volver
            </button>
            <button :disabled="!secondStepValid" :class="{ 'opacity-25 cursor-not-allowed': !secondStepValid }"
              class="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 mr-4 rounded" @click="selectedTab++">
              Confirmación <span class="text-white" aria-hidden="true">&rarr;</span> </button>
          </div>
        </TabPanel>
        <TabPanel class="p-10 rounded-lg bg-white/75 border sm:mb-32 shadow">
          <p class="text-2xl my-5"> Detalles de la reserva </p>
          <table class="w-full table-fixed border-x border-b">
            <tbody>
              <tr class="odd:bg-gray-100 even:bg-white hover:!bg-stone-200">
                <td class="border-b border-l p-2 text-left"> Usuario: </td>
                <td class="border-b border-l p-2 text-left"> {{ user }}</td>
              </tr>
              <tr class="odd:bg-gray-100 even:bg-white hover:!bg-stone-200">
                <td class="border-b border-l p-2 text-left"> Ubicacion: </td>
                <td class="border-b border-l p-2 text-left"> {{ selectedInstallation ?
                  selectedInstallation[1] : "No se registra" }}</td>
              </tr>
              <tr class="odd:bg-gray-100 even:bg-white hover:!bg-stone-200">
                <td class="border-b border-l p-2 text-left"> Casillero: </td>
                <td class="border-b border-l p-2 text-left"> {{ selectedLocker ? selectedLocker.padStart(2, '0') : "No se registra" }}
                </td>
              </tr>
              <tr class="odd:bg-gray-100 even:bg-white hover:!bg-stone-200">
                <td class="border-b border-l p-2 text-left"> Horario: </td>
                <td class="border-b border-l p-2 text-left"> {{ date ? formatTime + ", " + formatDate + " (2 dias)" : "No se registra" }} </td>
              </tr>
            </tbody>
          </table>
          <div class="flex justify-around w-full gap-2 my-10">
            <button class="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 ml-4 rounded"
              @click="selectedTab--"> <span class="text-white" aria-hidden="true">&larr;</span> Volver</button>
            <button @click="submit()" class="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 mr-4 rounded">
              Confirmar &#10003; </button>
          </div>
        </TabPanel>
      </TabPanels>
    </TabGroup>

    <!-- modal maximo reintentos de reserva -->
    <div 
      v-if="maxReservationStatus"       
      class="fixed inset-0 flex items-center justify-center z-50"
    >
      <div class="bg-gray-800 bg-opacity-30 absolute inset-0"></div>
      <div 
        class="md:w-96 w-64 bg-yellow-300 shadow-lg z-10
        border border-slate-400 rounded-xl">
        <MaxReservationText/>
      </div>
    </div>
    <div 
      v-if="rejectedText"       
      class="fixed inset-0 flex items-center justify-center z-50"
    >
      <div class="bg-gray-800 bg-opacity-30 absolute inset-0"></div>
      <div 
        class="md:w-96 w-64 bg-yellow-300 shadow-lg z-10
        border border-slate-400 rounded-xl">
        <ExceededTimeText/>
      </div>
    </div>

  </div>
</template>

<script>
import VueDatePicker from '@vuepic/vue-datepicker'
import '@vuepic/vue-datepicker/dist/main.css'
import axios from 'axios'
import { toRaw } from 'vue'
import LockerButton from '@/components/ui/LockerButton.vue'
import MaxReservationText from '@/components/modalboxs/MaxReservationText.vue'
import ExceededTimeText from './modalboxs/ExceededTimeText.vue'
import { TabGroup, TabList, Tab, TabPanels, TabPanel, Listbox, ListboxButton, ListboxOptions, ListboxOption } from '@headlessui/vue'
import { CheckIcon, ChevronUpDownIcon } from '@heroicons/vue/20/solid'
import { format, isToday } from 'date-fns';

export default {
  components: { VueDatePicker, LockerButton, TabGroup, TabList, Tab, TabPanels, TabPanel, Listbox, 
              ListboxButton, ListboxOptions, ListboxOption, CheckIcon, ChevronUpDownIcon, 
              MaxReservationText, ExceededTimeText },
  data () {
    return {
      date: null,
      selectedHour: null,
      locker: null,
      selectedInstallation: null,
      installations: [],
      selectedPerson: null, 
      user: localStorage.getItem('user') ? localStorage.getItem('user') : 'No se registra',
      role: localStorage.getItem('role') === "1" ? localStorage.getItem('role') : null,
      selectedTab: 0,
      selectedLocker: null,
      hours: [
        '00:00',
        '01:00',
        '02:00',
        '03:00',
        '04:00',
        '05:00',
        '06:00',
        '07:00',
        '08:00',
        '09:00',
        '10:00',
        '11:00',
        '12:00',
        '13:00',
        '14:00',
        '15:00',
        '16:00',
        '17:00',
        '18:00',
        '19:00',
        '20:00',
        '21:00',
        '22:00',
        '23:00'
      ],
      lockerLayout: [
        {
          id: 1,
          active: true,
          size: 'small'
        },
        {
          id: 2,
          active: true,
          size: 'small'
        },
        {
          id: 3,
          active: true,
          size: 'small'
        },
        {
          id: 4,
          active: true,
          size: 'small'
        },
        {
          id: 5,
          active: true,
          size: 'small'
        },
        {
          id: 6,
          active: true,
          size: 'small'
        },
        {
          id: 7,
          active: true,
          size: 'small'
        },
        {
          id: 8,
          active: true,
          size: 'small'
        },
        {
          id: 9,
          active: true,
          size: 'small'
        },
        {
          id: 10,
          active: true,
          size: 'small'
        },
        {
          id: 11,
          active: true,
          size: 'small'
        },
        {
          id: 12,
          active: true,
          size: 'small'
        },
        {
          id: 13,
          active: true,
          size: 'small'
        },
        {
          id: 14,
          active: true,
          size: 'small'
        },
        {
          id: 15,
          active: true,
          size: 'big'
        },
        {
          id: 16,
          active: true,
          size: 'big'
        }
      ],
      maxReservationStatus: false,
      rejectedText : false,
      timeoutTimer: null ,
      buttonColorControl: '',
      instalations: [],
      arrUsers: []
    }
  },
  async created () {
    const response = await axios
      .get(`${process.env.VUE_APP_URL_BACKEND}/instalaciones/getInstalaciones`, {
        headers: {
          Authorization: 'Bearer ' + localStorage.getItem('token')
        }
      })
      .then((response) => {
        this.installations = response.data.filter(item => {
          if(localStorage.getItem('role') === "1"){
            return item
          } else {
            return item.pagado
          }          
        })
      })
    return response
  },
  watch: {
    selectedInstallation: async function (val) {
      const response = await axios
        .get(
          `${process.env.VUE_APP_URL_CERRADURAS}/arriendo/device/${val.nro_serie}/arriendos`,
          {
            headers: {
              Authorization: 'Bearer ' + localStorage.getItem('token')
            }
          }
        )
        .then((response) => {
          return response.data.content
        })
      for (const j in this.lockerLayout) {
        this.lockerLayout[j].active = true
      }
      for (const i in response) {
        this.lockerLayout[response[i].nro_locker - 1].active = !this.lockerLayout[
          response[i].nro_locker - 1
        ].active
      }

      await this.getUsersByInstalation();
 
    }
  },
  computed: {
    formatDate () {
      const MyDate = new Date(this.date)
      const MyDateString =
        MyDate.getFullYear() +
        '-' +
        ('0' + (MyDate.getMonth() + 1)).slice(-2) +
        '-' +
        ('0' + MyDate.getDate()).slice(-2)
      return MyDateString
    },
    formatTime () {
      const newTime = this.selectedHour + ':00'
      return newTime
    },
    firstStepValid () {
      return this.selectedInstallation && this.selectedHour && this.date
    },
    secondStepValid () {
      return this.selectedLocker
    },
    filteredHours() {
      if (isToday(this.date)) {
        const currentTime = format(this.date, 'HH:mm');
        const filteredHours = this.hours.filter((hour) => hour > currentTime.slice(0, 2));
        return filteredHours;
      }
      return this.hours;
    },
    racks () {
      const racks = []
      for (const installation of this.installations) {
        for (const rack of installation.racks) {
          if (rack.dispositivos.length > 0) {
            racks.push([installation.id, installation.nombre_instalacion + (installation.racks.length > 1 ? (' - ' + rack.ubicacion) : ''), toRaw(rack)])
          }
        }
      }
      return racks
    }
  },
  methods: {
    async submit () {
      this.timeoutTimer = setTimeout(this.handleErrorTimeOut, 10000);

      const response = await axios
        .post(
          `${process.env.VUE_APP_URL_CERRADURAS}/arriendo`,
          {
            fecha_arriendo: this.formatDate + ' ' + this.formatTime,
            nro_locker: this.selectedLocker,
            id_device: this.selectedInstallation[2].dispositivos[0].nro_serie,
            usuario: this.selectedPerson ? this.selectedPerson : localStorage.getItem('user'),
            instalacion: String(this.selectedInstallation.id)
          },
          {
            headers: {
              Authorization: 'Bearer ' + localStorage.getItem('token')
            }
          }
        )
        .then((response) => {          
          if (response.status === 200) {
            clearTimeout(this.timeoutTimer);
            this.$router.push({ name: 'ActiveTable' })
          } 
          return response
        }).catch((error) => {
          if (error.response.status === 400){
            clearTimeout(this.timeoutTimer);
            this.maxReservationStatus = true;
          }
        })
      return response
    },
    handleErrorTimeOut (){
      this.rejectedText = !this.rejectedText;
    },
    handleLocker (target) {
      target.preventDefault()
      this.selectedLocker = target.target.id
    },
    async secondStepHandler () {
      const response = await axios
        .post(
          `${process.env.VUE_APP_URL_CERRADURAS}/arriendo/lockers-disponibles`,
          {
            fecha: this.formatDate + ' ' + this.formatTime,
            id_device: this.selectedInstallation[2]["dispositivos"][0].nro_serie
          },
          {
            headers: {
              Authorization: 'Bearer ' + localStorage.getItem('token')
            }
          }
        )
        .then((response) => {
          this.selectedTab++
          return response.data.lockers_disponibles
        })
      for (const j in this.lockerLayout) {
        this.lockerLayout[j].active = false
      }
      for (const i in response) {
        this.lockerLayout[response[i] - 1].active = true
      }
      return this.lockerLayout
    },
    async getUsersByInstalation () {
      try {
        const url = `${process.env.VUE_APP_URL_BACKEND}/departamentos/instalacion/${this.selectedInstallation[0]}`;
        const res = await axios.get(url)
                              .catch(error => {
                                  console.log(error)
                              });

        const users = res?.data.map(item => String(item?.correo).replace(' ', '')); // Esto es un arreglo con correos

        const currentUser = localStorage.getItem('user');

        if (users && currentUser) {
            const index = users.indexOf(currentUser);
            if (index !== -1) {
                users.splice(index, 1); // Elimina el correo actual del arreglo users
            }
            users.unshift(currentUser); // Agrega el correo actual al principio del arreglo users
        }

        this.arrUsers = users;

      } catch (ex) {
          console.log(`Error getUsersByInstalation(): ${ex}`);
      }
    }
  }
}
</script>
