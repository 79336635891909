<template>
    <div class="flex flex-col items-center justify-center mx-10 mt-10 mb-20 sm:mx-2">
        <div class="max-w-md my-20 w-full bg-white p-8 shadow border-2 rounded">
            <h2 class="text-2xl text-center font-semibold mb-6">Cambiar contraseña</h2>
            <form @submit.prevent="changePassword">
                <div class="mb-4">
                    <label for="password" class="block mb-2 font-medium"> Antigua contraseña: </label>
                    <input type="password" v-model="newPassword"
                        class="w-full p-2 border-gray-300 border rounded focus:outline-none focus:ring focus:border-blue-300"
                        required />
                </div>
                <div class="mb-4">
                    <label for="password" class="block mb-2 font-medium"> Nueva contraseña: </label>
                    <input type="password" v-model="oldPassword"
                        class="w-full p-2 border-gray-300 border rounded focus:outline-none focus:ring focus:border-blue-300"
                        required />
                </div>
                <div class="my-6">
                    <label for="confirmPassword" class="block mb-2 font-medium"> Confirmar nueva contraseña: </label>
                    <input type="password" v-model="confirmPassword"
                        class="w-full p-2 border-gray-300 border rounded focus:outline-none focus:ring focus:border-blue-300"
                        required />
                </div>

                <button type="submit"
                    class="w-full py-2 px-4 bg-blue-500 hover:bg-blue-600 text-white rounded focus:outline-none focus:ring">
                    Cambiar contraseña
                </button>

            </form>
        </div>
    </div>
</template>

<script>
import axios from 'axios'
export default {
  name: 'PasswordChangerView',
  data () {
    return {
      newPassword: '',
      oldPassword: '',
      confirmPassword: ''
    }
  },
  methods: {
    async changePassword () {
      const headers = {
        params: {
          password: this.oldPassword,
          user: localStorage.getItem('user')
        },
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + localStorage.getItem('token')
        }
      }
      const response = await axios
        .put(
          process.env.VUE_APP_URL_BACKEND + '/auth/usuario/actualizarPassword', {
          }
          ,
          headers
        )
        .then((response) => {
          if (response.status == 200) {
            this.$router.push({ name: 'MobileLayoutView' })
          }
        })
        .catch((error) => {
          this.msg = 'ERROR AL CREAR USUARIO' + error.message
          window.scrollTo(0, 0)
        })
      return response
    },
    handleSuccess (response) {
      this.captchaToken = response
    }
  }
}
</script>
