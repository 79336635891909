export default function getExpiration (token) {
  if (!token) return null
  const base64Url = token.split('.')[1]
  const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/')
  const info = JSON.parse(decodeURIComponent(Buffer.from(base64, 'base64').toString('ascii').split('').map(function (c) {
    return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2)
  }).join('')))

  return info.fechaExpiracion
}
