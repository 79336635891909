<template>
  <div v-if="hours >= 48 && hours <= 168">
    {{ Number(hours / 24).toFixed() }} Días
  </div>
  <div v-else-if="hours < 48">
    {{ hours }}h {{ minutes }}m
  </div>
  <div v-else>
    Desde el
    {{ date }}
  </div>
</template>

<script>
export default {
  name: 'TimerCountDown',
  data () {
    return {
      hours: null,
      minutes: null,
      seconds: null,
      countdown: null
    }
  },
  props: {
    duration: {
      type: String,
      required: true
    }
  },
  mounted () {
    this.startCountdown()
  },
  methods: {
    startCountdown () {
      let remainingTime = this.expirationDate(this.duration)
      this.countdown = setInterval(() => {
        if (remainingTime > 0) {
          remainingTime--
          this.hours = Math.floor(remainingTime / 3600)
          this.minutes = Math.floor((remainingTime % 3600) / 60)
          this.seconds = Math.floor(remainingTime % 60)
        } else {
          clearInterval(this.countdown)
        }
      }, 1000)
    },
    expirationDate (date) {
      const dateUnix = Date.parse(date) / 1000 + 2 * 86400
      const dateNow = Date.now() / 1000
      const dateDiff = dateUnix - dateNow
      return dateDiff
    }
  },
  computed: {
    date () {
      return new Date(this.duration).toLocaleDateString('en-GB', {
        day: '2-digit',
        month: '2-digit',
        year: '2-digit'
      })
    }
  }
}
</script>
