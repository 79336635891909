<script setup>
import { defineEmits, ref, defineProps } from 'vue';
import axios from 'axios';

const reSendStatus = ref(false);
const reSendMsg = ref('');

const emit = defineEmits(['update:codeRetry', 'update:modalCodeStatus']);

const props = defineProps({
    user: {
    type: String,
    required: true,
  }
});

const entrerCode = () =>{
    emit('update:codeRetry', false);
    emit('update:modalCodeStatus', true);
}

const getNewCode = async () =>{
    reSendMsg.value = "Cargando ...";
    reSendStatus.value = true;

    const headers = {
    'Content-Type': 'application/json'
  }
  
  await axios
    .get(process.env.VUE_APP_URL_BACKEND + '/auth/codigo?email=' + props.user, {
      headers: headers
    })
    .then(() => {
        reSendMsg.value = "Mensaje enviado exitoamente!";
        setTimeout(() => {
            reSendStatus.value = false;
            emit('update:codeRetry', false);
            emit('update:modalCodeStatus', true);

        }, 2000);
    })
    .catch((error) => {
      console.log(error);
      reSendMsg.value = "Error, intente más tarde";
      setTimeout(() => {
            reSendMsg.value = "";
            reSendStatus.value = false;
            emit('update:codeRetry', false);
        }, 2000);      
    });    



}

</script>

<template>
    <div class="p-4 max-sm:w-80 shadow-lg border rounded-md">
        <h1 class="font-bold">Aun no confirmas tu cuenta en ODIHNX</h1>
        <h2 class="font-semibold">Porfavor selecciona para continuar</h2>
        {{ props.email }}
        <div class="text-center">
            <button 
                @click="getNewCode"
                :class="`w-full flex mt-8 justify-center py-2 px-4 border 
                        border-transparent rounded-md shadow-sm text-sm 
                        font-medium text-white bg-[#0064ff] hover:bg-blue-400
                        focus:outline-none focus:ring-2 focus:ring-offset-2 
                        focus:ring-blue-500`"
            >
                Reenviar código por email
            </button>
            <span v-if="reSendStatus" class="bg-gray-300 rounded-lg p-2">{{reSendMsg}}</span>
            <button 
                type="submit"
                @click="entrerCode"
                :class="`w-full flex mt-8 justify-center py-2 px-4 border 
                        border-transparent rounded-md shadow-sm text-sm 
                        font-medium text-white bg-[#0064ff] hover:bg-blue-400
                        focus:outline-none focus:ring-2 focus:ring-offset-2 
                        focus:ring-blue-500`"
            >
                Ingresar código previamente enviado por email
            </button>
        </div>
    </div>
</template>