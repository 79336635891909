<template>
  <footer class="bottom-0 inset-x-0 text-center z-20 w-full p-4 shadow bg-[#0064ff]">
    <div :class='shouldShowImage ? `flex flex-col` : `grid md:grid-cols-9 xs:grid-cols-5 justify-items-stretch items-center `'>
      <div class="grid md:col-span-4 xs:col-span-2 justify-items-center mb-4">
          <img
            src="../../assets/odihnx-logotipo-vikingo.svg"
            alt="Logotipo vikingo"
            :class='shouldShowImage ? `h-12` : `hidden`'
          />
        </div>
      <span class="grow text-sm text-center text-white">© 2024 Odihnx V1.0</span>
    </div>
  </footer>
</template>

<script>
export default {
  name: 'FooterComponent',
  data() {
    return {
      shouldShowImage: false,
    };
  },
  watch: {
    $route(to) {
      this.shouldShowImage = this.isImageVisible(to);
    },
  },
  mounted() {
    // Verificar la visibilidad de la imagen al cargar la página
    this.shouldShowImage = this.isImageVisible(this.$route);
  },
  methods: {
    isImageVisible(route) {
      // Definir las rutas en las que se debe mostrar la imagen
      const allowedRoutes = ['/', '/register'];
      return allowedRoutes.includes(route.path);
    },
  },
};
</script>
