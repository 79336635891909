<script setup>
</script>

<template>
    <div class="p-4 max-sm:w-80 shadow-lg ">

        <h2 class="mb-6 font-semibold">Alerta</h2>
        <h3 class="mb-6 text-center text-xl font-semibold">Maximo de 3 reservas alcanzado</h3>
        <p>
            Si dese realizar una nueva reserva, dirigase a sus reservas y finalice una, o espere a que alguna termine.
        </p>
        <div class="flex justify-end text-center">
            <button 
            @click="$router.push({ name: 'ActiveTable' })"
                class="inline-flex justify-center rounded-md border border-transparent bg-blue-100 px-4 py-2 text-sm font-medium text-blue-900 hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2 my-4"
            >
                Mis Reservas
            </button>
        </div>

    </div>
</template>