<template>
    <DialogTitle as="h3" class="text-lg font-semibold leading-6 text-gray-900 mb-3">
        Copiar link
    </DialogTitle>
    <input type="text" class="w-full border border-gray-300 rounded-md p-2" placeholder="Ingrese el correo del invitado"
        :value="this.link" disabled />
    <div class="flex flex-row justify-start items-center gap-2">
        <button type="button" @click="acceptFunction"
            class="inline-flex justify-center rounded-md border border-transparent bg-blue-100 px-4 py-2 text-sm font-medium text-blue-900 hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2 my-4">
            Copiar link
        </button>
        <div> {{ this.modalMessage }} </div>
    </div>
</template>

<script>
import {
  DialogTitle
} from '@headlessui/vue'
export default {
  name: 'CopyCodeText',
  components: {
    DialogTitle
  },
  props: {
    link: {
      type: String
    },
    modalMessage: {
      type: String
    },
    acceptFunction: {
      type: Function
    }
  }
}
</script>
