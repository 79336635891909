<template>
  <div class="m-8 py-3 px-2 mb-3 sm:px-6 lg:px-8 bg-white border-2 rounded-md overflow-x shadow-md h-full sm:mb-20 sm:pb-10">
    <div class="flex overflow-scroll flex-col flex-nowrap">
      <div class="flex justify-between">
        <h1 class="text-3xl font-bold tracking-tight text-gray-900">
          Información del Perfil
        </h1>
      </div>
      <div class="grid sm:gap-4 grid-cols-2">
        <div class="m-2 p-2 border rounded-md">
          Usuario: {{ user.usuario ? user.usuario : "No disponible" }}
        </div>
        <div class="m-2 p-2 border rounded-md">
          Direccion: {{ user.casa_depto ? user.casa_depto : "No disponible" }}
        </div>
        <div class="m-2 p-2 border rounded-md">
          Correo: {{ user.correo ? user.correo : "No disponible" }}
        </div>
        <div class="m-2 p-2 border rounded-md">
          Telefono: {{ user.telefono ? user.telefono : "No disponible" }}
        </div>
        <div class="m-2 p-2 border rounded-md">
          Instalacion: {{ userInstallation ? userInstallation : "No disponible" }}
        </div>
        <br>
        <button  class="bg-blue-500 disabled:bg-gray-200 m-2 sm:mx-20 p-2 border rounded-md text-white shadow hover:bg-blue-700" @click="$router.push({name: 'EditProfileView'})">
          Editar Perfil
        </button>
        <button disabled class="bg-blue-500 m-2 sm:mx-20 disabled:bg-gray-200 p-2 border rounded-md text-white shadow hover:bg-blue-700" @click="$router.push({name: 'EditProfileView'})">
          Cambiar contraseña
        </button>
      </div>
      <div class="flex justify-between">
        <h1 class="text-3xl font-bold tracking-tight text-gray-900 my-4">
          Reservas Activas
        </h1>
      </div>
      <table class="table-auto overflow-scroll w-full pt-5 border-x border-b">
        <thead>
          <tr>
            <th
              class="border-b border-l border-blue-500 bg-blue-500 p-2 text-center font-bold text-white"
            >
              ID Reserva
            </th>
            <th
              class="border-b border-l border-blue-500 bg-blue-500 p-2 text-center font-bold text-white"
            >
              Ubicacion
            </th>
            <th
              class="border-b border-l border-blue-500 bg-blue-500 p-2 text-center font-bold text-white"
            >
              N° Locker
            </th>
            <th
              class="border-b border-l border-blue-500 bg-blue-500 p-2 text-center font-bold text-white"
            >
              Acciones
            </th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-bind:key="index"
            :value="row.id"
            v-for="(row, index) in activeReservations"
            class="bg-white odd:bg-gray-100 hover:!bg-stone-200 cursor-pointer"
          >
            <td class="border-b border-l p-2 text-left">
              {{ row.id }}
            </td>
            <td class="border-b border-l p-2 text-left">
              {{ row.nombre_instalacion }}
            </td>
            <td
              class="border-b border-l p-2 text-left"
              @click="
                $router.push({
                  name: 'InputPassView',
                  query: {
                    code: row.clave_actual,
                    id_device: row.id_device,
                    n_locker: row.nro_locker,
                  },
                })
              "
            >
              {{ row.nro_locker }}
            </td>
            <td
              class="border-b border-l p-2 flex justify-center"
              @click="deleteReserve(row.id)"
            >
              <svg class="max-w-10 max-h-10" viewBox="0 0 20 20">
                <path
                  d="M17.114,3.923h-4.589V2.427c0-0.252-0.207-0.459-0.46-0.459H7.935c-0.252,0-0.459,0.207-0.459,0.459v1.496h-4.59c-0.252,0-0.459,0.205-0.459,0.459c0,0.252,0.207,0.459,0.459,0.459h1.51v12.732c0,0.252,0.207,0.459,0.459,0.459h10.29c0.254,0,0.459-0.207,0.459-0.459V4.841h1.511c0.252,0,0.459-0.207,0.459-0.459C17.573,4.127,17.366,3.923,17.114,3.923M8.394,2.886h3.214v0.918H8.394V2.886z M14.686,17.114H5.314V4.841h9.372V17.114z M12.525,7.306v7.344c0,0.252-0.207,0.459-0.46,0.459s-0.458-0.207-0.458-0.459V7.306c0-0.254,0.205-0.459,0.458-0.459S12.525,7.051,12.525,7.306M8.394,7.306v7.344c0,0.252-0.207,0.459-0.459,0.459s-0.459-0.207-0.459-0.459V7.306c0-0.254,0.207-0.459,0.459-0.459S8.394,7.051,8.394,7.306"
                  fill="red"
                ></path>
              </svg>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>
<script>
import axios from 'axios'
export default {
  name: 'ProfileComponent',
  data () {
    return {
      user: Object,
      activeReservations: [],
      userInstallation: ''
    }
  },
  async created () {
    await this.fetchUserInfo();
    await this.fetchData();
    await this.fetchInstallations();

  },
  methods: {
    async fetchUserInfo () {
      await axios
        .get(process.env.VUE_APP_URL_BACKEND + '/auth/usuario/info', {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`
          },
          params: {
            emailOrUser: this.$route.params.user
          }
        })
        .then((response) => {
          this.user = response.data
        })
        .catch((error) => {
          console.log(error)
        })
    },
    async fetchData () {
      const response = await axios
        .get(
          `${process.env.VUE_APP_URL_CERRADURAS}/arriendo/usuario/${this.$route.params.user}/arriendos`,
          {
            headers: {
              Authorization: 'Bearer ' + localStorage.getItem('token')
            }
          }
        )
        .then((response) => {
          this.activeReservations = response.data.content;
        })
      return response;
    },
    async fetchInstallations () {
      const response = await axios
        .get(
          process.env.VUE_APP_URL_BACKEND + '/instalaciones/listarInstalaciones'
        )
        .then((response) => {
          if (response.status == 200) {
            //console.log(this.user.instalacion);
            const installations = response.data;
            this.userInstallation = installations.filter(item => {
              if(item.id.toString() === this.user.instalacion){
                return item.nombre_instalacion;
              }
            })[0].nombre_instalacion;
          }
        })
        .catch((error) => {
          console.log(error);
        })
        
      return response
    }
  }
}
</script>
