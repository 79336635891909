<template>
  <div class="h-full flex flex-col justify-center gap-1	basis-1/2">
    <div class="flex flex-row mx-5 justify-center">
      <div class="flex flex-col gap-1 basis-1/2 mt-5 bg-white border-2 shadow rounded-2xl py-4 px-8 text-center">
        <h1 class="text-3xl font-bold text-gray-900">
          {{
            this.$route.params.device
            ? "Editar Dispositivo"
            : "Crear Dispositivo"
          }}
        </h1>
      </div>
    </div>
    <div class="flex flex-row mx-5 justify-center">
      <form class="flex flex-col gap-1 basis-1/2 mt-5 mb-20 bg-white border-2 shadow rounded-2xl py-4 px-8"
        v-on:submit="submitDevice">
        <span class="text-red-600"> {{ msg }} </span>
        <br /><br />

        <span> Nombre: </span><br />
        <input v-model="name"
          class="w-full border px-4 py-2 mb-4 rounded-full focus:border-blue-500 focus:shadow-outline outline-none"
          type="text" placeholder="Nombre de referencia del dispositivo" />
        <span> Numero de Serie: </span><br />
        <input v-model="serialNumber"
          class="w-full border px-4 py-2 mb-4 rounded-full focus:border-blue-500 focus:shadow-outline outline-none"
          type="text" placeholder="Ingrese el numero de serie" />
        <span> Tarjeta SD: </span><br />
        <input v-model="sdCard"
          class="w-full border px-4 py-2 mb-4 rounded-full focus:border-blue-500 focus:shadow-outline outline-none"
          type="text" placeholder="Capacidad y tipo de Tarjeta SD" />
        <span> Tipo de conexión: </span><br />
        <input v-model="conectionType"
          class="w-full border px-4 py-2 mb-4 rounded-full focus:border-blue-500 focus:shadow-outline outline-none"
          type="text" placeholder="Ingrese el tipo de conexión" />
        <span> Rack asociado: </span><br />
        <Listbox v-model="selectedRack">
          <ListboxButton
            class="relative w-full cursor-default rounded-full bg-white py-2 pl-3 pr-10 text-left shadow-md focus:outline-none focus:border-blue-500 focus-visible:border-indigo-500 focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 focus-visible:ring-offset-blue-300 sm:text-sm mt-2">
            {{
              selectedRack
              ? selectedRack.id + " - " + selectedRack.tipo_rack.modelo + " - " + selectedRack.instalacion.nombre_instalacion
              : "Seleccione un rack de casilleros"
            }}
          </ListboxButton>
          <ListboxOptions
            class="mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
            <ListboxOption v-slot="{ active, selected }" v-for="rack in racks" :key="rack.id" :value="rack" as="template">
              <li :class="[
                active ? 'bg-blue-100 text-blue-900' : 'text-gray-900',
                'relative cursor-default select-none py-2 pl-10 pr-4',
              ]">
                <span :class="[
                  selected ? 'font-medium' : 'font-normal',
                  'block truncate',
                ]">{{ rack.id + "- " + rack.tipo_rack + "/" + rack.instalacion.nombre_instalacion }} </span>
                <span v-if="selected" class="absolute inset-y-0 left-0 flex items-center pl-3 text-blue-600">
                  <CheckIcon class="h-5 w-5" aria-hidden="true" />
                </span>
              </li>
            </ListboxOption>
          </ListboxOptions>
        </Listbox>
        <button class="bg-blue-500 p-4 m-4 self-end font-bold text-white grow rounded-md hover:bg-blue-900 border-b-4"
          type="submit">
          {{
            this.$route.params.device
            ? "Editar Dispositivo"
            : "Crear Dispositivo"
          }}
        </button>
      </form>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import {
  Listbox,
  ListboxButton,
  ListboxOptions,
  ListboxOption
} from '@headlessui/vue'
import { CheckIcon } from '@heroicons/vue/20/solid'
export default {
  name: 'CreateDeviceForm',
  components: {
    Listbox,
    ListboxButton,
    ListboxOptions,
    ListboxOption,
    CheckIcon
  },
  props: {},
  data () {
    return {
      name: '',
      sdCard: '',
      serialNumber: '',
      conectionType: '',
      msg: '',
      selectedRack: null
    }
  },
  created () {
    this.fetchRacks()
    if (this.$route.params.device) {
      this.fetchDeviceInfo()
    }
  },
  methods: {
    async createDevice () {
      const device = {
        id_rack: this.selectedRack ? this.selectedRack.id : null,
        id_simcard: null,
        marca: 'No brand',
        modelo: 'No model',
        nombre: this.name,
        nro_serie: this.serialNumber,
        sd_card: this.sdCard,
        tipo_conexion: this.conectionType
      }
      const headers = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + localStorage.getItem('token')
        }
      }
      const response = await axios
        .post(
          process.env.VUE_APP_URL_BACKEND + '/dispositivos/crear',
          device,
          headers
        )
        .then(async (response) => {
          if (response.status == 200) { this.$router.push({ name: 'ShowDevicesView' }) }
        }
        ).catch((error) => {
          this.msg =
            'Error al crear dispositivo' +
            (error.message
              ? error.message
              : 'No se logró indentificar el error')
          window.scrollTo(0, 0) // Scrolls to the top when error msg pops
        })
      return response
    },
    async fetchDeviceInfo () {
      const params = {
        id: this.$route.params.device
      }
      const headers = {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + localStorage.getItem('token')
      }
      const response = await axios
        .get(process.env.VUE_APP_URL_BACKEND + '/dispositivos/getDispositivo', {
          params: params,
          headers: headers
        })
        .then((response) => {
          if (response.status === 200) {
            this.name = response.data.nombre
            this.sdCard = response.data.sd_card
            this.serialNumber = response.data.nro_serie
            this.conectionType = response.data.tipo_conexion
            this.selectedRack = response.data.rack
          }
        })
        .catch((error) => {
          this.msg = 'ERROR AL OBTENER DISPOSITIVO: ' + error.message
          window.scrollTo(0, 0) // Scrolls to the top when error msg pops
        })
      return response
    },
    async submitDevice (e) {
      e.preventDefault()
      if (this.validateForm()) return
      if (this.$route.params.device) {
        await this.editDevice()
      } else {
        await this.createDevice()
      }
    },
    async editDevice () {
      const device = {
        id: this.$route.params.device,
        id_rack: this.selectedRack ? this.selectedRack.id : null,
        marca: 'No brand',
        modelo: 'No model',
        nombre: this.name,
        nro_serie: this.serialNumber,
        sd_card: this.sdCard,
        simcard: null,
        tipo_conexion: this.conectionType
      }
      const headers = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + localStorage.getItem('token')
        }
      }
      const response = await axios
        .put(
          process.env.VUE_APP_URL_BACKEND + '/dispositivos/actualizar',
          device,
          headers
        )
        .then((response) => {
          if (response.status === 200) {
            this.$router.push({ name: 'ShowDevicesView' })
          }
        })
        .catch((error) => {
          this.msg = 'Error al editar el dispositivo: ' + error.message
          window.scrollTo(0, 0) // Scrolls to the top when error msg pops
        })
      return [response]
    },
    validateForm () { // Validate form fields
      if (
        this.sdCard === '' ||
        this.serialNumber === '' ||
        this.conectionType === '' ||
        this.name === ''
      ) {
        this.msg = 'Debe completar todos los campos'
        window.scrollTo(0, 0) // Scrolls to the top when error msg pops
        return true
      }
      return false
    },
    async fetchRacks () { // Fetch Racks from backend
      const headers = {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + localStorage.getItem('token')
      }
      const response = await axios
        .get(process.env.VUE_APP_URL_BACKEND + '/racks/getRacks', {
          headers: headers
        })
        .then((response) => {
          if (response.status === 200) {
            this.racks = response.data
          }
        })
        .catch((error) => {
          this.msg = 'Error al cargar las simcards' + error.message
          window.scrollTo(0, 0) // Scrolls to the top when error msg pops
        })
      return response
    }
  }
}
</script>
