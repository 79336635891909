<template>
    <div class="flex flex-col px-4 container mx-auto my-8 justify-center items-center">
        <div v-if="!showMsg">
            <!-- Selector de intalaciones -->
            <div class="mb-16">
                <div>
                    <label class="font-semibold" for="instalationId">Instalaciones: </label>
                    <select v-model="instalationId" id="instalations" name="instalations" class="border rounded px-2 py-1">
                        <option value="select" disabled selected>-- Seleccionar --</option>
                        <option v-for="instalation in instalations" :key="instalation.id" :value="instalation.id">{{
                            instalation.nombre_instalacion }}
                        </option>
                    </select>
                </div>
                <h3 class="text-xs mt-2">*Hoja de planilla debe llamarse: <strong>bd_edificio</strong></h3>
            </div>
            
            
            <div v-if="instalationId" class="flex flex-col space-y-4 justify-center items-center">

                <!-- Input archivo excel -->
                <div class="mb-8">
                    <input type="file" @change="handleFileUpload" class="hidden" accept=".xls, .xlsx" id="fileInput" />
                    <label for="fileInput" class="bg-blue-500 text-white px-4 py-2 rounded-md cursor-pointer hover:bg-blue-700">
                    Seleccionar Archivo y guardar dpto desde archivo
                    </label>
                    <h3 class="mt-4 text-xs">*Datos se suman a los ya existentes</h3>
                </div>
                
                <div v-if="excelData.length === 0" class="mb-12">                   
                    <button @click="deleteDtps" class="mt-6 bg-blue-500 text-white px-4 py-2 rounded-md cursor-pointer hover:bg-blue-700">
                        Borrar departamentos de instalación seleccionada
                    </button>
                    <h3 class="mt-4 text-xs">*Se eliminan todos los departamentos de la instalación</h3>
                </div>

                <!-- Tabla con datos de excel -->
                <div v-if="excelData.length > 0">
                    <h2 class="text-2xl font-bold mb-4">Contenido del Excel:</h2>
                    <table class="table-auto border-separate ">
                        <thead>
                            <tr class="border bg-blue-500 font-bold text-white truncate px-4 py-2">
                                <th>Nombre</th>
                                <th>N° Dep</th>
                                <th>Correo</th>
                                <th>Número</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(row, index) in excelData" :key="index">
                                <td class="border px-4 py-2" v-for="(cell, cellIndex) in row" :key="cellIndex">
                                    {{ cell }}
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>

            <!-- Boton para enviar datos -->
            <div v-if="Object.keys(reqData).length > 0">
                <button @click="sendReq" class="mt-12 bg-blue-500 text-white px-4 py-2 rounded-md cursor-pointer hover:bg-blue-700">
                    Guardar
                </button>
            </div>
        </div>

        <div v-else>
            <h3>{{ showMsg }}</h3>
        </div>

    </div>
</template>
  
<script setup>
import { ref, onMounted, toRaw } from 'vue';
import axios from 'axios';
import * as XLSX from 'xlsx';

const excelData = ref([]);
const instalations = ref([]);
const instalationId = ref('');
const reqData = ref({});
const showMsg = ref('');

const handleFileUpload = (event) => {
    const file = event.target.files[0];
    if (file) {
        readExcel(file);
        
    }
};

const readExcel = (file) => {
    const reader = new FileReader();
    reader.onload = (e) => {
        const arrayBuffer = new Uint8Array(e.target.result);
        const workbook = XLSX.read(arrayBuffer, { type: 'array' });
        //const sheetName = workbook.SheetNames[0];
        const sheetName = 'bd_edificio';
        const sheet = workbook.Sheets[sheetName];
        const parsedData = XLSX.utils.sheet_to_json(sheet, { header: 1 });
        excelData.value = parsedData.slice(1); // Excluir fila de header
        excelData.value = excelData.value.map(item => {
            return item = {
                "nombre": item[0],
                "nro_depto": item[1],
                "correo": item[2],
                "telefono": item[3]
            }
        }).filter(item => item.nombre !== undefined);
        reqData.value = {
            "id_instalacion": instalationId.value,
            "departamentos": toRaw(excelData.value)
        }
    };
    reader.readAsArrayBuffer(file);
};

const getInstalations = async () => {

    const url = `${process.env.VUE_APP_URL_BACKEND}/instalaciones/getInstalaciones`;
    const res = await axios.get(url, {
        headers: {
            Authorization: 'Bearer ' + localStorage.getItem('token')
        }
    });

    const instalationsData = res?.data;
    instalations.value = Object.keys(instalationsData).map(key => {
        return {
            id: instalationsData[key].id,
            nombre_instalacion: instalationsData[key].nombre_instalacion
        }
    });
}

const deleteDtps = async () => {

    const url = `${process.env.VUE_APP_URL_BACKEND}/departamentos/borrarBatch`;

    const requestData = {
        "id_instalacion": instalationId.value
    };

    await axios.post(url, 
        requestData, {
        headers: {
            Authorization: 'Bearer ' + localStorage.getItem('token')
        }
    })
    .then(() => {
        showMsg.value = 'Datos borrados exitosamente';
        setTimeout(()=> {
            showMsg.value = '';
        }, 3000);
    })
    .catch(ex => {
        console.log(`error borrar datos: ${ex}`);
    });

}

const sendReq = async () => {
    const url = `${process.env.VUE_APP_URL_BACKEND}/departamentos/crearBatch`;

    const requestData = toRaw(reqData.value);

    await axios.post(url, 
        requestData, {
        headers: {
            Authorization: 'Bearer ' + localStorage.getItem('token')
        }
    })
    .then(() => {
        showMsg.value = 'Datos cargados exitosamente';
        setTimeout(()=> {
            showMsg.value = '';
        }, 3000);
    })
    .catch(ex => {
        console.log(`error guardar excel: ${ex}`);
    });


    excelData.value = [];
    instalations.value = [];
    instalationId.value = '';
    reqData.value = {};
}

onMounted(async() => {
    getInstalations();
});

</script>
  